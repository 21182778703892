
import { Component, Prop, Vue } from "vue-property-decorator";

import authUtils from "@/utils/authentication";
import eventBus from "@/utils/eventBus";

@Component
export default class NewTemplate extends Vue {
  @Prop({ type: Boolean }) isWordTemplateParamPresent!: Boolean;

  edit = this.$route.name === "edit-template";
  issue = this.$route.name === "issue-template";
  issueFromFile = this.$route.name === "issue-from-file";
  renew = this.$route.name === "renew-issued";
  external = this.$route.name === "external-contract"

  userName = "";
  userEmail = "";
  showUserMenu = false;
  disableSaveButton = true;
  disableEmitButton = false;
  disableIssueFromFileButton = true
  buttonEditWordIsVisible = false; // if theres word-template in url param show the button
  handleOpenEditWordDrawer = false;
  
  canRenew = false;

  public editWordButtonIsVisible(){
    if(this.$route.path.split('/').pop() === 'word-template'){
      return true;
    }
    else if(this.isWordTemplateParamPresent){
      return true;
    }
    else{
      return false;
    }
  }

  allRequiredInformationContractPartiesAreValid = false;
  allBillingsContractPartiesAreValid = false;
  allAttachmentsContractPartiesAreValid = false;

  public openDrawer() {
    if (this.edit) eventBus.$emit("openEdittingIssuersDrawer");
    else eventBus.$emit("openIssuersDrawer");
  }
  public openEditWordDrawer() {
    this.handleOpenEditWordDrawer = false
    eventBus.$emit("openEditingWordTemplateDrawer")
  }

  public handleEditContract() {
    this.handleOpenEditWordDrawer = true;
    eventBus.$emit("editContract");
  }

  public goBack() { }
  public logout() {
    authUtils.logout(() => {
      this.$router.push("/login");
      this.showUserMenu = false;
    });
  }

  public canSendContract() {
    let canSend = true;
    eventBus.$emit("canIssueContract", (val: boolean) => { if (!val) canSend = false });
    if (canSend) eventBus.$emit("openIssueDrawer")
    else this.$message({
      message: 'Preencha todas as informações.',
      type: 'warning',
      offset: 15
    })
  }

  public canSendTemplate() {
    let canSend = true;
    eventBus.$emit("canIssueContract", (val: boolean) => { if (!val) canSend = false });
    if (canSend) eventBus.$emit("saveTemplate")
    else this.$message({
      message: 'Preencha todas as informações.',
      type: 'warning',
      offset: 15
    })
  }

  public canSendExternalContract() {
    eventBus.$emit("openIssueDrawer")
  }

  public canRenewContract() {
    let canSend = true;
    eventBus.$emit("canIssueContract", (val: boolean) => { if (!val) canSend = false });
    if (canSend) eventBus.$emit("openIssueDrawer")
    else this.$message({
      message: 'Preencha todas as informações.',
      type: 'warning',
      offset: 15
    })
  }

  created() {    
    eventBus.$on("disableEmitButton", () => this.disableEmitButton = true);
    eventBus.$on("canSaveTemplate", (val: boolean) => this.disableSaveButton = !val);
    eventBus.$on("issueFromFileButton", (val: boolean) => this.disableIssueFromFileButton = !val);
    eventBus.$on("allRequiredInformationContractPartiesAreValid", (val: boolean) => this.allRequiredInformationContractPartiesAreValid = val);
    eventBus.$on("allBillingsContractPartiesAreValid", (val: boolean) => this.allBillingsContractPartiesAreValid = val);
    eventBus.$on("allAttachmentsContractPartiesAreValid", (val: boolean) => this.allAttachmentsContractPartiesAreValid = val);
    eventBus.$on("canSaveExternalContract", (val: boolean) => this.disableIssueFromFileButton = !val);

    if (!authUtils.isAuthenticated()) return;
    this.userName = authUtils.user().account.fullname;
    this.userEmail = authUtils.user().account.email;
    this.buttonEditWordIsVisible = this.editWordButtonIsVisible();
  }
  updated(){
    this.buttonEditWordIsVisible = this.editWordButtonIsVisible();
  }
}
