
import TemplateFileViwer from "@/components/editor/TemplateFileViwer.vue";
import contracts from "@/services/contracts";
import organization from "@/services/organization";
import { IdentificationType } from "@/types/contract";
import ErrorWrapper from "@/utils/ErrorWrapper";
import eventBus from "@/utils/eventBus";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SearchBar from "../SearchBar.vue";

interface ContractPartieEditableTabs {
  members?: any,
  name?: any,
  duplicated?: any,
  // those are aplied because the way that parties in issue from file was made, use those three attributes
  email?: string,
  role: string,
  identificationType: IdentificationType,
  identification: {
    teamId?: string,
    uuid?: string,
    email?: string
  },
  comment?: string
}

interface EmailInterface {
  identification: string,
  role: string
}

@Component({ components: { SearchBar, TemplateFileViwer } })
export default class Parties extends Vue {
  IdentificationType = IdentificationType;
  @Prop({ type: Array }) contractParties!: ContractPartieEditableTabs[];
  @Prop({ type: String }) id!: string;
  @Prop({ type: Boolean }) renewContract!: boolean
  @Prop({ type: Array }) identifications!: EmailInterface[]

  dialogVisible = false;
  selectedTempName: string | null = null;

  editCommentDialog = false;
  comment = ""
  prevComment = ""

  @Watch("contractParties")
  handler() {
    if (!this.contractParties) return;
    this.contractParties.forEach(async (cp: any) => {
      Object.assign(cp, { duplicated: false })
      if (this.renewContract) {
        this.identifications.forEach(async (identification) => {
          if (cp.role === identification.role && cp.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL) {
            cp.identification = { ...cp.identification, email: identification.identification };
          } else if (cp.role === identification.role && cp.identificationType !== IdentificationType.IDENTIFIED_BY_EMAIL) {
            const user = await organization.fetchMember(identification.identification)
            Object.assign(cp, { members: [{fullname: user.fullname}], identification: {uuid: user.uuid} })
          }
        })
      }
      else if (cp.identificationType === IdentificationType.ORGANIZATION_TEAM) {
        const teamPagination = await organization.teamMembersPagination(cp.identification.teamId);
        
        let members: any[] = [];
        for (let currentPage = 1; currentPage <= teamPagination.totalRecords; currentPage ++) {
          try {
            const response = await organization.teamMembers(cp.identification.teamId, currentPage)
            members = [...members, ...response];
          } catch (e) {
            const err = new ErrorWrapper(e);
            this.$message({type: "error", message: err.message});
          }
            
        }
        // const filteredMembers = members.filter(m => m.uuid != authUtils.user().account.uuid)
        Object.assign(cp, { members: members })
      }
      else if (cp.identificationType === IdentificationType.ORGANIZATION_USER) {
        const user = await organization.fetchMember(cp.identification.uuid)
        Object.assign(cp, { name: user.fullname })
        
      } else {
        cp.identification = { ...cp.identification, email: '' };
      }
      //Update DOM with request data
      this.$forceUpdate()
    })
  }

  parsedCP: any = [];
  created() {
    if (this.$route.name === "renew-issued") {
      eventBus.$on("canIssueContract", (requestHandler: Function) => {
        const formCP: any = this.$refs.formCP
        formCP.forEach((form: any) => form.validate((valid: any) => valid = true))
      })
    }
    else{
      eventBus.$on("canIssueContract", (requestHandler: Function) => {
        const formCP: any = this.$refs.formCP
        formCP.forEach((form: any) => form.validate((valid: any) => requestHandler(valid)))
      }) 
    }
    eventBus.$on("getIssueData", (requestHandler: Function) => {
      this.parsedCP = []
      let hasErrors = false

      const contractPartiesIdentification: Set<string[]> = new Set([])
      try {
        // Iterate over the contract parties
        this.contractParties.forEach((cp: any) => {

          const newCP = { role: cp.role, identification: {}, comment: cp.comment }
          if (cp.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL) {
            newCP.identification = { email: cp.identification.email }

            // Check if there is already this email in the identification set and if there is throw a Error
            if (!contractPartiesIdentification.has(cp.identification.email)) {
              contractPartiesIdentification.add(cp.identification.email)
            } else {
              throw new Error(`E-mail ${cp.identification.email} foi encontrado em mais de um participante`)
            }
            this.parsedCP.push(newCP);
          }
          else {
            newCP.identification = { uuid: cp.identification.uuid }

            // Check if there is already this uuid in the identification set and if there is throw a Error
            if (!contractPartiesIdentification.has(cp.identification.uuid)) {
              contractPartiesIdentification.add(cp.identification.uuid)
            } else {
              throw new Error(`Mesmo membro da organização foi encontrado em mais de um participante`)
            }
            this.parsedCP.push(newCP);
          }
        })
      } catch (e) {
        console.error(e)
        // If there is a error send a message and set the boolean to true
        this.$message.error(`Ocorreu um erro: ${new ErrorWrapper(e).message}`);
        hasErrors = true
      }
      requestHandler("contractParties", this.parsedCP)

      // Emit if the IssueDataReview drawer can open
      eventBus.$emit("canOpenDrawer", !hasErrors)
    })
  }

  //Form validator
  checkEmail = async (rule: any, value: string, callback: Function) => {
    if (!value) return callback(new Error("Campo obrigatório"));
    if (!/\S+@\S+\.\S+/.test(value)) return callback(new Error("Insira um email válido"));
    return callback();
  };

  public async validateDuplicated(cp: any) {

    if (cp.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL && !/\S+@\S+\.\S+/.test(cp.identification.email)) return;
    try {
      const field = cp.identification.uuid ? 'uuid' : 'email',
        value = cp.identification.uuid ? cp.identification.uuid : cp.identification.email,
        duplicated = (await contracts.duplicatedCp(this.id, field, value))
      if (duplicated && cp.identificationType !== IdentificationType.IDENTIFIED_BY_EMAIL) {
        this.dialogVisible = true;
        this.selectedTempName = duplicated.template.name;
      }

      Object.assign(cp, { duplicated: !duplicated ? false : true })
      this.$forceUpdate()
    } catch (e) {
      console.error(e)
      this.$message.error(`Ocorreu um erro: ${new ErrorWrapper(e).message}`);
    }
  }

  rules = {
    ["identification.email"]: [{ required: true, validator: this.checkEmail, trigger: "blur" }],
    ["identification.uuid"]: [{ required: true, message: 'Campo obrigatório', trigger: "blur" }]
  };

  public handleOpenCommentDialog(cp: ContractPartieEditableTabs) {
    this.editCommentDialog = true
    this.comment = cp.comment ? cp.comment : ""
    this.prevComment = this.comment
  }

  public saveContractPartyComment(cp: ContractPartieEditableTabs) {
    this.editCommentDialog = false
    cp.comment = this.comment
    this.comment = ""
    this.prevComment = ""
  }

}
