import contractsApi from "./contracts-api";

enum Plans {
    PAY_AS_YOU_GO = "PAY_AS_YOU_GO",
    BASIC = "BASIC",
    SMALL_BUSINESS = "SMALL_BUSINESS",
    BUSINESS = "BUSINESS"
}

export interface ContractsQuota {
    customerContractsQuota: {
        maxTemplateCount: Number,
        maxSubusers: Number,
        contractsIssuedPerMonth: Number,
        maxStorageBytes: Number,
        financialAnalyticsModuleEnabled: boolean,
        contractsFieldsAutomationEnabled: boolean,
        contractAttachmentsEnabled: boolean,
        plan: Plans
    },
    currentTemplateCount: Number,
    currentSubusersCount: Number,
    currentStorageConsumptionInBytes: Number
}


export const OrganizationContractsQuotaService = {

    async fetchContractsQuota(): Promise<ContractsQuota> {
        const response = await contractsApi.get("/rest/v1/organizations/my/contracts/quotas")

        return await response.data
    }
}