
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import "moment/locale/pt-br";
import {
  StartDatePolicyType,
  EndDatePolicyType,
  ValidityPeriod,
  EndDatePolicy,
  DateIntervals,
  ValidityPeriodDates,
} from "@/types/contract";
import eventBus from "@/utils/eventBus";

@Component
export default class Validity extends Vue {
  @Prop({ type: Boolean }) fileUploaded!: boolean;

  // Boolean variables
  dateOnEditor = false;

  // Types variables
  startDatePolicyType = StartDatePolicyType;
  endDatePolicyType = EndDatePolicyType;

  // Data variables
  startDate = StartDatePolicyType.SET_BY_ISSUER;
  finalDate = EndDatePolicyType.UNDETERMINED;
  dateValue = 0;
  dateInterval = "";
  endDate = "";
  dates: ValidityPeriodDates = {
    startDate: null,
    endDate: null
  }

  // Validation
  rules = {
    startDate: { type: 'date', required: true, message: 'Selecione uma data', trigger: 'change' },
    endDate: { type: 'date', required: true, message: 'Selecione uma data', trigger: 'change' }
  };
  
  //Date picker options - disable previous date
  get pickerOptions() {
    return {
      disabledDate: (time: any) => {
        // Disable dates before the startDate
        return time.getTime() < (this.dates.startDate ? this.dates.startDate.getTime() : Date.now());
      },
    };
  }

  // Events
  created() {
    eventBus.$on("canSendExternalContract", (requestHandler: Function) => { requestHandler(this.dates.startDate ? true : false)})
    eventBus.$on("getIssueData", (requestHandler: Function) => {
      const newVP: any = {
        startDate: null,
        endDate: null,
        startDatePolicyType: this.startDate,
        endDatePolicyType: this.finalDate
      }

      if (this.dates.startDate) newVP.startDate = new Date(this.dates.startDate)

      if (this.finalDate === this.endDatePolicyType.SET_BY_ISSUER) {
        if (this.dates.endDate) newVP.endDate = new Date(this.dates.endDate)
      }
      requestHandler("validityPeriod", newVP)
    })
  }

  datesHandler() {
    if (!this.dates.startDate) eventBus.$emit("canSaveExternalContract", false);
    else if (this.finalDate === EndDatePolicyType.SET_BY_ISSUER && !this.dates.endDate) eventBus.$emit("canSaveExternalContract", false);
    else eventBus.$emit("canSaveExternalContract", true)
  }

  // Functions

  public emit() {
    if (this.finalDate === EndDatePolicyType.SET_BY_ISSUER) eventBus.$emit("canSaveExternalContract", false);

    //Create the object
    let validityPeriod: ValidityPeriod = {
      startDatePolicyType: this.startDate,
      endDatePolicyType: this.finalDate,
    };

    if (this.finalDate === EndDatePolicyType.SET_AUTOMATICALLY_AFTER_START_DATE) {
      //Create the object
      const endDatePolicy: EndDatePolicy = {
        value: this.dateValue,
        dateInterval: this.dateInterval.toString().slice(0, -1) as DateIntervals,
      };

      //Avoid empty value
      if (endDatePolicy.dateInterval.length === 0)
        endDatePolicy.dateInterval = DateIntervals.YEAR;

      validityPeriod = {
        ...validityPeriod,
        endDatePolicy: endDatePolicy,
      };
    }

    //Emit the object
    this.$emit("validityPeriod", validityPeriod);
  }

  /**
   * Remove date wildcard from editor
   */
  public removeDate() {
    eventBus.$emit("removeDateWildcard");
    this.dateOnEditor = false;
  }

  // Handler functions
  /**
   * Handle end date simulator
   */
   public handleDateChange() {
    const today = moment(moment(), "DD-MM-YYYY");
    const interval = (this.dateInterval as unknown) as undefined;
    this.endDate = today.add(this.dateValue, interval).calendar();
    this.emit();
  }
}
