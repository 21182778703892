
import EmailTemplateEditor from "@/components/EmailTemplateEditor.vue";
import authentication from "@/services/authentication";
import contracts from "@/services/contracts";
import organization from "@/services/organization";
import { EmailTypes, MailTemplate, OrganizationInterfaceTheme } from "@/types/organization";
import ErrorWrapper from "@/utils/ErrorWrapper";
import eventBus from "@/utils/eventBus";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SupportedCurrencies } from "../../utils/Currencies";
import Tags from "../Tags.vue";

@Component({ components: { EmailTemplateEditor, Tags } })
export default class IssueDataReview extends Vue {
    @Prop({ type: Boolean }) visible!: boolean;
    @Prop({ type: Object }) data!: any;
    @Prop({ type: String }) templateName!: String;
    @Prop({ type: [] }) templateTags!: String[];
    @Prop({ type: Array }) emailTemplates!: MailTemplate[]
    

    loading = true;
    success = false;
    saving = false;
    review = false;
    organization = "";
    isARenewedContract = false;

    users: any = []
    parties: any = []
    tags: any = [];

    selectedMailTemplate: MailTemplate = {
        mailTemplate: "",
        mailType: EmailTypes.ISSUE_CONTRACT
    }

    emailEditor = false;

    editEmailTemplates = false

    emailType = "" as EmailTypes;

    emailTypes = EmailTypes;

    interfaceTheme: OrganizationInterfaceTheme = {
        primaryColor: "#370068",
        secondaryColor: "#7C24BE",
        logoUrl: "https://accountsblob.blob.core.windows.net/publicassets/cartorizi-logo.webp"
    }

    issueMailTemplate: MailTemplate | null = null

    hasPersonalizedIssueMail = false
    hasPersonalizedStateMail = false
    hasPersonalizedSignMail = false

    created() {
        if (this.$route.name === "renew-issued") {
            this.isARenewedContract = true;
        }

        eventBus.$on("editMailTemplate", (mailTemplate: MailTemplate) => {
            if (this.emailTemplates) {
                const mailTemplates = this.emailTemplates.filter((t: MailTemplate) => t.mailType !== mailTemplate.mailType) as MailTemplate[]
                mailTemplates.push(mailTemplate)
                this.emailTemplates = mailTemplates
            } else {
                this.emailTemplates = [mailTemplate]
            }
        })
        this.fetchInterfaceTheme()
    }

    public handleOpenEmailEditor(type: EmailTypes) {
        this.emailEditor = true
        this.emailType = type
        if (this.emailTemplates) {
            const mt = this.emailTemplates.filter(t => t.mailType === type)
            if (mt) this.selectedMailTemplate = mt[0]
        }
    }

    @Watch("emailTemplates")
    handleEmailTemplates() {
        if (this.emailTemplates) {
            const issueTemplate = this.emailTemplates.filter(t => t.mailType === EmailTypes.ISSUE_CONTRACT)
            if (issueTemplate) {
                this.issueMailTemplate = issueTemplate[0]
            }
        }
    }

    @Watch("data")
    handler() {
        if (!this.data.contractParties) return;
        this.parties = this.data.contractParties;
        this.parties.forEach(async (cp: any) => {
            const field = cp.identification.uuid ? 'uuid' : 'email',
                value = cp.identification.uuid ? cp.identification.uuid : cp.identification.email,
                duplicated = (await contracts.duplicatedCp(this.data.id, field, value))

            Object.assign(cp, { duplicated: !duplicated ? false : true })

            this.$forceUpdate()
            if (cp.identification.uuid) {
                const user = await organization.fetchMember(cp.identification.uuid);
                this.users.push({ name: user.fullname, uuid: user.uuid })
            }
            this.getOrganization()
        });
    }

    get startDate() {
        return moment(this.data.validityPeriod.startDate.timestamp).format('DD MMMM YYYY')
    }
    get endDate() {
        return moment(this.data.validityPeriod.endDate.timestamp).format('DD MMMM YYYY')
    }

    public findUser(uuid: string) {
        const user = this.users.find((u: any) => u.uuid === uuid)
        return user ? user.name : uuid;
    }

    public billingDate(date: Date) {
        return moment(date).format('DD MMMM YYYY')
    }

    public close() {
        this.saving = false;
        this.review = false;
        this.editEmailTemplates = false
        this.$emit('close')
    }

    public async issue() {
        this.loading = true;
        this.saving = true;
        const normalizedTags = {
            tags: this.tags
        }
        try {
            await contracts.issue(this.data.id, {
                ...this.data,
                billingData: (!this.data.billingData || this.data.billingData.length == 0) ? [] : this.data.billingData.map((b: { value: { value: number; }; }) => ({
                    ...b,
                    value: {
                        value: SupportedCurrencies.BRL.fromRegionValueToSubunit(b.value.value),
                        currency: SupportedCurrencies.BRL.currencyName,
                    }
                })),
                contractTags: normalizedTags,
                mailTemplates: this.emailTemplates,
            })
            this.success = true;
        }
        catch (e) {
            const err = new ErrorWrapper(e);
            this.$message.error(
                    err.message === "Unable to issue contract: limit has been reached"  
                    ?   
                        "Ocorreu um erro: Sua cota de emissão atingiu o limite." 
                    :   
                        `Ocorreu um erro: ${err.message}`
                );
        }
        finally {
            this.loading = false;
        }
    }

    public async renew() {
        this.loading = true;
        this.saving = true;

        const reqBody = {
            name: this.data.name,
            validityPeriod: this.data.validityPeriod,
            billingData: (!this.data.billingData || this.data.billingData.length == 0) ? [] : this.data.billingData.map((b: { value: { value: number; }; }) => ({
                ...b,
                value: {
                    value: SupportedCurrencies.BRL.fromRegionValueToSubunit(b.value.value),
                    currency: SupportedCurrencies.BRL.currencyName,
                }
            }))
        }
        try {
            await contracts.renewIssuedDigitalContract(this.$route.params.previousContractId, reqBody)
            this.success = true;
        }
        catch (e) {
            const err = new ErrorWrapper(e);
            this.$message.error(`Ocorreu um erro: ${err.message}`);
        }
        finally {
            this.loading = false;
        }
    }

    public async getOrganization() {
        this.loading = true
        if (this.organization == "") {
            try {
                const org = await authentication.getOrganization()
                this.organization = org.data.fullname
            } catch {
                try {
                    this.organization = (await authentication.me()).account.preferredName
                } catch (error) {
                    this.organization = "{Nome da sua organização}"
                }

            }
        }
        this.loading = false
        if (this.emailTemplates) {
            this.emailTemplates.forEach(mt => {
                switch (mt.mailType) {
                    case EmailTypes.ISSUE_CONTRACT:
                        this.hasPersonalizedIssueMail = true
                        break;
                    case EmailTypes.CHANGE_STATES:
                        this.hasPersonalizedStateMail = true
                        break;
                    case EmailTypes.FULLY_SIGNED:
                        this.hasPersonalizedSignMail = true
                        break;
                }
            })
        }
    }

    public async getSuggestions(queryString: string, callback: any) {
        const contract = (await contracts.find(this.data.id))
        const participants = this.data.contractParties.filter((party: any) => {
            return party.identification.email
        })
        const results: any[] = [];
        participants.forEach((participant: any) => {
            const email = participant.identification.email
            const emailProvider = email.split("@")[1];
            results.push({ value: contract.name });
            results.push({ value: `${contract.name} - ${email}` });
            results.push({ value: `${contract.name} - ${email.split("@", 1)}` });
            results.push({ value: `${contract.name} - ${emailProvider.substring(0, emailProvider.indexOf("."))}` });
        });

        // const participant = this.data.contractParties.find((party: any) => {
        //     return party.identification.email
        // })
        // const email = participant.identification.email
        // const emailProvider = email.split("@")[1]
        // const result = [
        //     { value: contract.name },
        //     { value: `${contract.name} - ${email}` },
        //     { value: `${contract.name} - ${email.split("@", 1)}` },
        //     { value: `${contract.name} - ${emailProvider.substring(0, emailProvider.indexOf("."))}` },
        // ]

        callback(results)
    }

    public enableReview() {
        if (this.data.name.trim().length !== 0) {
            this.review = !this.review
        }
    }

    public removeTag(tag: any) {
        const updatedTags = this.tags.filter((t: any) => t !== tag);
        this.tags = updatedTags
    }

    @Watch('tags', { immediate: true })
    handleTagsChange() {
        this.$forceUpdate();
    }

    public handleTagAdded(tag: any) {
        tag = tag.trim().toLowerCase();
        if (!this.tags.some((t: any) => t === tag)) {
            this.tags.push(tag);
        }
    }

    private async fetchInterfaceTheme() {
        try {
            const theme = await organization.fetchOrganizationInterfaceTheme()
            if (theme) {
                this.interfaceTheme = theme
            }
        } catch (e) {
            const err = new ErrorWrapper(e)
            switch (err.httpStatus) {
                case 404:
                    break;
                default:
                    this.$message.error(err.message)
                    break;
            }
        }
    }
}
