<template>
  <div class="main" style="{height: pages.lenght * 500 + 'px';}">
    <!-- Top bar -->
    <!-- <vue-file-toolbar-menu :content="menu" class="bar" /> -->
    <!-- <prime-menu ref="menu" :model="items" :popup="true" />-->
    <!-- Document editor -->
    <!-- eslint-disable vue/no-v-model-argument -->
    <toolbar :content="menu" class="bar" />
    <vue-document-editor :key="key" class="editor" ref="editor" v-model:content="content" :content="content"
      :overlay="overlay" :zoom="zoom" :page_format_mm="page_format_mm" :page_margins="page_margins" :display="display"
      @checkWildcards="checkWildcards" :editing="editing" />
    <el-tooltip placement="top" :disabled="!enableWildcard && !disableAllButton">
      <span slot="content">
        {{
  disableAllButton
  ? `Preencha os dados ao lado primeiro`
        : `Insira um participante`
        }}
      </span>
      <div id="context-menu" v-show="show_button">
        <el-tooltip placement="right" :disabled="!disableWildcardButton || disableAllButton">
          <span slot="content"> Selecione o texto que<br />deseja transformar </span>
          <div style="display: flex; flex-direction: column">
            <el-button type="text" class="border" @click="createWildcard('field')"
              :disabled="enableWildcard || disableAllButton || disableWildcardButton">
              <i class="pi pi-bars" style="font-size: 15px; margin-right: 15px" />
              Transformar em campo
            </el-button>
            <el-button style="width: 100%" type="text" class="border" @click="createWildcard('money')"
              :disabled="enableWildcard || disableAllButton || disableWildcardButton">
              <i class="pi pi-money-bill" style="font-size: 15px; margin-right: 10px" />
              Transformar em cobrança
            </el-button>
          </div>
        </el-tooltip>
        <el-tooltip placement="right" :disabled="!disableDateButton || disableAllButton">
          <span slot="content">
            Para adicionar este campo o texto <br />não pode estar selecionado
          </span>
          <div>
            <el-button type="text" class="border" @click="
  showDateButtons = true;
show_button = true;
            " :disabled="enableWildcard || disableAllButton || disableDateButton">
              <i class="pi pi-calendar" style="font-size: 15px; margin-right: 10px" />
              Adicionar data de vigência

              <i class="pi pi-chevron-right" style="font-size: 11px; padding-left: 10px" />
            </el-button>
            <el-button v-if="showDateButtons" type="text" class="border" @click="createDateWildcard('start')"
              :disabled="enableWildcard || disableAllButton || disableDateButton">
              Data de início
            </el-button>
            <el-button v-if="showDateButtons" type="text" class="border" @click="createDateWildcard('end')"
              :disabled="enableWildcard || disableAllButton || disableDateButton">
              Data final
            </el-button>
          </div>
        </el-tooltip>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
/* import VueFileToolbarMenu from "vue-file-toolbar-menu"; */
import VueDocumentEditor from "./DocumentEditor.vue";
import toolbar from "../toolbar/Bar/Bar.vue";
import eventBus from "@/utils/eventBus";

import "material-icons/iconfont/material-icons.css";
import "primeicons/primeicons.css";

//Import custom fonts
import Fonts from "./imports/fonts/fonts";
import { EndDatePolicyType } from "@/types/contract";

import JSZip from "jszip"

export default {
  components: { VueDocumentEditor, toolbar },
  props: {
    wildcardsDisabled: { type: Boolean },
    templateFile: { type: String },
    editing: { 
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [
        {
          label: "Limpar conteúdo",
          icon: "pi pi-file",
          command: () => {
            if (confirm("Tem certeza que deseja limpar todo este conteúdo?")) {
              this.content = [""];
              this.resetContentHistory();
            }
          },
        },
        {
          label: "Salvar",
          icon: "pi pi-save",
          command: () => this.replaceWildcards(),
        },
      ],
      // This is where the pages content is stored and synced
      content: [
        "<p><br></p>",
        // Every item below produce a page break
        //'<div>I am a page</div>'
      ],
      key: 0,
      wildcards: [],
      disableAllButton: false,
      disableDateButton: false,
      disableWildcardButton: false,
      showDateButtons: false,
      show_button: false,
      font_family: "Helvetica", // ["Courier", "Helvetica", "Times"]
      font_size: "16", //["10", "13", "16", "18", "24", "32", "48"]
      zoom: 0.75,
      zoom_min: 0.1,
      zoom_max: 5.0,
      page_format_mm: [210, 297],
      page_margins: "42pt",
      display: "vertical", // ["grid", "vertical", "horizontal"]
      mounted: false, // will be true after this component is mounted
      undo_count: -1, // contains the number of times user can undo (= current position in content_history)
      content_history: [], // contains the content states for undo/redo operations
      importOrCreateNew: false,
      file: null,
      lines: [],
    };
  },

  created() {
    //const button = document.getElementById("floating-button");

    document.addEventListener("contextmenu", (e) => {
      const editor = document.getElementsByClassName("content")[0];

      if (!e.composedPath().includes(editor)) return;

      e.preventDefault();
      this.disableDateButton = this.isTextSelected();
      this.disableWildcardButton = !this.isTextSelected();
      this.openContextMenu(e.clientX, e.clientY);
    });

    document.addEventListener("mouseup", (e) => {
      this.show_button = e.wich === 3 ? true : false;
    });

    document.addEventListener("keydown", (e) => {
      //Reset show wildcard button flag
      this.show_button = false;

      //Get current node and it's parent
      const currentNode = window.getSelection()?.anchorNode;
      const currentNodeParent = window.getSelection()?.anchorNode?.parentElement;

      //Valdate if wildcard is selected
      if (
        (currentNode &&
          currentNode.tagName === "SPAN" &&
          currentNode.className.includes("wildcard")) ||
        (currentNodeParent &&
          currentNodeParent.tagName === "SPAN" &&
          currentNodeParent.className.includes("wildcard"))
      ) {
        //Ignore arrow keys
        if (
          e.key === "ArrowUp" ||
          e.key === "ArrowDown" ||
          e.key === "ArrowLeft" ||
          e.key === "ArrowRight"
        )
          return;

        //Cancel event
        e.preventDefault();

        //If delete or backspace key
        if (e.keyCode === 8 || e.keyCode === 46) {
          //Confirm to delete wildcard

          this.$confirm("Deseja remover esse campo?").then(() => {
            //Get current node
            const node = currentNode.nodeType === 3 ? currentNodeParent : currentNode;
            if (!node) return;

            //Get the text it contains
            const nodeText = node.textContent.replace(/&nbsp;/g, "");
            const fields = nodeText.split(">");

            //Get the node text content
            const nodeTextContent = node.classList.contains("date")
              ? ""
              : fields[1].trim();

            //Get the element's parent
            const parent = node.parentNode;

            //Create a text node:
            const newNode = document.createTextNode(nodeTextContent);

            //Insert it into the document before the selected one
            parent.insertBefore(newNode, node);

            //Remove the selected one element
            parent.removeChild(node);

            const wildcardAttributes = {
              type: "",
              id: 0,
              name: ""
            }

            // update the wildcardAttributes with the values of the selected wildcard
            if (node.nodeType === Node.ELEMENT_NODE) {
              wildcardAttributes.type = node.getAttribute("class").split(" ")[0]
              wildcardAttributes.id = node.getAttribute("id")
              wildcardAttributes.name = node.getAttribute("data-value")
            }

            // select all wildcards of the same type
            const allWildcards = document.querySelectorAll(".wildcard")

            let hasSameWildcardMultipleTimesInEditor

            //check if there is other wildcards with the same data-value that the selected wildcard
            allWildcards.forEach(wildcard => {
              if (wildcard.getAttribute("data-value") === wildcardAttributes.name && 
                    wildcard.getAttribute("class").split(" ")[0] === wildcardAttributes.type) {
                hasSameWildcardMultipleTimesInEditor = true
              }
            })
            
            //Remove the wildcard from the array
            const findWc = this.wildcards.find((wc) => wc.id === wildcardAttributes.id);
            this.$delete(this.wildcards, this.wildcards.indexOf(findWc));

            // check if there is the no wildcard with the same data-value
            if(!hasSameWildcardMultipleTimesInEditor) {
              //Emit the action
              eventBus.$emit(
                `removedWildcard-${node.classList.contains("date") ? "date" : findWc.type}`,
                nodeTextContent
              );        
            }
          });
        }
      }
    });

    // Initialize gesture flags
    let start_zoom_gesture = false;
    let start_dist_touch = false;
    let start_zoom_touch = false;

    // Manage ctrl+scroll zoom (or trackpad pinch)
    window.addEventListener(
      "wheel",
      (e) => {
        if (e.ctrlKey) {
          e.preventDefault();
          this.zoom = Math.min(
            Math.max(this.zoom - e.deltaY * 0.01, this.zoom_min),
            this.zoom_max
          );
        }
      },
      { passive: false }
    );

    // Manage trackpad pinch on Safari
    window.addEventListener("gesturestart", (e) => {
      e.preventDefault();
      start_zoom_gesture = this.zoom;
    });
    window.addEventListener("gesturechange", (e) => {
      e.preventDefault();
      if (!start_zoom_touch) {
        this.zoom = Math.min(
          Math.max(start_zoom_gesture * e.scale, this.zoom_min),
          this.zoom_max
        );
      }
    });
    window.addEventListener("gestureend", () => {
      start_zoom_gesture = false;
    });

    // Manage pinch to zoom for touch devices
    window.addEventListener(
      "touchstart",
      (e) => {
        if (e.touches.length == 2) {
          e.preventDefault();
          start_dist_touch = Math.hypot(
            e.touches[0].pageX - e.touches[1].pageX,
            e.touches[0].pageY - e.touches[1].pageY
          );
          start_zoom_touch = this.zoom;
        }
      },
      { passive: false }
    );
    window.addEventListener(
      "touchmove",
      (e) => {
        if (start_dist_touch && start_zoom_touch) {
          e.preventDefault();
          let zoom =
            (start_zoom_touch *
              Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY
              )) /
            start_dist_touch;
          this.zoom = Math.min(Math.max(zoom, this.zoom_min), this.zoom_max);
        }
      },
      { passive: false }
    );
    window.addEventListener(
      "touchend",
      () => {
        start_dist_touch = false;
        start_zoom_touch = false;
      },
      { passive: false }
    );

    // Manage history undo/redo events
    const manage_undo_redo = (e) => {
      switch (e && e.inputType) {
        case "historyUndo":
          e.preventDefault();
          e.stopPropagation();
          this.undo();
          break;
        case "historyRedo":
          e.preventDefault();
          e.stopPropagation();
          this.redo();
          break;
      }
    };
    window.addEventListener("beforeinput", manage_undo_redo);
    window.addEventListener("input", manage_undo_redo); // in case of beforeinput event is not implemented (Firefox)

    // If your component is susceptible to be destroyed, don't forget to
    // use window.removeEventListener in the Vue.js beforeUnmount handler
  },

  mounted() {
    this.mounted = true;
    eventBus.$on("removeWildcard", (wcName) => {
      const removedWildcard = this.wildcards.filter((wc) => wc.name === wcName);
      removedWildcard.forEach((wc) => this.removeHighlight(wc));
    });
    // eventBus.$on("updateWildcard", (wcOldName, wcNewName) => {
    //   this.updateWildcard(wcOldName, wcNewName);
    // });
    eventBus.$on("removeDateWildcard", () => {
      const wcFilter = this.wildcards.filter((wc) => wc.type === "date");
      wcFilter.forEach((wc) => this.removeHighlight(wc));
    });
    eventBus.$on("getTemplateHTMLContent", (handler) => {
      const content = this.replaceWildcardsAndGetEditorHTML();
      handler(content);
    });
  },

  computed: {
    enableWildcard() {
      this.$forceUpdate();
      return this.wildcardsDisabled;
    },
    // This is the menu content
    menu() {
      return [
        {
          // Zoom menu
          text: Math.floor(this.zoom * 100) + "%",
          title: "Zoom",
          icon: "zoom_in",
          chevron: true,
          menu: [
            ["200%", 2.0],
            ["150%", 1.5],
            ["125%", 1.25],
            ["100%", 1.0],
            ["75%", 0.75],
            ["50%", 0.5],
            ["25%", 0.25],
          ].map(([text, zoom]) => {
            return {
              text,
              active: this.zoom == zoom,
              click: () => {
                this.zoom = zoom;
              },
            };
          }),
          menu_width: 80,
          menu_height: 280,
          menu_class: "align-center",
        },
        {
          // Display mode menu
          title: "Display",
          icon:
            this.display == "horizontal"
              ? "view_column"
              : this.display == "vertical"
                ? "view_stream"
                : "view_module",
          chevron: true,
          menu: [
            {
              icon: "view_module",
              active: this.display == "grid",
              click: () => {
                this.display = "grid";
              },
            },
            {
              icon: "view_column",
              active: this.display == "horizontal",
              click: () => {
                this.display = "horizontal";
              },
            },
            {
              icon: "view_stream",
              active: this.display == "vertical",
              click: () => {
                this.display = "vertical";
              },
            },
          ],
          menu_width: 55,
          menu_class: "align-right",
        },
        /*   { is: "separator" },
        // Undo / redo commands
        {
          title: "Undo",
          icon: "undo",
          disabled: !this.can_undo,
          hotkey: this.isMacLike ? "command+z" : "ctrl+z",
          click: () => this.undo(),
        },
        {
          title: "Redo",
          icon: "redo",
          disabled: !this.can_redo,
          hotkey: this.isMacLike ? "shift+command+z" : "ctrl+y",
          click: () => this.redo(),
        }, */
        { is: "separator" },
        // Rich text menus
        {
          icon: "format_align_left",
          title: "Align left",
          active: this.isLeftAligned,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? "shift+command+l" : "ctrl+shift+l",
          click: () => document.execCommand("justifyLeft"),
        },
        {
          icon: "format_align_center",
          title: "Align center",
          active: this.isCentered,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? "shift+command+e" : "ctrl+shift+e",
          click: () => document.execCommand("justifyCenter"),
        },
        {
          icon: "format_align_right",
          title: "Align right",
          active: this.isRightAligned,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? "shift+command+r" : "ctrl+shift+r",
          click: () => document.execCommand("justifyRight"),
        },
        {
          icon: "format_align_justify",
          title: "Justify content",
          active: this.isJustified,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? "shift+command+j" : "ctrl+shift+j",
          click: () => document.execCommand("justifyFull"),
        },
        { is: "separator" },
        {
          icon: "format_bold",
          title: "Bold",
          active: this.isBold,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? "command+b" : "ctrl+b",
          click: () => document.execCommand("bold"),
        },
        {
          icon: "format_italic",
          title: "Italic",
          active: this.isItalic,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? "command+i" : "ctrl+i",
          click: () => document.execCommand("italic"),
        },
        {
          icon: "format_underline",
          title: "Underline",
          active: this.isUnderline,
          disabled: !this.current_text_style,
          hotkey: this.isMacLike ? "command+u" : "ctrl+u",
          click: () => document.execCommand("underline"),
        },
        {
          icon: "format_strikethrough",
          title: "Strike through",
          active: this.isStrikeThrough,
          disabled: !this.current_text_style,
          click: () => document.execCommand("strikethrough"),
        },
        {
          is: "button-color",
          //type: "compact",
          menu_class: "align-center",
          disabled: !this.current_text_style,
          color: this.curColor,
          update_color: (new_color) =>
            document.execCommand("foreColor", false, new_color.hex8),
        },
        { is: "separator" },
        {
          icon: "format_list_numbered",
          title: "Numbered list",
          active: this.isNumberedList,
          disabled: !this.current_text_style,
          click: () => document.execCommand("insertOrderedList"),
        },
        {
          icon: "format_list_bulleted",
          title: "Bulleted list",
          active: this.isBulletedList,
          disabled: !this.current_text_style,
          click: () => document.execCommand("insertUnorderedList"),
        },
        /* {
          html: "<b>H1</b>",
          title: "Header 1",
          active: this.isH1,
          disabled: !this.current_text_style,
          click: () => document.execCommand("formatBlock", false, "<h1>"),
        },
        {
          html: "<b>H2</b>",
          title: "Header 2",
          active: this.isH2,
          disabled: !this.current_text_style,
          click: () => document.execCommand("formatBlock", false, "<h2>"),
        },
        {
          html: "<b>H3</b>",
          title: "Header 3",
          active: this.isH3,
          disabled: !this.current_text_style,
          click: () => document.execCommand("formatBlock", false, "<h3>"),
        }, */
        {
          icon: "format_clear",
          title: "Limpar formatação",
          disabled: !this.current_text_style,
          click() {
            document.execCommand("removeFormat");
            document.execCommand("formatBlock", false, "<div>");
          },
        },
        {
          // Font family menu
          text: this.curFontFamily || "Helvetica",
          title: "Fonte",
          chevron: true,
          menu_height: 280,
          disabled: !this.current_text_style,
          menu: Fonts.map((text) => {
            return {
              text,
              active: this.curFontFamily === text,
              click: () => {
                this.font_family = text;
                document.execCommand("fontName", false, text);
              },
            };
          }),
        },
        {
          // Font size menu
          text: this.curFontSize || "16",
          title: "Tamanho da fonte",
          chevron: true,
          menu_width: 50,
          disabled: !this.current_text_style,
          menu: [
            ["10", 1],
            ["13", 2],
            ["16", 3],
            ["18", 4],
            ["24", 5],
            ["32", 6],
            ["48", 7],
          ].map(([text, size]) => {
            return {
              text,
              active: this.curFontSize === parseFloat(text),
              click: () => {
                this.font_size = text;
                document.execCommand("fontSize", false, size);
              },
            };
          }),
        },
        { is: "separator" },
        {
          icon: "image",
          title: "Inserir imagem",
          disabled: !this.current_text_style,
          click: () => {
            const file_input = document.getElementById("file");
            file_input.value = null;
            file_input.click();
          },
        },
        { is: "separator" },
        {
          title: "Opções da página",
          icon: "more_vert",
          click: (e) => this.$refs.menu.toggle(e),
        },
      ];
    },

    // Formats management
    current_format_name() {
      const format = this.formats.find(
        ([, width_mm, height_mm]) =>
          this.page_format_mm[0] == width_mm && this.page_format_mm[1] == height_mm
      );
      return format
        ? format[0]
        : this.page_format_mm[0] + "mm x " + this.page_format_mm[1] + "mm";
    },
    formats: () => [
      ["A0", 841, 1189],
      ["A0L", 1189, 841],
      ["A1", 594, 841],
      ["A1L", 841, 594],
      ["A2", 420, 594],
      ["A2L", 594, 420],
      ["A3", 297, 420],
      ["A3L", 420, 297],
      ["A4", 210, 297],
      ["A4L", 297, 210],
      ["A5", 148, 210],
      ["A5L", 210, 148],
      ["A6", 105, 148],
      ["A6L", 148, 105],
    ],

    // Margins management
    current_margins_name() {
      const margins = this.margins.find(([, margins]) => this.page_margins == margins);
      return margins ? margins[0] : margins[1];
    },
    margins: () => [
      ["Medium", "20mm"],
      ["Small", "15mm"],
      ["Slim", "10mm 15mm"],
      ["Tiny", "5mm"],
    ],

    // Current text style management
    current_text_style() {
      return this.mounted ? this.$refs.editor.current_text_style : false;
    },
    isLeftAligned() {
      return ["start", "left", "-moz-left"].includes(this.current_text_style.textAlign);
    },
    isRightAligned() {
      return ["end", "right", "-moz-right"].includes(this.current_text_style.textAlign);
    },
    isCentered() {
      return ["center", "-moz-center"].includes(this.current_text_style.textAlign);
    },
    isJustified() {
      return ["justify", "justify-all"].includes(this.current_text_style.textAlign);
    },
    isBold() {
      const fontWeight = this.current_text_style.fontWeight;
      return (
        fontWeight && (parseInt(fontWeight) > 400 || fontWeight.indexOf("bold") == 0)
      );
    },
    isItalic() {
      return this.current_text_style.fontStyle == "italic";
    },
    isUnderline() {
      // text-decoration is not overridden by children, so we query the parent stack
      const stack = this.current_text_style.textDecorationStack;
      return stack && stack.some((d) => d.indexOf("underline") == 0);
    },
    isStrikeThrough() {
      // text-decoration is not overridden by children, so we query the parent stack
      const stack = this.current_text_style.textDecorationStack;
      return stack && stack.some((d) => d.indexOf("line-through") == 0);
    },
    isNumberedList() {
      return (
        this.current_text_style.isList &&
        this.current_text_style.listStyleType == "decimal"
      );
    },
    isBulletedList() {
      return (
        this.current_text_style.isList &&
        ["disc", "circle"].includes(this.current_text_style.listStyleType)
      );
    },
    isH1() {
      return this.current_text_style.headerLevel == 1;
    },
    isH2() {
      return this.current_text_style.headerLevel == 2;
    },
    isH3() {
      return this.current_text_style.headerLevel == 3;
    },
    curFontSize() {
      return parseFloat(this.current_text_style.fontSize);
    },
    curFontFamily() {
      return this.current_text_style.fontFamily;
    },
    curColor() {
      return this.current_text_style.color || "transparent";
    },

    // Platform management
    isMacLike: () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),

    // Undo / redo flags
    can_undo() {
      return this.undo_count > 0;
    },
    can_redo() {
      return this.content_history.length - this.undo_count - 1 > 0;
    },
  },

  methods: {
    //Open context menu to generate wildcards
    openContextMenu(x, y) {
      this.show_button = true;
      let div = document.querySelector("#context-menu");
      Object.assign(div.style, { left: x + "px", top: y + "px" });
      eventBus.$emit("canCreateWildcard", (responseFromService) => {
        this.disableAllButton = !responseFromService;
      });
    },

    //Check if editor content was cleared
    checkWildcards() {
      const wildcardsCount = document.querySelectorAll(".wildcard").length;
      if (wildcardsCount > 0) return;
      this.wildcards = [];
      eventBus.$emit("clearWildcards");
    },

    //Check if editor has a text selected
    isTextSelected() {
      var sel = window.getSelection();
      if (sel.rangeCount < 1) return false;

      var range = sel.getRangeAt(0);
      if (range.collapsed) return false;

      return range.toString().length > 0 && range.toString().length < 40;
    },

    removeHighlight(wildcard) {
      //Get element's text
      const highlightedText =
        wildcard.type === "date" ? "" : wildcard.element.textContent;
      const fields = wildcard.type === "date" ? null : highlightedText.split(">");

      //Get the element
      const element = document.getElementById(wildcard.id);

      if (!element) return;

      //Get the element's parent
      const parent = element.parentNode;
      if (!parent) return;

      //Create a text node:
      const newNode = document.createTextNode(fields ? fields[1] : highlightedText);

      //Insert it into the document before the element
      parent.insertBefore(newNode, element);

      //Remove the element
      parent.removeChild(element);

      //Delete the wildcard from the array
      this.$delete(this.wildcards, this.wildcards.indexOf(wildcard));
    },

    createDateWildcard(period) {
      this.showDateButtons = false;

      //Get current node
      let currentNode = document.getSelection().anchorNode.parentElement;
      const id = Math.random().toString().substr(2, 6);

      //Wrap selected text in span tag
      document.execCommand(
        "insertHTML",
        false,
        `<span id='${id}' class='date wildcard'> Data ${period === "start" ? "de Inicio" : "Final"
        } do contrato </span>`
      );

      //Assert class were added
      currentNode = document.getSelection().anchorNode;
      currentNode = currentNode.nodeType === 3 ? currentNode.parentElement : currentNode;

      if (currentNode.className.length === 0 && currentNode.tagName === "SPAN") {
        currentNode.className = "date wildcard";
        currentNode.style = "";
        currentNode.id = id;
      }

      //Create the wildcard and add to array
      this.wildcards.push({
        type: "date",
        id: id,
        element: currentNode,
      });

      //Emit the action
      eventBus.$emit("dateAddedToEditor");
    },

    //Create a wildcard (wrap selected text with a <span class='wildcard'> tag)
    createWildcard(value) {
      if (!this.isTextSelected()) return;

      //Reset show wildcard button flag
      this.show_button = false;

      //Get current node
      let currentNode = document.getSelection().anchorNode.parentElement;

      //Avoid double tag
      if (currentNode.tagName === "SPAN" && currentNode.className === "wildcard") return;

      //Generate random id
      const id = Math.random().toString().substr(2, 6);

      //Get the selected text string
      let selectedText = this.getSelectionText();

      //Special character validation (replace all)
      const regex = /[^a-zA-Z0-9 \u00C0-\u00FF\-\/]/;
      if (regex.test(selectedText)) {
        selectedText = selectedText.replace(/[^a-zA-Z0-9 \u00C0-\u00FF\-\/]/gi, "");
      }

      //Encapsulate the selected text with the wildcard tag
      const wcSpan =
        `<span data-value='${selectedText}' class='${value} wildcard' id='${id}'>` +
        document.getSelection() +
        `</span>`;
      document.execCommand("insertHTML", false, wcSpan);

      //Get the created node
      currentNode = document.getSelection().anchorNode;

      //Set the text content according to value
      currentNode.textContent =
        value === "field" ? `Campos > ${selectedText}` : `Cobrança > ${selectedText}`;

      //Assert class were added
      if (currentNode.className.length === 0 && currentNode.tagName === "SPAN") {
        currentNode.className = `${value} wildcard`;
        currentNode.style = "";
        currentNode.id = id;
      }

      //Create the object
      const newWc = {
        id: id,
        type: value,
        name: selectedText,
        element: currentNode,
      };

      //Check duplicated
      const wcFilter = this.wildcards.filter(
        (wc) => wc.name.trim() === selectedText && wc.type === value
      );
      const tabValue = value === "field" ? 1 : value === "date" ? 2 : 3;

      //Emit the action
      if (wcFilter.length === 0) eventBus.$emit("newWildcard", tabValue, selectedText);

      //Push new object into array
      this.wildcards.push(newWc);
    },

    getSelectionText() {
      var text = "";
      if (window.getSelection) {
        text = window.getSelection().toString();
      } else if (document.selection && document.selection.type != "Control") {
        text = document.selection.createRange().text;
      }
      //Return selected text string
      return text.trim();
    },

    // updateWildcard(oldValue, newValue) {
    //   //Replace all span tags that represents wildcards with {{}} identifier
    //   const targetWc = this.wildcards.filter((wc) => wc.name.trim() === oldValue.trim());

    //   targetWc.forEach((wc) => {
    //     var fields = wc.element.textContent.split(">");
    //     wc.name = newValue;
    //     wc.element.textContent = `${fields[0]} > ${newValue}`;
    //   });
    // },

    replaceWildcardsAndGetEditorHTML() {
      //Create a copy of the editor element so the changes don't affect the dom
      const editor = document.getElementsByClassName("content")[0].cloneNode(true);
      
      // check if there is any trash HTML in the element and create a clean element to use
      const cleanEditor = this.checkHTMLElements(editor)

      //Get all wildcards elements
      const wildcards = cleanEditor.querySelectorAll(".wildcard");

      //Replace the wildcards with the {{ }}
      wildcards.forEach((wc) => {
        //Get the text content
        let textContent = wc.classList.contains("date")
          ? wc.textContent
          : wc.textContent.split(">")[1].trimStart();
        //textContent = textContent.replaceAll(" ", "_");

        //Get the element's parent
        const parent = wc.parentNode;

        //Generate a prefix for the wildcard being replaced
        const prefix = wc.classList.contains("money")
          ? "billing"
          : wc.classList.contains("field")
            ? "requiredInformation"
            : "";

        //Create a text node:
        const newNode = document.createTextNode(`{{${prefix}["${textContent.trim()}"]}}`);

        //Insert it into the document before the element
        parent.insertBefore(newNode, wc);

        //Remove the element
        parent.removeChild(wc);
      });
      //Return the innerHTML of the editor without the wildcards
      return cleanEditor.innerHTML;
    },

    checkHTMLElements(editor) { 
      editor.childNodes.forEach(page => {
        const regex = /<\/?(?!p|ol|ul|b|u|li|strike|img|br|s)\w+(?:\s+\w+(?:\s*=\s*(?:"[^"]*"|'[^']*'|[\^'">\s]+))?)*\s*\/?>/gi
        const hasDisallowedTags = page.innerHTML.match(regex) !== null;

        if (hasDisallowedTags) {
          page.innerHTML = page.innerHTML.replace(regex, (match) => {
            if (match.charAt(1) === '/') {
              return '</p>';
            } else {
              return '<p>';
            }
          })
        } 
      })
      return editor
    },

    // Page overlays (headers, footers, page numbers)
    overlay(page, total) {
      // Add page numbers on each page
      let html =
        '<div style="position: absolute; bottom: 8mm; ' +
        (page % 2 ? "right" : "left") +
        ': 10mm">Page ' +
        page +
        " of " +
        total +
        "</div>";

      // Add custom headers and footers from page 3
      if (page >= 3) {
        html +=
          '<div style="position: absolute; left: 0; top: 0; right: 0; padding: 3mm 5mm; background: rgba(200, 220, 240, 0.5)"><strong>MYCOMPANY</strong> example.com /// This is a custom header overlay</div>';
        html +=
          '<div style="position: absolute; left: 10mm; right: 10mm; bottom: 5mm; text-align:center; font-size:10pt">MY COMPANY - example.com /// This is a custom footer overlay</div>';
      }
      return html;
    },

    // Undo / redo functions examples
    undo() {
      if (this.can_undo) {
        this._mute_next_content_watcher = true;
        this.content = this.content_history[--this.undo_count];
      }
    },
    redo() {
      if (this.can_redo) {
        this._mute_next_content_watcher = true;
        this.content = this.content_history[++this.undo_count];
      }
    },
    resetContentHistory() {
      this.content_history = [];
      this.undo_count = -1;
    },
    
    parseTemplateFile(file) {

      //Generate a DOM with HTML from the template file
      const dom = new DOMParser().parseFromString(file, "text/html");

      const editor = dom.getElementsByTagName("SECTION")[0].cloneNode(true);

      // clean DOM of any trash html tags
      const cleanEditor = this.checkHTMLElements(editor)

      //Get all pages
      const pages = Array.from(cleanEditor.getElementsByTagName("div"));

      //Loop trought each page
      pages.forEach((page, index) => {

        //Get only the content inside the div tag
        let pageContent = this.insertWildcard(page.innerHTML);

        //If first page set first content
        if (index === 0) this.content[0] = pageContent;
        //Else push page to content array
        else this.content.push(pageContent);
      });

      this.key += 1;
    },

    insertWildcard(page) {
      //Regex used to identify the wildcards
      const regex = /\{\{+(.*?)\}\}+/g;

      //Find all matching values
      const wildcards = page.match(regex);

      if (!wildcards) return page;

      //Loop through each wildcard
      wildcards.forEach((wc) => {

        //Regex to get the value of the wildcard
        const innerText = wc.match(/\[\"+(.*?)\"\]+/g);
        if (!innerText) return page;

        //Get wildcatdn name and create a prefix according to wildcard type
        const name = innerText[0].replace('["', "").replace('"]', "");
        const prefix = wc.includes("requiredInformation")
          ? "Campos"
          : wc.includes("billing")
            ? "Cobrança"
            : "";
        const type = wc.includes("requiredInformation")
          ? "field"
          : wc.includes("billing")
            ? "money"
            : "";

        //Generate random id
        const id = Math.random().toString().substr(2, 6);

        //Generate the new text with the span tag and class style and replace unnecessary characters
        const newText = `<span id='${id}' data-value='${name}'class='${type} wildcard'> ${prefix} > ${name} </span>`;

        //Push wildcards to array
        this.wildcards.push({ type: type, name: name, id: id });

        //Replace the page innerHTML with new values
        page = page.replace(wc, newText);
      });

      return page;
    },

    beforeUpload(file) {
      this.file = null;
      return false;
    },

    

  },

  watch: {
    templateFile: {
      immediate: true,
      handler() {
        if (this.templateFile) this.parseTemplateFile(this.templateFile);
      },
    },
    content: {
      immediate: true,
      // Fill undo / redo history stack on user input
      handler(new_content) {
        if (!this._mute_next_content_watcher) {
          // only update the stack when content is changed by user input, not undo/redo commands
          this.content_history[++this.undo_count] = new_content;
          this.content_history.length = this.undo_count + 1; // remove all redo items
        }
        this._mute_next_content_watcher = false;
      },
    },
  },
};
</script>

<style>
/* Import custom fonts into css*/
@font-face {
  font-family: OpenSans;
  src: url("./imports/fonts/OpenSans.ttf");
}

@font-face {
  font-family: Verdana;
  src: url("./imports/fonts/Verdana.ttf");
}

@font-face {
  font-family: Roboto;
  src: url("./imports/fonts/Roboto.ttf");
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background: rgb(248, 249, 250);
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
  background: #edf6ff !important;
}

.p-contextmenu {
  width: fit-content;
}

.p-contextmenu .p-submenu-list {
  min-width: 65% !important;
}

.p-button-label {
  font-weight: 500;
}

.p-button.p-button-text {
  color: #64067F;
  background-color: #fff;
}

.p-button.p-button-text:enabled:hover {
  background-color: #fff !important;
}

#floating-button {
  animation: fadein 0.2s;
  position: fixed;
  top: 100px;
  display: flex;
  flex-direction: column;
}

#floating-button button {
  margin: 0 !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.wildcard {
  box-shadow: 3px 3px 4px darkgrey !important;
  padding: 4px;
}

.field {
  background-color: #ebe698;
}

.date {
  background-color: #c6e2ff;
}

.money {
  background-color: #c6ffd6;
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border: 5px solid transparent;
  border-radius: 16px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* TABLE CONTEXT MENU */
#context-menu {
  z-index: 1000;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  position: fixed;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 2px 2px 2px #ddd;
  width: min-content;
}

#context-menu span {
  padding: 0 20px;
}

#context-menu span:hover,
#context-menu button:hover {
  background-color: #f3f3f3;
}

.border {
  border-bottom: 1px solid #ddd !important;
  margin: 0 !important;
  width: 100%;
  margin-left: 0 !important;
}
</style>

<style scoped>
.main {
  font-family: Helvetica !important;
  /*   width: fit-content;
  min-width: 100%; */
}

.editor {
  overflow: scroll;
}

.bar {
  display: flex;
  justify-content: center;
  padding: 5px;
  position: sticky;
  border-radius: 3px;
  box-shadow: 0 1px 3px 1px #ddd;
  left: 0;
  top: 0;
  width: fit-content;
  /* width: calc(100vw - 16px); */
  z-index: 1000;
  background: rgba(248, 249, 250, 0.8);
  border-bottom: solid 1px rgb(248, 249, 250);
  backdrop-filter: blur(10px);
  --bar-button-active-color: #64067F;
  --bar-button-open-color: #64067F;
  --bar-button-active-bkg: #EFE2F0;
  --bar-button-open-bkg: #EFE2F0;
}
</style>
