
import {
  BillingPeriodType,
  BillingSpecification,
  EndDatePolicyType,
  RequiredInformation,
  StartDatePolicyType,
  Template,
  TemplateDTO,
  WildcardType,
  DigitalContractInputFileType,
  WildcardMetadata,
  ContractIssuer,
  SearchResult,
  SearchResultType,
  IdentificationType,
  Tags as TagsModel
} from "@/types/contract";
import { RequiredInformationTypes } from "@/types/utils";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import RenewAccess from "@/components/RenewAccess.vue";
import contracts from "@/services/contracts";
import ErrorWrapper from "@/utils/ErrorWrapper";
import StlInterceptor, { SecurityTierLevels } from "@/utils/stl";
import eventBus from "@/utils/eventBus";
import tags from "@/services/tags";
import EmailTemplateEditor from "@/components/EmailTemplateEditor.vue";
import { EmailTypes, MailTemplate } from "@/types/organization";
import organization from "@/services/organization"
import Tags from "./Tags.vue";
import SearchBar from "./SearchBar.vue";

@Component({ components: { RenewAccess, EmailTemplateEditor, Tags, SearchBar } })
export default class TemplateDataReview extends Vue {
  @Prop({ type: Object }) templateData!: any;
  @Prop({ type: String }) name!: string;
  @Prop({ type: Boolean }) visible!: boolean;
  @Prop({ type: Boolean }) editing!: boolean;
  @Prop({ type: Boolean }) templateFromWord!: boolean;
  @Prop({ type: File }) templateFile!: File;
  @Prop({ type: Array, required: false }) issuers!: ContractIssuer[];

  savingMode = false;
  savingSuccess = false;
  loading = false;

  template: any = {};

  renewAccessDialog = false;

  emailEditor = false;

  emailType = "" as EmailTypes;

  emailTypes = EmailTypes;

  globalMailTemplates: MailTemplate[] | undefined = undefined;

  hasPersonalizedIssueMail = "";
  hasPersonalizedStateMail = "";
  hasPersonalizedSignMail = "";

  contractName = "";

  selectedTab: "name" | "issuers" | "confirmation" | "tags" | "email" | "review" = "name";

  searchResultType = SearchResultType;
  selectedUsers: SearchResult[] = [];
  tags: string[] = [];
  allowIssuer = false;
  confirmRead = false;
  typeOfConfirmation: "clauses" = "clauses";
  clausesToConfirm = "";

  created() {
    this.template = this.templateData;
    this.fetchMailTemplate();

    if (this.name) this.contractName = this.name;

    this.clausesToConfirm = this.templateData.clausesToConfirm ? this.templateData.clausesToConfirm.join("; ") : "";

    this.confirmRead = this.clausesToConfirm.length > 0;

    this.allowIssuer = this.templateData.allowIssuerToSendRequiredData ? this.templateData.allowIssuerToSendRequiredData : false;

    eventBus.$on("editMailTemplate", (mailTemplate: MailTemplate) => {
      if (this.templateData.mailTemplates) {
        const mailTemplates = this.templateData.mailTemplates.filter((t: MailTemplate) => t.mailType !== mailTemplate.mailType) as MailTemplate[]
        mailTemplates.push(mailTemplate)
        this.templateData.mailTemplates = mailTemplates
      } else {
        this.templateData.mailTemplates = [mailTemplate]
      }
    })

    this.fetchData();

  }

  public fetchData() {
    if (this.editing) {
      eventBus.$emit("fetchIssuers", (fetchedIssuers: ContractIssuer[]) => {
        if (fetchedIssuers) {
          fetchedIssuers.forEach(async i => {
            if (i.identificationType === IdentificationType.ORGANIZATION_USER && i.identification.uuid) {
              const user = await organization.fetchMember(i.identification.uuid)
              const sr: SearchResult = {
                id: i.identification.uuid,
                description: user.fullname,
                subdescription: user.email,
                type: user.customerOrAdministrator ? SearchResultType.ORGANIZATION_ACCOUNT : SearchResultType.ORGANIZATION_SUBUSER_ACCOUNT,
                object: user
              }
              this.selectedUsers.push(sr)
            }
            else if (i.identificationType === IdentificationType.ORGANIZATION_TEAM && i.identification.teamId) {
              const team = await organization.fetchTeam(i.identification.teamId)
              const sr: SearchResult = {
                id: i.identification.teamId,
                description: team.name,
                subdescription: team.guid,
                type: SearchResultType.ORGANIZATION_TEAM,
                object: team
              }
              this.selectedUsers.push(sr)
            }
          })
          this.allowIssuer = true;
        }
      });

      eventBus.$emit("fetchTags", (fetchedTags: any) => {
        if (fetchedTags) this.tags = fetchedTags;
      });
    }
  }

  selectedMailTemplate: MailTemplate = {
    mailTemplate: "",
    mailType: EmailTypes.ISSUE_CONTRACT
  }

  public handleOpenEmailEditor(type: EmailTypes) {
    this.emailEditor = true
    this.emailType = type
    if (this.globalMailTemplates) {
      const mt = this.globalMailTemplates.filter(t => t.mailType === type)
      if (mt) this.selectedMailTemplate = mt[0]
    }
  }

  async fetchMailTemplate() {
    try {

      const response = await organization.fetchOrganizationEmail()

      let gmt: MailTemplate[] = []
      if (this.templateData && this.templateData.mailTemplates) {

        this.templateData.mailTemplates.forEach((mt: MailTemplate) => {
          switch (mt.mailType) {
            case EmailTypes.ISSUE_CONTRACT:
              this.hasPersonalizedIssueMail = "template"
              break;
            case EmailTypes.CHANGE_STATES:
              this.hasPersonalizedStateMail = "template"
              break;
            case EmailTypes.FULLY_SIGNED:
              this.hasPersonalizedSignMail = "template"
              break;
          }
        })

        response.forEach(mt => {
          switch (mt.mailType) {
            case EmailTypes.ISSUE_CONTRACT:
              if (this.hasPersonalizedIssueMail === "") {
                this.hasPersonalizedIssueMail = "global"
              }
              break;
            case EmailTypes.CHANGE_STATES:
              if (this.hasPersonalizedStateMail === "") {
                this.hasPersonalizedStateMail = "global"
              }
              break;
            case EmailTypes.FULLY_SIGNED:
              if (this.hasPersonalizedSignMail === "") {
                this.hasPersonalizedSignMail = "global"
              }
              break;
          }
        })

        gmt = this.templateData.mailTemplates as MailTemplate[]
        for (const mt of response) {
          const exist = gmt.some(t => t.mailType === mt.mailType)
          if (!exist) gmt.push(mt)
        }
      } else {
        gmt = response
      }

      this.globalMailTemplates = gmt



    } catch (e) {
      const err = new ErrorWrapper(e)

      switch (err.httpStatus) {
        case 404:
        case 403:
        case 402:
          break;
        default:
          console.error(err)
          this.$message.error(err.message);
          break;
      }
    }
  }

  public getRiValue(ri: RequiredInformation) {
    const type = RequiredInformationTypes.find(
      (r) => r.value === ri.requiredInformation.type
    );
    return type?.label;
  }

  public getVpValue(period: string) {
    if (!this.templateData.validityPeriod) return;
    if (period === "start")
      switch (this.templateData.validityPeriod.startDatePolicyType) {
        case StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES:
          return "Quando todas as partes assinarem";
        case StartDatePolicyType.SET_BY_ISSUER:
          return "Definido durante a emissão";
        default:
          break;
      }
    else
      switch (this.templateData.validityPeriod.endDatePolicyType) {
        case EndDatePolicyType.UNDETERMINED:
          return "Contrato com prazo indeterminado";
        case EndDatePolicyType.SET_BY_ISSUER:
          return "Defininido durante a emissão";
        case EndDatePolicyType.SET_AUTOMATICALLY_AFTER_START_DATE:
          return `Automaticamente após o inicio do contrato`;
        default:
          break;
      }
  }

  public getBillingValue(billing: BillingSpecification) {
    switch (billing.billingPeriodType) {
      case BillingPeriodType.ONE_TIME_PAYMENT:
        return "Cobrança única";
      case BillingPeriodType.RECURRING:
        return "Cobrança recorrente";
      case BillingPeriodType.INSTALLMENTS:
        return `Cobrança parcelada em ${billing.billingPeriod?.maxInstallments} vezes`;
      default:
        break;
    }
  }
  public close() {
    this.savingMode = false;
    this.emailEditor = false
    this.selectedTab = "name";
    this.$emit("closeDialog");
  }

  public save() {
    if (this.editing) this.checkStlLevel();
    else this.saveTemplate();
  }

  public checkStlLevel() {
    StlInterceptor.require(SecurityTierLevels.SamePerson)
      .then(() => this.saveTemplate())
      .catch(() => (this.renewAccessDialog = true));
  }

  public sessionRenewed() {
    this.renewAccessDialog = false;
    this.saveTemplate();
  }

  public generateTempObj() {
    const normalizedTags = {
      tags: this.template.contractTags,
    }
    const template: TemplateDTO = {
      name: this.contractName,
      legalRegionOfIssuance: "BRA",
      id: this.template.id,
      templateInputFile: this.template.templateInputFile,
      validityPeriod: this.template.validityPeriod,
      contractParties: this.template.contractParties,
      contractIssuers: this.template.contractIssuers,
      requiredContractPartiesInformation: [],
      requiredAttachments: [],
      billingSpecifications: [],
      allowIssuerToSendRequiredData: this.template.allowIssuerToSendRequiredData,
      wildcardMetadata: [],
      contractTags: normalizedTags,
      mailTemplates: [],
      contractPartiesSignatureOrder: this.template.contractPartiesSignatureOrder
    };

    if (this.template.requiredContractPartiesInformation.length > 0) {
      this.template.requiredContractPartiesInformation.forEach((cpi: any) => {

        const i: RequiredInformation = {
          contractPartyRole: cpi.contractPartyRole || cpi.contractParty.role,
          requiredInformation: {
            name: cpi.requiredInformation.name,
            type: cpi.requiredInformation.type,
            required: cpi.requiredInformation.required,
            description: cpi.requiredInformation.description,
            options: cpi.requiredInformation.options,
          },
          isWildcard: cpi.isWildcard
        };
        template.requiredContractPartiesInformation.push(i);
      });
    }

    if (this.template.billingSpecifications.length > 0) {
      this.template.billingSpecifications.forEach((billing: any) => {

        const b: BillingSpecification = {
          required: true,
          name: billing.name,
          billingPeriodType: billing.billingPeriodType,
          debtorContractPartyRole: billing.debtorContractPartyRole || billing.debtorContractParty.role,
          maximumValue: billing.maximumValue,
          minimumValue: billing.minimumValue,
          isWildcard: billing.isWildcard
        };


        if (!b.maximumValue || !b.minimumValue) {
          billing.maximumValue = 9999999999
          billing.minimumValue = 0
        };

        if (billing.billingPeriodType === BillingPeriodType.INSTALLMENTS)
          Object.assign(b, { billingPeriod: billing.billingPeriod })
        template.billingSpecifications.push(b);
      });
    }

    if (this.template.requiredAttachments.length > 0) {
      this.template.requiredAttachments.forEach((att: any) => {
        const a = {
          name: att.name,
          required: att.required,
          contractPartyRole: att.contractPartyRole || att.contractParty.role,
          requiresApproval: att.requiresApproval
        }
        template.requiredAttachments.push(a)
      });
    }
    return template;
  }

  public async saveTemplate() {
    this.savingMode = true;
    this.loading = true;

    const contractIssuers: ContractIssuer[] = [];

    this.selectedUsers.forEach((user) => {
      const type =
        user.type === SearchResultType.ORGANIZATION_TEAM
          ? IdentificationType.ORGANIZATION_TEAM
          : IdentificationType.ORGANIZATION_USER;

      const fieldName =
        user.type === SearchResultType.ORGANIZATION_TEAM ? "teamId" : "uuid";

      const fieldValue =
        user.type === SearchResultType.ORGANIZATION_TEAM
          ? user.object.guid
          : user.object.uuid;

      const currentIssuer: ContractIssuer = {
        identificationType: type,
        identification: {
          [fieldName]: fieldValue,
        },
      };

      contractIssuers.push(currentIssuer);
    });

    let clauses: string[] = []
    if (this.clausesToConfirm !== "") {
      clauses = this.clausesToConfirm.split(";").map(s => s.trim());
      clauses = clauses.filter(s => s !== "");
    }

    const normalizedTags = {
      tags: this.tags
    }
    try {
      let temp = this.editing ? this.generateTempObj() : this.templateData;

      temp.contractIssuers = contractIssuers;
      temp.name = this.contractName;
      temp.allowIssuerToSendRequiredData = this.allowIssuer;

      if (this.confirmRead) temp.clausesToConfirm = clauses;

      const wcMetadata: WildcardMetadata[] = []
      temp.requiredContractPartiesInformation.forEach((req: RequiredInformation) => {
        if (req.isWildcard) {
          const wcMeta: WildcardMetadata = {
            name: req.requiredInformation.name,
            type: WildcardType.REQUIRED_INFORMATION
          };
          wcMetadata.push(wcMeta);
        }
      })
      temp.billingSpecifications.forEach((bill: BillingSpecification) => {
        if (bill.isWildcard) {
          const wcMeta: WildcardMetadata = {
            name: bill.name,
            type: WildcardType.BILLING
          }
          wcMetadata.push(wcMeta)
        }
      })
      temp.contractTags = normalizedTags;

      temp.wildcardMetadata = wcMetadata;

      console.log("temp", temp)

      const template = this.editing ? await contracts.update(temp.id, temp) : await contracts.register(this.templateData);

      if (this.templateFromWord) {
        let templateFile = null
        if (this.editing) {

          eventBus.$emit("getWordTemplateContent", (response: File) => {
            if (!response) {
              templateFile = this.templateFile;
            }
            else {
              templateFile = new Blob([response], { type: response.type });
            }
          })
        }
        else {
          eventBus.$emit("getWordTemplateContent", (response: File) => {
            templateFile = new Blob([response], { type: response.type });
          })
        }
        if (!template || !templateFile) return;
        await contracts.uploadTemplateFile(template.id, templateFile, DigitalContractInputFileType.WORD_1_0);
      }
      else {
        let templateFile = null;
        eventBus.$emit("getTemplateHTMLContent", (response: string) => {
          response = response.replaceAll(/&nbsp;/g, " ");
          templateFile = `<section>${response}</section>`;
        });
        if (!template || !templateFile) return;
        const templateBlob = new Blob([templateFile], { type: "text/plain" });
        await contracts.uploadTemplateFile(template.id, templateBlob, DigitalContractInputFileType.HTML_1_0);
      }
      this.savingSuccess = true;
    } catch (e) {
      console.error(e)
      const err = new ErrorWrapper(e);
      this.$message.error(`Ocorreu um erro: ${err.message}`);
    } finally {
      this.loading = false;
    }
  }

  public goToNextTab() {
    switch (this.selectedTab) {
      case "name":
        this.selectedTab = "issuers";
        break;
      case "issuers":
        this.selectedTab = "confirmation";
        break;
      case "confirmation":
        this.selectedTab = "tags";
        break;
      case "tags":
        this.selectedTab = "email";
        break;
      case "email":
        this.selectedTab = "review";
        break;
    }
  }

  public updateName() {
    this.templateData.name = this.contractName;
  }

  public handleUpdateIssuers() {

  }

  public handleTagAdded(tag: any) {
    tag = tag.trim().toLowerCase();
    if (!this.tags.some((t: any) => t === tag)) {
      this.tags.push(tag);
    }
  }

  public removeTag(tag: any) {
    const updatedTags = this.tags.filter((t: any) => t !== tag);
    this.tags = updatedTags
  }

  /**
   * Handle change select of organization member
   * @param member (user account or organization team)
   */
  public selectedOrganizationMember(member: any) {
    const filter = this.selectedUsers.find((user) => user.id === member.id);
    if (!filter) this.selectedUsers.push(member);
  }

  /**
   * Remove an user from the list
   * @param user
   */
  public removeUser(user: any) {
    this.$delete(this.selectedUsers, this.selectedUsers.indexOf(user));
  }

  public handleTabChange() {
    if (this.contractName.length <= 0) return false;
    return true;
  }

}
