// deno-lint-ignore-file

import { SearchResult, SearchResultType } from "@/types/contract";
import api from "@/services/contracts-api"

interface ApiSearchResult {
    type: 'ORGANIZATION_ACCOUNT' | 'ORGANIZATION_SUBUSER_ACCOUNT' | 'ORGANIZATION_TEAM';
    data: any
}

/**
 * Normalize the subdescription prop from the API search
 * @param data 
 * @returns 
 */
function normalizeSubdescription(data: ApiSearchResult): string | null {
    switch (data.type) {
        case 'ORGANIZATION_TEAM':
            return data.data.guid;
        case 'ORGANIZATION_SUBUSER_ACCOUNT': case 'ORGANIZATION_ACCOUNT':
            return data.data.email;
        default:
            return null;
    }
}

/**
 * Normalize the description prop from the API search
 * @param data 
 * @returns 
 */
function normalizeDescription(data: ApiSearchResult): string {
    switch (data.type) {
        case 'ORGANIZATION_SUBUSER_ACCOUNT': case 'ORGANIZATION_ACCOUNT':
            return data.data.fullname;
        case 'ORGANIZATION_TEAM':
            return data.data.name;
        default:
            throw Error(`Not identified entity: ${data.type}`);
    }
}

/**
 * Normalize the id prop from the API search
 * @param data 
 * @returns 
 */
function normalizeId(data: ApiSearchResult): string {
    switch (data.type) {
        case 'ORGANIZATION_SUBUSER_ACCOUNT': case 'ORGANIZATION_ACCOUNT':
            return data.data.uuid;
        case 'ORGANIZATION_TEAM':
            return data.data.guid;
        default:
            throw Error(`Not identified entity: ${data.type}`);
    }
}

const SearchService = {
    /**
     * Search all teams associated with the authenticated user organization from Klaus Accounts
     * @param query 
     * @returns 
     */
    allEntitiesFromOrganization: async (query: string = ''): Promise<SearchResult[] | null> => {

        //Fetch the result from the API
        const response = await api.get(`/rest/v1/organizations/my/search/all-entities?q=${query}`);
     
        //If the response is empty, return null
        if (!response || response.status === 204) return null;

        //Parse the response
        const responseData = await (response).data as ApiSearchResult[];

        //Normalize the data
        return responseData.map((data): SearchResult => {
            return {
                description: normalizeDescription(data),
                subdescription: normalizeSubdescription(data),
                type: data.type as SearchResultType,
                id: normalizeId(data),
                object: data.data
            }
        })
    },

    allUsersFromOrganization: async (query: string = ''): Promise<SearchResult[] | null> => {
        const response = await api.get(`/rest/v1/organizations/my/search/users?q=${query}`)

        //If the response is empty, return null
        if (!response || response.status === 204) return null;

        //Parse the response
        const responseData = await (response).data as ApiSearchResult[];

        //Normalize the data
        return responseData.map((data): SearchResult => {
            return {
                description: normalizeDescription(data),
                subdescription: normalizeSubdescription(data),
                type: data.type as SearchResultType,
                id: normalizeId(data),
                object: data.data
            }
        })
    }
}


export default SearchService;