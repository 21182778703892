
import { reactive, toRefs } from 'vue';
import TagsService from '../services/tags';

export default {
  name: 'Tags',
  props: {
    source: {
      type: Object,
      required: false,
    },
  },
  emits: ['tagAdded'],
  methods: {
    async fetchTags(queryString: string, cb: any) {
      try {
        if (queryString) {
          const tags = await TagsService.searchTagsByName(queryString);
          if (tags.length > 0) {
            const normalizedTags = tags.map((tag: any) => {
              return {
                tagName: tag.data.tagName,
                hits: tag.data.hits,
              };
            });
            cb(normalizedTags);
          } else {
            cb([{ tagName: queryString }]);
          }
        } else {
          const tags = await TagsService.fetchTagsUsingPagination(1);
          cb(tags);
        }
      } catch (error) {
        console.error('Não foi possível buscar as tags');
      }
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      selectedTag: null,
      tags: new Set(),
      fetchedTags: [],
      addNewTag: false,
    });

    async function handleAddTag() {
      if (state.selectedTag) {
        if (!state.tags.has(state.selectedTag)) {
          state.tags.add(state.selectedTag);
          emit('tagAdded', state.selectedTag);
        }
      } else {
        const inputValue = state.selectedTag ? state.selectedTag : '';
        if (inputValue) {
          state.tags.add(inputValue);
          emit('tagAdded', inputValue);
        }
      }
      state.selectedTag = null;
      state.addNewTag = false;
    }

    return {
      ...toRefs(state),
      handleAddTag,
    };
  },
};
