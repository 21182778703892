
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import eventBus from "@/utils/eventBus";
import SearchBar from "../SearchBar.vue";
import {
  SearchResult,
  SearchResultType,
  ContractIssuer,
  IdentificationType,
  Template,
} from "@/types/contract";
import organization from "@/services/organization";
import WordTemplateDialog from "../template-from-word/WordTemplateDialog.vue";
import Tags from "../Tags.vue";

@Component({ components: { SearchBar, WordTemplateDialog, Tags } })
export default class Issuers extends Vue {
  @Prop({ type: Array, required: false }) issuers!: ContractIssuer[];

  searchResultType = SearchResultType;

  openDrawer = false;
  contractName = "";
  allowIssuer = false;
  selectedUsers: SearchResult[] = [];
  openEditWordDrawer = false;
  openDrawerToEditWordTemplate   = false;
  tags: any = [];

  @Watch("issuers")
  handler() {
    if (this.issuers) {
      this.issuers.forEach(async i => {
        if (i.identificationType === IdentificationType.ORGANIZATION_USER && i.identification.uuid) {
          const user = await organization.fetchMember(i.identification.uuid)
          const sr: SearchResult = {
            id: i.identification.uuid,
            description: user.fullname,
            subdescription: user.email,
            type: user.customerOrAdministrator ? SearchResultType.ORGANIZATION_ACCOUNT : SearchResultType.ORGANIZATION_SUBUSER_ACCOUNT,
            object: user
          }
          this.selectedUsers.push(sr)
        }
        else if (i.identificationType === IdentificationType.ORGANIZATION_TEAM && i.identification.teamId) {
          const team = await organization.fetchTeam(i.identification.teamId)
          const sr: SearchResult = {
            id: i.identification.teamId,
            description: team.name,
            subdescription: team.guid,
            type: SearchResultType.ORGANIZATION_TEAM,
            object: team
          }
          this.selectedUsers.push(sr)
        }
      })
    }

  }

  mounted() {
    eventBus.$on("openIssuersDrawer", (temp: Template) => {
      this.openDrawer = true;
      this.contractName = temp ? temp.name : "";
      this.allowIssuer = temp ? temp.allowIssuerToSendRequiredData : false;
      this.tags = temp ? temp.contractTags : [];
    });
  }

  /**
   * Handle change select of organization member
   * @param member (user account or organization team)
   */
  public selectedOrganizationMember(member: any) {
    const filter = this.selectedUsers.find((user) => user.id === member.id);
    if (!filter) this.selectedUsers.push(member);
  }

  /**
   * Remove an user from the list
   * @param user
   */
  public removeUser(user: any) {
    this.$delete(this.selectedUsers, this.selectedUsers.indexOf(user));
  }

  public saveTemplate() {
    this.openDrawer = false;

    let contractIssuers: ContractIssuer[] = [];
    this.selectedUsers.forEach((user) => {
      const type =
        user.type === SearchResultType.ORGANIZATION_TEAM
          ? IdentificationType.ORGANIZATION_TEAM
          : IdentificationType.ORGANIZATION_USER;

      const fieldName =
        user.type === SearchResultType.ORGANIZATION_TEAM ? "teamId" : "uuid";

      const fieldValue =
        user.type === SearchResultType.ORGANIZATION_TEAM
          ? user.object.guid
          : user.object.uuid;

      const currentIssuer: ContractIssuer = {
        identificationType: type,
        identification: {
          [fieldName]: fieldValue,
        },
      };

      contractIssuers.push(currentIssuer);
    });

    const normalizedTags = this.tags;

    eventBus.$emit("saveTemplate", this.contractName, contractIssuers, this.allowIssuer, normalizedTags);
  }

  public handleTagAdded(tag: any) {
    tag = tag.trim().toLowerCase();
    if (!this.tags.some((t: any) => t === tag)) {
      this.tags.push(tag);
    }
  }

  public removeTag(tag: any) {
    const updatedTags = this.tags.filter((t: any) => t !== tag);
    this.tags = updatedTags
  }
}
