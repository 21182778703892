
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SearchResultType, SearchResult } from "@/types/contract";
import SearchService from "@/services/search";
import authUtils from "@/utils/authentication";
import { UserAccount } from "@/types/user";
import authentication from "@/services/authentication";

@Component
export default class SearchBar extends Vue {
  @Prop({ type: String, required: true }) placeholder!: string;
  @Prop({ type: Boolean }) clearOnSelect!: boolean;
  @Prop({ type: String }) searchType!: string;


  userAccount = authUtils.user();

  searchResultType = SearchResultType;

  fixedOptions: SearchResult[] = [
    {
      description: `${this.userAccount.account.preferredName} (Você)`,
      id: this.userAccount.account.uuid,
      object: this.userAccount.account,
      subdescription: this.userAccount.account.email,
      type: this.userAccount.session.accountType === "CUSTOMER" ? this.searchResultType.ORGANIZATION_ACCOUNT : this.searchResultType.ORGANIZATION_SUBUSER_ACCOUNT
    },
  ]

  timeout: NodeJS.Timeout | null = null;
  value: SearchResult | null | string = null;
  item: SearchResult | null = null;
  
  created() {
    if (this.userAccount.session.accountType !== "CUSTOMER") {
      authentication.getOrganization().then(response => {
        const organizationAccount = {
          contractsRestrictViewModeEnabled: false,
          creationDate: response.creationDate,
          customerOrAdministrator: true,
          deletedAt: null,
          email: response.email,
          fullname: response.fullname,
          hasHandwrittenSignature: false,
          prefferedName: response.preferredName,
          preferredRegion: response.user.preferredRegion,
          uuid: response.uuid,
        }
        const option: SearchResult = {
          description: `${organizationAccount.prefferedName} (Sua Organização)`,
          id: organizationAccount.uuid,
          object: organizationAccount,
          subdescription: organizationAccount.email,
          type: SearchResultType.ORGANIZATION_ACCOUNT
        }
        this.fixedOptions.unshift(option)
      })
    }
  }

  public querySearchAsync(queryString: string, cb: any) {
    if (queryString === null || queryString === "") {
      this.item = (this.fixedOptions as unknown) as SearchResult;
      cb(this.fixedOptions);
      return;
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.searchType === "users") {
        SearchService.allUsersFromOrganization(queryString)
          .then((response) => {
            if (!response) return cb([]);
            this.item = (response as unknown) as SearchResult;
            cb(response);
          })
          .catch((e) => console.error(e));
      } else {
        SearchService.allEntitiesFromOrganization(queryString)
          .then((response) => {
            if (!response) return cb([]);
            const test = response.map(item => {
              const foundItem = this.fixedOptions.find(option => option.id === item.id)
              if (foundItem) {
                return foundItem;
              }
              return item
            })
            this.item = (test as unknown) as SearchResult;
            cb(test);
          })
          .catch((e) => console.error(e));
      }
    }, 500);
  }

  public handleSelect(selectedValue: SearchResult) {
    this.value = selectedValue.description;
    this.$emit("selectedValue", selectedValue);
    if (this.clearOnSelect) this.value = null;
  }
}
