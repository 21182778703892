// deno-lint-ignore-file
import api from "@/services/contracts-api"
import { MailTemplate, OrganizationInterfaceTheme, OrganizationQuota } from "@/types/organization";
import { OrganizationTeam, UserAccount } from "@/types/user";
import { PaginationMetadata } from "@/types/utils";
import { Pagination } from "element-ui";

interface ApiSearchResult {
    type: 'ORGANIZATION_ACCOUNT' | 'ORGANIZATION_SUBUSER_ACCOUNT' | 'ORGANIZATION_TEAM';
    data: any
}

const organization = {
    allEntities: async (query: string): Promise<ApiSearchResult[] | null> => {
        //Send request
        const response = await api.get(`/rest/v1/organizations/my/search/all-entities?q=${query}`);

        //If the response is empty, return null
        if (response.status === 204) return null;

        //Return data
        return await (response).data
    },

    fetchMember: async (uuid: string): Promise<UserAccount> => {
        return await (await api.get(`/rest/v1/organizations/my/search-member-by-uuid/${uuid}`)).data;
    },

    teamMembers: async (guid: string, page: number = 1): Promise<UserAccount[]> => {
        //Send request
        const response = await api.get(`/rest/v1/organization-teams/${guid}/members?page=${page}`);

        //Return data
        return await (response).data
    },

    teamMembersPagination: async (guid: string): Promise<PaginationMetadata> => {
        //Send request
        const response = await api.get(`/rest/v1/organization-teams/${guid}/members/pagination`);

        //Return data
        return await (response).data
    },

    fetchTeam: async (guid: string): Promise<OrganizationTeam> => {
        return await (await api.get(`/rest/v1/organization-teams/${guid}`)).data;
    },

    fetchOrganizationQuota: async (): Promise<OrganizationQuota> => {
        return await (await api.get("/rest/v1/organizations/my/contracts/quotas")).data;
    },

    fetchOrganizationEmail: async (): Promise<MailTemplate[]> => {
        return await (await api.get("/rest/v1/organizations/my/global-mail-template")).data.mailTemplates
    },

    fetchOrganizationInterfaceTheme: async (): Promise<OrganizationInterfaceTheme> => {
        return await (await api.get("/rest/v1/organizations/interface-theme")).data
    },

    updateGlobalMailTemplate: async (mailTemplates: MailTemplate): Promise<MailTemplate[]> => {
        return await (await api.put("/rest/v1/organizations/my/global-mail-template", mailTemplates)).data
    }
}

export default organization;