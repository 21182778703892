import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from './store'

Vue.config.productionTip = false

import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';

import ProgressBar from 'primevue/progressbar';

Vue.use(PrimeVue);
Vue.component('ProgressBar', ProgressBar);

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import locale from 'element-ui/lib/locale/lang/pt-br'
import '../element-variables.scss'
Vue.use(ElementUI, { locale });

// Importa o pacote de fontes
import "@/assets/css/fontawesome/css/fontawesome.css";
import "@/assets/css/fontawesome/css/fa-solid.css";
import "@/assets/css/fontawesome/css/fa-regular.css";
import fontAwesomeSvgs from "@/assets/css/fontawesome/svgs";
Vue.prototype.$fontAwesomeSvgs = fontAwesomeSvgs;

import authUtils from './utils/authentication';

const urlSearchParams = new URLSearchParams(window.location.search);
const authenticationTokenStr = urlSearchParams.get("authToken");
if (!authenticationTokenStr) {
  throw new Error("authToken not set");
}

// this algorithm will enforce that the contracts authentication token is passed on parameter
new Promise((resolve, reject) => {

  urlSearchParams.delete("authToken");

  // try to authenticate the user with the token
  authUtils.authenticate(authenticationTokenStr)
    .then(authUser => {
      resolve(authUser);
    })
    .catch(error => {
      reject(error);
    });

})
.then(authUser => {
  console.info("User authenticate successfully", authUser);
})
.catch(error => {
  console.error(error);
  authUtils.logout(() => {});
})
.finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
