
import { BillingSpecification, BillingPeriodType } from "@/types/contract";
import eventBus from "@/utils/eventBus";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

interface BillingSpecificationEditableTabs {
    numberOfInstallments?: any;
    value?: any;
    initialBillingPeriod?: any;
    required: boolean,
    name: string,
    billingPeriodType: BillingPeriodType,
    debtorContractPartyRole: string,
    description?: string,
    minimumValue: number,
    maximumValue: number,
    billingPeriod: {
        maxInstallments: number
    },
    debtorContractParty?: {
        role: string
    },
    isWildcard?: boolean
}

@Component
export default class Billings extends Vue {
    BillingPeriodType = BillingPeriodType
    @Prop({ type: Array }) billings!: BillingSpecificationEditableTabs[];

    //Date picker options - disable previous date
    pickerOptions = {
        disabledDate(time: any) {
            return false;
        },
    };

    rules = {
        ["value"]: [{ required: true, message: 'Campo obrigatório', trigger: "blur" }],
        ["numberOfInstallments"]: [{ required: true, message: 'Campo obrigatório', trigger: "blur" }],
        ["initialBillingPeriod"]: { type: 'date', required: true, message: 'Selecione uma data', trigger: 'change' },
    };

    parsedB: any = []
    created() {
        eventBus.$on("canIssueContract", (requestHandler: Function) => {
            const form: any = this.$refs.formBilling
            if (!form) return;
            form.forEach((f: any) => f.validate((valid: any) => requestHandler(valid)))
        })

        eventBus.$on("getIssueData", (requestHandler: Function) => {
            this.parsedB = []
            this.billings.forEach((b: any) => {

                const newB = {
                    name: b.name,
                    value: { value: Number(b.value), currency: "BRL" },
                    initialBillingPeriod: new Date(b.initialBillingPeriod).getTime(),
                    billingDueDay: new Date(b.initialBillingPeriod).getUTCDate(),
                    numberOfInstallments: Number(b.numberOfInstallments) || 1
                }

                this.parsedB.push(newB)
            })
            requestHandler("billingData", this.parsedB)
        })
    }
    public handleInputValue(currentValue: number, minValue: number, maxValue: number) {
        if (!currentValue) return minValue;
        if (currentValue < minValue) {
            return minValue
        } else if (currentValue > maxValue) {
            return maxValue
        } else {
            return currentValue
        }
    }


}

//Custom directive to change "," to "." on keydown event
Vue.directive('keydown', {
  bind(el: HTMLElement, binding: any) {
    el.addEventListener('keydown', event => {
      if (event.key === ',') {
        event.preventDefault();
        const input = event.target as HTMLInputElement;
        const value = input.value;
        const selectionStart = input.selectionStart ?? 0;
        const selectionEnd = input.selectionEnd ?? 0;
        const newValue = value.slice(0, selectionStart) + '.' + value.slice(selectionEnd);
        input.value = newValue;
        input.selectionStart = input.selectionEnd = selectionStart + 1;
        input.dispatchEvent(new Event('input'));
      }
    });
  }
});

