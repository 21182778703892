import { IdentificationType, RequiredInformationType, BillingPeriodType } from "./contract"

const IdentificationTypes = [
    { 
        label: 'Através de um email',
        value: IdentificationType.IDENTIFIED_BY_EMAIL
         
    },
    { 
        label: 'Membro da minha organização',
        value: IdentificationType.ORGANIZATION_USER || IdentificationType.ORGANIZATION_TEAM
    }
]

const RequiredInformationTypes = [
    {
        label: "Texto de linha única",
        value: RequiredInformationType.SINGLE_LINE_TEXT,
        icon: "pi pi-minus"
    },
    {
        label: "Data",
        value: RequiredInformationType.DATE,
        icon: "pi pi-calendar"
    },
    {
        label: "Horário",
        value: RequiredInformationType.TIME,
        icon: "pi pi-clock"
    },
    {
        label: "Endereço",
        value: RequiredInformationType.ADDRESS,
        icon: "pi pi-map"
    },
    {
        label: "Número inteiro",
        value: RequiredInformationType.INTEGER,
        icon: "pi pi-sort-numeric-up"
    },
    {
        label: "Número decimal",
        value: RequiredInformationType.DECIMAL,
        icon: "pi pi-sort-numeric-up"
    },
    {
        label: "CPF ou CNPJ",
        value: RequiredInformationType.BRA_CPF_OR_CNPJ,
        icon: "pi pi-id-card"
    },
    {
        label: "CPF",
        value: RequiredInformationType.BRA_CPF,
        icon: "pi pi-id-card"
    },
    {
        label: "CNPJ",
        value: RequiredInformationType.BRA_CNPJ,
        icon: "pi pi-building"
    },
    {
        label: "Data e hora",
        value: RequiredInformationType.TIMESTAMP,
        icon: "pi pi-calendar-times"
    },
    {
        label: "Seleção múltipla",
        value: RequiredInformationType.MULTIPLE_SELECT,
        icon: "pi pi-plus-circle"
    },
    {
        label: "Seleção única",
        value: RequiredInformationType.SINGLE_SELECT,
        icon: "pi pi-check-circle"
    },
    {
        label: "Email",
        value: RequiredInformationType.EMAIL,
        icon: "pi pi-at"
    },
]

const BillingPeriodTypes = [
    {
        label: "Cobrança única",
        value: BillingPeriodType.ONE_TIME_PAYMENT
    },
    {
        label: "Cobrança recorrente",
        value: BillingPeriodType.RECURRING
    },
    {
        label: "Cobrança parcelada",
        value: BillingPeriodType.INSTALLMENTS
    },
]

interface ContractPartiesSuggestionsType {
    label: string,
    value: string
  }

const ContractPartiesSuggestions : ContractPartiesSuggestionsType[] = [
    { label: 'Contratante', value: 'Contratante' },
    { label: 'Contratada', value: 'Contratada' },
    { label: 'Vendedor', value: 'Vendedor' },
    { label: 'Comprador', value: 'Comprador' },
    { label: 'Testemunha', value: 'Testemunha' },
    { label: 'Advogado Responsável', value: 'Advogado Responsável' },
    { label: 'Representante Legal', value: 'Representante Legal' },
    { label: 'Fiador', value: 'Fiador' },
    { label: 'Beneficiário', value: 'Beneficiário' },
    { label: 'Prestador de Serviços', value: 'Prestador de Serviços' },
    { label: 'Empreiteiro', value: 'Empreiteiro' },
    { label: 'Arrendatário', value: 'Arrendatário' },
    { label: 'Arrendador', value: 'Arrendador' },
    { label: 'Locatário', value: 'Locatário' },
    { label: 'Locador', value: 'Locador' },
    { label: 'Cessionário', value: 'Cessionário' },
    { label: 'Cedente', value: 'Cedente' },
    { label: 'Promitente Comprador', value: 'Promitente Comprador' },
    { label: 'Promitente Vendedor', value: 'Promitente Vendedor' },
    { label: 'Depositante', value: 'Depositante' },
    { label: 'Depositário', value: 'Depositário' },
    { label: 'Outorgante', value: 'Outorgante' },
    { label: 'Outorgado', value: 'Outorgado' },
    { label: 'Proprietário', value: 'Proprietário' },
    { label: 'Fornecedor', value: 'Fornecedor' },
    { label: 'Mutuário', value: 'Mutuário' },
    { label: 'Mutuante', value: 'Mutuante' },
    { label: 'Concessionária', value: 'Concessionária' },
    { label: 'Concedente', value: 'Concedente' },
    { label: 'Licenciado', value: 'Licenciado' },
    { label: 'Licenciante', value: 'Licenciante' },
    { label: 'Comodante', value: 'Comodante' },
    { label: 'Comodatário', value: 'Comodatário' },
    { label: 'Corretor', value: 'Corretor' },
    { label: 'Comissão', value: 'Comissão' },
    { label: 'Notário', value: 'Notário' },
    { label: 'Testamenteiro', value: 'Testamenteiro' },
    { label: 'Herdeiro', value: 'Herdeiro' },
    { label: 'Legatário', value: 'Legatário' },
    { label: 'Procurador', value: 'Procurador' },
    { label: 'Subcontratado', value: 'Subcontratado' },
    { label: 'Subcontratante', value: 'Subcontratante' },
    { label: 'Cedente de Direitos', value: 'Cedente de Direitos' },
    { label: 'Cessionário de Direitos', value: 'Cessionário de Direitos' },
    { label: 'Promotor', value: 'Promotor' },
    { label: 'Promovido', value: 'Promovido' },
    { label: 'Mandante', value: 'Mandante' },
    { label: 'Mandatário', value: 'Mandatário' },
    { label: 'Devedor', value: 'Devedor' },
    { label: 'Credor', value: 'Credor' }
]

enum DialogSteps {
    INSERT_PARTICIPANTS = "INSERT_PARTICIPANTS",
    INSERT_FIELDS = "INSERT_FIELDS",
    INSERT_RI = "INSERT_RI",
    INSERT_BILL = "INSERT_BILL",
    IMPORT_FILE = "IMPORT_FILE"
}

export interface PaginationMetadata {
    totalPages : number;
    totalRecords : number;
    recordsPerPage : number;
}

export { IdentificationTypes, RequiredInformationTypes, BillingPeriodTypes, ContractPartiesSuggestions, ContractPartiesSuggestionsType, DialogSteps };
