export interface OrganizationQuota {
    customerContractsQuota: {
        maxTemplateCount: number,
        maxSubusers: number,
        contractsIssuedPerMonth: number | null,
        maxStorageBytes: number,
        financialAnalyticsModuleEnabled: boolean,
        contractsFieldsAutomationEnabled: boolean,
        contractAttachmentsEnabled: boolean,
        plan: Plans
      },
      currentTemplateCount: number,
      currentSubusersCount: number,
      currentStorageConsumptionInBytes: number
      contractsIssuedPerMonth: number
}

export enum Plans {
    NO_QUOTA = "NO_QUOTA",
    PAY_AS_YOU_GO = "PAY_AS_YOU_GO",
    BASIC = "BASIC",
    SMALL_BUSINESS = "SMALL_BUSINESS",
    BUSINESS = "BUSINESS"
}

export enum EmailTypes {
    ISSUE_CONTRACT = "ISSUE_CONTRACT",
    CHANGE_STATES = "CHANGE_STATES",
    FULLY_SIGNED = "FULLY_SIGNED",
}

export interface MailTemplate {
    mailTemplate: string,
    mailType: EmailTypes,
}

export interface OrganizationInterfaceTheme {
    primaryColor: string,
    secondaryColor: string,
    logoUrl: string
}