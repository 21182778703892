
import { Component, Vue } from "vue-property-decorator";
import authUtils from "./utils/authentication";
import eventBus from "./utils/eventBus";


@Component
export default class App extends Vue {

  created() {
    let query = Object.assign({}, this.$route.query);
    if (query.authToken) {
      delete query.authToken;
      this.$router.replace({ query });
    }
  }

}
