
import Appbar from "@/components/Appbar.vue";
import Billings from "@/components/issued-from-file-tabs/Billings.vue";
import Parties from "@/components/issued-from-file-tabs/Parties.vue";
import Validity from "@/components/issued-from-file-tabs/ValidityPeriod.vue";
import SignatureOrder from "@/components/tabs/SignatureOrder.vue";
import eventBus from "@/utils/eventBus";
import { Component, Vue } from "vue-property-decorator";

import IssueDataReview from "@/components/issued-from-file-tabs/IssueDataReview.vue";
import organization from "@/services/organization";
import { ContractPartie } from "@/types/contract";
import { OrganizationQuota, Plans } from "@/types/organization";
import ErrorWrapper from "@/utils/ErrorWrapper";

@Component({ components: { Appbar, Billings, Validity, Parties, IssueDataReview, SignatureOrder } })
export default class IssueTemplate extends Vue {
  templateId: string | null | (string | null)[] = null;
  templateFileId = null;
  loading = false;
  activeTab = "0";

  tempName = "Teste";
  tempParties = [];
  tempBillings = [];
  tempValidity = {};

  showPreview = false;
  data: any = {};

  contractOnTop = false;

  tabIsDisabled = true

  contractParties: ContractPartie[] = [];

  pdfFile: any = null

  fileUploaded = false

  pdfBlobURL: any = null

  pdfBlob: any = null

  organizationQuota: OrganizationQuota = {
    customerContractsQuota: {
      contractAttachmentsEnabled: false,
      contractsFieldsAutomationEnabled: false,
      contractsIssuedPerMonth: 0,
      financialAnalyticsModuleEnabled: false,
      maxStorageBytes: 0,
      maxSubusers: 0,
      maxTemplateCount: 0,
      plan: Plans.NO_QUOTA
    },
    contractsIssuedPerMonth: 0,
    currentStorageConsumptionInBytes: 0,
    currentSubusersCount: 0,
    currentTemplateCount: 0
  };

  contractsQuotaPercentage = "0"
  contractsQuota: number | null = 0;
  contractsUsage = 0

  cpSignatureOrder: string[] = [];
  signatureOrderEnabled = false;

  created() {
    eventBus.$on("openIssueDrawer", () => {
      eventBus.$emit('getIssueData', (value: string, resp: any) => {
        if (value === 'contractParties') {
          this.data = { ...this.data, contractParties: resp }
        }
        else if (value === 'billingData') {
          this.data = { ...this.data, billingData: resp }
        }
        else if (value === 'validityPeriod') {
          this.data = { ...this.data, validityPeriod: resp }
        }
        this.data = {
          ...this.data,
          name: "",
          region: "BRA",
          contractPartiesSignatureOrder: this.signatureOrderEnabled ? this.cpSignatureOrder : null
        }
      })
    })
    eventBus.$on("canOpenDrawer", (openDialog: boolean) => {
      this.showPreview = openDialog
    })
    this.fetchOrganizationQuota()
    eventBus.$on("issueContract", (requestHandler: Function) => {

      // Get the contracts issued per month quota and if it's null set to the default of 10
      const contractsIssuedPerMonth = this.organizationQuota.customerContractsQuota.contractsIssuedPerMonth || 10

      // Check if the quota has been exceeded
      const quotaExcess = this.organizationQuota.contractsIssuedPerMonth > contractsIssuedPerMonth;
      requestHandler(quotaExcess)
    })
  }

  async handleFileUpload(file: File) {
    if (file.type != "application/pdf") {
      this.$message({
        type: "error",
        message: "Só é possível enviar arquivos PDF"
      })
      return false;
    }

    this.pdfFile = file
    this.fileUploaded = true
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        const data = event.target.result;
        if (data) {
          this.pdfBlob = new Blob([data], { type: 'application/pdf' });
          this.pdfBlobURL = URL.createObjectURL(this.pdfBlob);
        }
      }

    };
    reader.readAsArrayBuffer(file);

    // Return false to prevent the file from being uploaded
    return false;
  }

  public showContract() {
    if (this.contractOnTop == false) {
      document.getElementById('el-main')!.style.zIndex = '2';
      this.contractOnTop = true;
    }
    else {
      document.getElementById('el-main')!.style.zIndex = '0';
      this.contractOnTop = false;
    }
  }

  public resetPDF() {
    this.fileUploaded = false;
  }

  public enableTabs(emittedContractParties: ContractPartie[]) {
    this.contractParties = emittedContractParties
    this.tabIsDisabled = emittedContractParties.length === 0
    eventBus.$emit("issueFromFileButton", this.contractParties.length > 0);
  }

  async fetchOrganizationQuota() {
    // Try to fetch the organization quota
    try {
      const response = await organization.fetchOrganizationQuota()
      this.organizationQuota = response
      const contractsQuota = response.customerContractsQuota.contractsIssuedPerMonth;
      if (contractsQuota) {
        let percentage = ((response.contractsIssuedPerMonth / contractsQuota) * 100).toFixed(4)
        if (Number(percentage) > 100) percentage = "100"
        this.contractsQuotaPercentage = percentage;
      }
      this.contractsQuota = contractsQuota
      this.contractsUsage = response.contractsIssuedPerMonth
    } catch (e) {
      // Handle errors
      const err = new ErrorWrapper(e);
      this.$message.error(`Ocorreu um erro: ${err.message}`);
    }

  }

  public updateSignatureOrder(orderEnabled: boolean, order: string[]) {
    this.cpSignatureOrder = order;
    this.signatureOrderEnabled = orderEnabled;
  }
}
