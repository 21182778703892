
import eventBus from "@/utils/eventBus";
import { ContractPartie, RequiredInformation, RequiredInformationType, Template, WordTemplate } from "@/types/contract";
import { RequiredInformationTypes } from "@/types/utils";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Campos extends Vue {
  @Prop({ type: Array }) contractParties!: ContractPartie[];
  @Prop({ type: Object, required: false }) editing!: Template;
  @Prop({ type: Boolean, required: false }) hasRequiredQuota!: Boolean;
  @Prop({ type: Boolean, required: false }) canEdit!: Boolean;
  @Prop({ type: Object, required: false }) templateFromWord!: any;
  @Prop({ type: Boolean }) isWordTemplateParamPresent!: Boolean;

  created() {
    if (this.editing) {
      this.wildcards = this.editing.requiredContractPartiesInformation;
      this.wildcards.forEach(wc => {
        this.editing.wildcardMetadata.forEach(wcMeta => {
          if (wcMeta.name == wc.requiredInformation.name) wc.isWildcard = true
        })
      })
      this.wildcards.sort((a, b) => a.requiredInformation.name.localeCompare(b.requiredInformation.name))
    }
  }

  @Watch("templateFromWord")
  onTemplateFromWordChanged(newVal: any) {
    this.wildcards = [];
    newVal.requiredContractPartiesInformation.forEach((w: any) => {
      const normalizedWc: RequiredInformation = {
        contractPartyRole: w.contractPartyRole,
        requiredInformation: w.requiredInformation,
        contractParty: {
          role: w.contractParty.role
        },
        isWildcard: true
      }
      this.wildcards.push(normalizedWc);
      this.emit();
    })
    this.wildcards.sort((a, b) => a.requiredInformation.name.localeCompare(b.requiredInformation.name))
  }

  wildcards: RequiredInformation[] = [];

  //Form validator
  checkField = async (rule: any, value: any, callback: Function) => {
    if (!value) return callback(new Error("Campo obrigatório"));
    this.validateForm(value, callback);
  };

  public validateForm(value: string, callback: Function) {
    const filter = this.wildcards.filter((wc) => wc.requiredInformation.name.toLowerCase() === value.toLowerCase());
    if (filter.length >= 1 && !this.wcBeingEdited) return callback(new Error("Campo já adicionado"));
    if (value.trim().length === 0) return callback(new Error("Campo está invalido"));
    return callback();
  }

  //Handles of contract partie info
  wcBeingEdited: RequiredInformation | null = null;
  currentWc = {
    isWildcard: false,
    contractPartyRole: "",
    requiredInformation: {
      name: "",
      description: "",
      required: true,
      type: RequiredInformationType.SINGLE_LINE_TEXT /* "SINGLE_LINE_TEXT" */,
      options: [] as string[]
    },
    contractParty: {
      role: ""
    }
  };

  rules = {
    ["requiredInformation.name"]: [
      { required: true, validator: this.checkField, trigger: "blur" },
    ],
    'contractParty.role': [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],
    ["requiredInformation.type"]: [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],
  };

  requiredInformationTypes = RequiredInformationTypes;
  oldValue = "";
  expandBox = false;

  mounted() {
    eventBus.$on("clearWildcards", () => (this.wildcards = []));
    eventBus.$on("removedWildcard-field", (wcName: string) => {
      const wc = this.wildcards.find((w) => w.requiredInformation.name === wcName.trim());
      if (wc) this.removeWildcard(wc, false);
    });
    eventBus.$on("canCreateWildcard", (requestHandler: Function) => {
      if (!this.expandBox && !this.wcBeingEdited) requestHandler(true);
      else requestHandler(false);
    });
    eventBus.$on("newWildcard", (tabValue: number, wcName: string) => {
      if (tabValue != 1) return;
      this.createWildcard(wcName);
    });
  }

  /**
   * Open edit wildcard box
   * @param wc
   */
  public openEdit(wc: any) {
    this.expandBox = true;
    this.wcBeingEdited = wc;

    this.currentWc = {
      ...wc,
      isWildcard: wc.isWildcard,
    };

    this.oldValue = JSON.parse(JSON.stringify(wc.requiredInformation.name));
  }

  public createWildcard(wcName: string) {
    const newWildcard = {
      contractPartyRole: "",
      isWildcard: true,
      requiredInformation: {
        name: wcName,
        description: "",
        required: true,
        type: RequiredInformationType.SINGLE_LINE_TEXT,
        options: []
      },
      contractParty: {
        role: ""
      }
    };
    this.expandBox = true;
    this.currentWc = newWildcard;
  }

  /**
   * Remove a wildcard
   * @param wc
   */
  public removeWildcard(wc: RequiredInformation, flag: boolean) {
    if (flag) eventBus.$emit("removeWildcard", wc.requiredInformation.name);
    this.$delete(this.wildcards, this.wildcards.indexOf(wc));
    this.emit();
  }

  /**
   * Update a wildcard
   * @param wc
   */
  public updateWildcard(i: number) {
    //Validate form
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm[0]) {
      ruleForm[0].validate((valid: boolean) => {
        if (valid) {
          const type = this.currentWc.requiredInformation.type;
          if (type === 'MULTIPLE_SELECT' || type === 'SINGLE_SELECT') {
            if (!this.validateOptions(this.currentWc.requiredInformation.options)) {
              return;
            }
          }
          this.wildcards[i] = this.currentWc
          this.wildcards[i].contractPartyRole = this.currentWc.contractParty.role

          this.emit();
          this.resetValues();
        }
      });
    }
  }

  public addWildcard() {
    //Validate form
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm) {
      ruleForm.validate((valid: boolean) => {
        if (valid) {
          const type = this.currentWc.requiredInformation.type;
          if (type === 'MULTIPLE_SELECT' || type === 'SINGLE_SELECT') {
            if (!this.validateOptions(this.currentWc.requiredInformation.options)) {
              return;
            }
          }
          const wildcard = this.currentWc
          const normalizedWc: RequiredInformation = {
            contractPartyRole: wildcard.contractParty.role,
            isWildcard: wildcard.isWildcard,
            requiredInformation: wildcard.requiredInformation,
            contractParty: wildcard.contractParty
          }
          this.wildcards.push(normalizedWc);
          this.wildcards.sort((a, b) => a.requiredInformation.name.localeCompare(b.requiredInformation.name))
          this.emit();
          this.resetValues();
        }
      });
    }
  }

  private validateOptions(options: string[]): boolean {
    return options.length >= 1 && options.every(option => option.trim().length > 0);
  }

  public cancelAction() {
    eventBus.$emit("removeWildcard", this.currentWc.requiredInformation.name);
    this.resetValues();
  }

  public emit() {
    this.$emit("requiredInformations", this.wildcards);
  }

  /**
   * Reset state values
   */
  public resetValues() {
    this.expandBox = false;
    this.wcBeingEdited = null;
    this.currentWc = {
      isWildcard: false,
      contractPartyRole: "",
      requiredInformation: {
        name: "",
        description: "",
        required: true,
        type: RequiredInformationType.SINGLE_LINE_TEXT,
        options: []
      },
      contractParty: {
        role: ""
      }
    };
  }

  public canEditOrDeleteThisRequiredInformation(ri: RequiredInformation) {
    if (this.editing) {
      if (this.editing.wildcardMetadata) {
        const aux = this.editing.wildcardMetadata.find(wc => wc.name === ri.requiredInformation.name);
        const isAWildcardMetadata = (Boolean(aux));
        if (this.isWordTemplateParamPresent && isAWildcardMetadata) {
          return false;
        }
      }
    }
    if (this.templateFromWord && ri.isWildcard === true) {
      return false;
    }
    else {
      return true;
    }
  }

  public addOption() {
    this.currentWc.requiredInformation.options.push('');
  }

  public removeOption(index: number) {
    this.currentWc.requiredInformation.options.splice(index, 1);
  }

  public handleOptionInput(index: number) {
    const newValue = this.currentWc.requiredInformation.options[index].replace(/,/g, '');
    this.currentWc.requiredInformation.options[index] = newValue;
  }

  @Watch("expandBox")
  handler() {
    eventBus.$emit("canSaveTemplate", !this.expandBox);
  }
}
