
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import "moment/locale/pt-br";
import {
  StartDatePolicyType,
  EndDatePolicyType,
  ValidityPeriod,
  EndDatePolicy,
  DateIntervals,
  Template,
} from "@/types/contract";
import eventBus from "@/utils/eventBus";

@Component
export default class Vigencia extends Vue {
  @Prop({ type: Object, required: false }) editing!: Template;
  
  startDatePolicyType = StartDatePolicyType;
  endDatePolicyType = EndDatePolicyType;

  startDate = StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES;
  finalDate = EndDatePolicyType.UNDETERMINED;
  dateValue = 1;
  dateInterval = "";
  endDate = "";

  dateOnEditor = false;

  created() {
    if (this.editing) {
      this.startDate = this.editing.validityPeriod.startDatePolicyType
      this.finalDate = this.editing.validityPeriod.endDatePolicyType

      if (this.editing.validityPeriod.endDatePolicy) {
        this.dateInterval = this.editing.validityPeriod.endDatePolicy.dateInterval
        this.dateValue = this.editing.validityPeriod.endDatePolicy.value
        this.handleDateChange()
      }
    }

    this.emit();
    eventBus.$on("dateAddedToEditor", () => (this.dateOnEditor = true));
    eventBus.$on("removedWildcard-date", () => (this.dateOnEditor = true));
  }

  /**
   * Handle end date simulator
   */
  public handleDateChange() {
    const today = moment(moment(), "DD-MM-YYYY");
    const interval = (this.dateInterval as unknown) as undefined;
    this.endDate = today.add(this.dateValue, interval).calendar();
    this.emit();
  }

  /**
   * Remove date wildcard from editor
   */
  public removeDate() {
    eventBus.$emit("removeDateWildcard");
    this.dateOnEditor = false;
  }

  public emit() {
    //Create the object
    let validityPeriod: ValidityPeriod = {
      startDatePolicyType: this.startDate,
      endDatePolicyType: this.finalDate,
    };

    if (this.finalDate === EndDatePolicyType.SET_AUTOMATICALLY_AFTER_START_DATE) {
      //Create the object
      const endDatePolicy: EndDatePolicy = {
        value: this.dateValue,
        dateInterval: this.dateInterval.toString() as DateIntervals,
      };

      //Avoid empty value
      if (endDatePolicy.dateInterval.length === 0)
        endDatePolicy.dateInterval = DateIntervals.YEAR;

      validityPeriod = {
        ...validityPeriod,
        endDatePolicy: endDatePolicy,
      };
    }

    //Emit the object
    this.$emit("validityPeriod", validityPeriod);
  }
}
