
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ContractPartie, RequiredAttachment, Template } from "@/types/contract";
import eventBus from "@/utils/eventBus";

@Component
export default class Anexos extends Vue {
  @Prop({ type: Array }) contractParties!: ContractPartie[];
  @Prop({ type: Object, required: false }) editing!: Template;
  @Prop({ type: Boolean, required: false }) hasRequiredQuota!: Boolean;
  @Prop({ type: Boolean, required: false }) canEdit!: Boolean;

  created() {
    if (this.editing) {
      this.attachments = this.editing.requiredAttachments;
      /* this.attachments.forEach((att: any) => {
        att.contractPartyRole = att.contractParty.role;
      }) */
      this.attachments.sort((a,b) => a.name.localeCompare(b.name))
    }
  }

  //Form validator
  checkField = async (rule: any, value: any, callback: Function) => {
    if (!value) return callback(new Error("Campo obrigatório"));
    this.validateForm(value, callback);
  };

  public validateForm(value: string, callback: Function) {
    const filter = this.attachments.filter((att) => att.name.toLowerCase() === value.toLowerCase());
    if (filter.length >= 1 && !this.attBeingEdited) return callback(new Error("Campo já adicionado"));
    if (value.trim().length === 0) return callback(new Error("Campo está invalido"));
    return callback();
  }

  expandBox = false;
  attachments: RequiredAttachment[] = [];
  attBeingEdited: RequiredAttachment | null = null;
  currentAttachment = {
    required: true,
    contractPartyRole: "",
    name: "",
    contractParty: {
      role: ""
    },
    requiresApproval: false,
  };

  rules = {
    name: [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
      { required: true, validator: this.checkField, trigger: "blur" },
    ],
    "contractParty.role": [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],
  };

  public selectOnChange(att: any) {
  }

  public addAttachment() {
    //Validate form
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm) {
      ruleForm.validate((valid: boolean) => {
        if (!valid) return;
        const att = this.currentAttachment
        const newAtt = {
          ...att,
          contractPartyRole: att.contractParty.role
        }

        this.attachments.push(newAtt);
        this.attachments.sort((a,b) => a.name.localeCompare(b.name))
        this.emit();
        this.resetValues();
      });
    }
  }

  /**
   * Update an attachment
   * @param att
   */
  public updateAttachment(att: RequiredAttachment) {
    //Validate form
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm[0]) {
      ruleForm[0].validate((valid: boolean) => {
        if (valid) {
          att = this.currentAttachment;
          att.contractPartyRole = this.currentAttachment.contractParty.role;
          this.emit();
          this.resetValues();
        }
      });
    }
  }

  /**
   * Open edit wildcard box
   * @param att
   */
  public openEdit(att: any) {
    this.expandBox = true;
    this.attBeingEdited = att;
    this.currentAttachment = att;
  }

  /**
   * Remove a wildcard
   * @param att
   */
  public removeAttachment(att: RequiredAttachment) {
    this.$delete(this.attachments, this.attachments.indexOf(att));
    this.emit();
  }

  public emit() {
    this.$emit("requiredAttachments", this.attachments);
  }

  public resetValues() {
    this.expandBox = false;
    this.attBeingEdited = null;
    this.currentAttachment = {
      required: true,
      contractPartyRole: "",
      name: "",
      contractParty: {
        role: ""
      },
      requiresApproval: false,
    };
  }

  public changeRequiresApproval(){
    if(this.currentAttachment.required == false) {
      this.currentAttachment.requiresApproval = false;
    }
  }

  @Watch("expandBox") 
  handler() {
    eventBus.$emit("canSaveTemplate", !this.expandBox);
  }
}
