import api from "@/services/contracts-api"
import { Pagination } from "element-ui";

const tags = {
    fetchTagsUsingPagination: async (page: number, limit: number = 20): Promise<any> => {
        let url = `/rest/v1/digital-contracts/tags?page=${page}&limit=${limit}`;
        return await (await api.get(url)).data;
    },

    fetchTotalTags: async (limit: number = 20): Promise<Pagination> => {
        let url = `/rest/v1/digital-contracts/tags/pagination?limit=${limit}`;
        return await (await api.get(url)).data;
    },

    searchTagsByName: async (searchTerm: string): Promise<any> => {
        let url = `/rest/v1/digital-contracts/search/tags?searchTerm=${searchTerm}`;
        return await (await api.get(url)).data;
    },

    updateContractTags: async (contractId: string, tags: any): Promise<any> => {
        let url = `/rest/v1/digital-contracts/${contractId}/tags`;
        return await api.put(url, tags);
    }
}
export default tags;