
import { ContractPartie, ContractPartyIdentification, IdentificationType, Template, WordTemplate } from "@/types/contract";
import { IdentificationTypes, ContractPartiesSuggestions, ContractPartiesSuggestionsType } from "@/types/utils";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import SearchBar from "../SearchBar.vue";
import eventBus from "@/utils/eventBus";

@Component({ components: { SearchBar } })
export default class Parties extends Vue {
  @Prop({ type: Object, required: false }) editing!: Template;
  @Prop({ type: Object, required: false }) templateFromWord!: any;
  contractPartiesSuggestions: any;

  created() {
    if (this.editing) this.contractParties = this.editing.contractParties;
    this.contractParties.sort((a, b) => a.role.localeCompare(b.role))

    eventBus.$on("getContractParties", (handler: any) => {
      handler(this.contractParties)
    })
    eventBus.$on("setContractParties", (contractParties: ContractPartie[]) => {
      this.contractParties = contractParties
      this.emit()
    })
  }

  // Fetch the Contract Parties Suggestions and if filter by the user input
  public fetchSuggestions(queryString: string, callback: any) {
    const filteredSuggestions = ContractPartiesSuggestions.filter(
      suggestion =>
        suggestion.label.toLowerCase().includes(queryString.toLowerCase()) ||
        suggestion.value.toLowerCase().includes(queryString.toLowerCase())
    );
    callback(filteredSuggestions);
  }

  @Watch("templateFromWord")
  onTemplateFromWordChanged(newVal: WordTemplate) {
    this.contractParties = newVal.contractParties;
    this.emit()
  }

  checkIdentificationField = async (rule: any, value: any, callback: Function) => {
    if (!this.currentCP.identification) return callback(new Error("Campo obrigatório"))
    const hasValue = (value.uuid || value.teamId) ? true : false
    if (!hasValue) return callback(new Error("Campo obrigatório"));
    if (value.uuid) {
      const filter = this.addedOrganizationMembers.has(value.uuid)
      if (filter) return callback(new Error("Membro já utilizado em outro participante"))
    }
  }

  //Editing booleans
  edit = false;
  expandBox = false;
  //Editing mode flag
  editingMode = false;
  editingContractParties: ContractPartie[] = [];

  //Types
  identificationType = IdentificationType;
  identificationTypes = IdentificationTypes;

  currentCP: ContractPartyIdentification = {
    role: "",
    identificationType: "",
    identification: {} || undefined,
    email: "",
    comment: ""
  };

  addedOrganizationMembers = new Set([""])

  //Form validator
  checkField = async (rule: any, value: string, callback: Function) => {
    if (!value) return callback(new Error("Campo obrigatório"));
    this.validateForm(value, callback);
  };

  rules = {
    role: [{ required: true, validator: this.checkField, trigger: "change" }],
    identificationType: [
      { required: true, message: "Campo obrigatório", trigger: "blur" },
    ],
    identification: [{ required: true, validator: this.checkIdentificationField, trigger: "change" }],
  };
  //Contract parties
  contractParties: ContractPartie[] = [];

  public validateForm(value: string, callback: Function) {
    const filter = this.contractParties.filter((cp) => cp.role.toLowerCase() === value.toLowerCase())
    if (filter.length >= 1 && !this.cpBeingEdited) return callback(new Error("Campo já adicionado"));
    if (value.trim().length === 0) return callback(new Error("Campo está invalido"));
    return callback();
  }

  //Handles of contract partie info
  cpBeingEdited: ContractPartie | null = null;

  // When select an option, verify if already has an value in suggestion list and add an sufix count
  verifyIfAlreadyInSuggestionList(val: string) {
    const filteredSuggestions = ContractPartiesSuggestions.filter(
      suggestion => {
        suggestion.label.toLowerCase() === val.toLowerCase() || suggestion.value.toLowerCase() === val.toLowerCase();
      }
    );
    // Verify if the value is in suggestion list
    if (filteredSuggestions) {
      const count = this.contractParties.filter(suggestion => suggestion.role.substring(0, val.length).includes(val)).length;
      // Update the value with the count
      const newVal = count >= 1 ? `${val} ${count + 1}` : val;
      return newVal;
    }
    else {
      return val;
    }
  }

  /**
   * Handle change input value
   * @param val
   */
  changeInput(val: string) {
    this.currentCP.role = val
  };

  handleSelectSuggestion(selectedOption: any) {
    let newVal = this.verifyIfAlreadyInSuggestionList(selectedOption.value);
    this.changeInput(newVal);
  }

  /**
   * Handle change select of identification type
   * @param idType
   */
  public handleIdentificationTypeChange(idType: IdentificationType) {
    this.currentCP.identificationType = JSON.parse(JSON.stringify(idType));
    if (idType === IdentificationType.IDENTIFIED_BY_EMAIL) {
      delete this.currentCP.identification;
    }
  }

  currentIdType: string | null = null;

  /**
   * Handle change select of organization member
   * @param member (user account or organization team)
   */
  public selectedOrganizationMember(member: any) {
    this.currentIdType = member.type === "ORGANIZATION_TEAM" ? "teamId" : "uuid";
    let fieldValue =
      member.type === "ORGANIZATION_TEAM" ? member.object.guid : member.object.uuid;

    this.currentCP.identification = { [this.currentIdType]: fieldValue };
  }

  /**
   * Add a contract partie
   */
  public addPartie() {
    //Validate form
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm) {
      ruleForm?.validate((valid: any) => {
        if (valid) {
          let idType = !this.currentIdType
            ? IdentificationType.IDENTIFIED_BY_EMAIL
            : this.currentIdType === "teamId"
              ? IdentificationType.ORGANIZATION_TEAM
              : IdentificationType.ORGANIZATION_USER;

          if (idType === IdentificationType.ORGANIZATION_USER && this.currentCP.identification?.uuid) {
            this.addedOrganizationMembers.add(this.currentCP.identification.uuid)
          }

          //Create a new object to be added to the list of cp
          let newCP = {
            role: this.currentCP.role,
            identificationType: idType,
            identification: this.currentCP.identification,
            comment: this.currentCP.comment,
          };

          //Remove identication field if necessary
          if (
            this.currentCP.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
          )
            delete newCP.identification;

          //Force type to stop observer
          let _newCP = JSON.parse(JSON.stringify(newCP));

          //Add and emit values
          this.contractParties.push(_newCP);
          this.contractParties.sort((a, b) => a.role.localeCompare(b.role))

          this.emit();
          this.resetValues();
        }
      });
    }
  }

  /**
   * Remove a contract partie
   * @param cp
   */
  public removePartie(cp: ContractPartie) {
    this.$delete(this.contractParties, this.contractParties.indexOf(cp));
    if (cp.identification.uuid) {
      this.addedOrganizationMembers.delete(cp.identification.uuid)
    }
    this.emit();
  }

  /**
   * Update a contract partie
   * @param cp
   */
  public updatePartie(cp: ContractPartie) {
    //Cp is the object being updated, its values have to match currentCP values
    //Validate form
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm) {
      ruleForm[0].validate((valid: any) => {
        //Ignore if both values are the same because the obj is being updated
        if (cp.role == this.currentCP.role) valid = true;
        if (valid) {

          if (cp.identification?.uuid) {
            this.addedOrganizationMembers.add(cp.identification.uuid)
          }

          //Update role
          cp.role = this.currentCP.role;

          cp.comment = this.currentCP.comment;

          //Update identification type
          cp.identificationType = this.currentCP.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL
            ?
            IdentificationType.IDENTIFIED_BY_EMAIL
            :
            this.currentIdType === "teamId"
              ?
              IdentificationType.ORGANIZATION_TEAM
              :
              IdentificationType.ORGANIZATION_USER;

          //Update identification (if necessary)
          if (this.currentCP.identification)
            cp.identification = this.currentCP.identification;

          //Reset values
          this.resetValues();
          this.emit()
        }
      });
    }
  }

  /**
   * Open edit mode of added partie
   * @param partie
   */
  public openEdit(partie: ContractPartie) {
    //Set values to the form being updated
    this.edit = true;
    this.cpBeingEdited = partie;

    this.currentCP.role = partie.role;
    this.currentCP.identificationType = partie.identificationType;
    this.currentCP.comment = partie.comment;
    if (partie.identification)
      Object.assign(this.currentCP, { identification: partie.identification });

    if (partie.identification.uuid) {
      this.addedOrganizationMembers.delete(partie.identification.uuid)
    }
  }

  /**
   * Reset state values
   */
  public resetValues() {
    this.edit = false;
    this.expandBox = false;
    this.currentIdType = null;
    this.currentCP = {
      role: "",
      identificationType: "",
      identification: {},
      email: "",
      comment: ""
    };
  }

  /**
   * Emit contract parties value to parent
   */
  @Emit("addContractParties")
  emit() {
    const normalizedContractParties: any[] = []
    this.contractParties.forEach(cp => {
      const normalizedCp = {
        identification: cp.identification,
        identificationType: cp.identificationType,
        role: cp.role,
        contractPartyRole: cp.role,
        comment: cp.comment
      }
      normalizedContractParties.push(normalizedCp)
    })

    return normalizedContractParties;
  }
}
