
import {
BillingPeriodType,
ContractPartie,
Template
} from "@/types/contract";
import { BillingPeriodTypes } from "@/types/utils";
import eventBus from "@/utils/eventBus";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

interface BillingSpecificationIssuedFromFile {
  billingDueDay: number,
  billingPeriodType: string,
  contractParty: string,
  description: string,
  initialBillingPeriod: number,
  name: string,
  numberOfInstallments: number,
  value: {
    currency: string,
    value: number
  }
  required?: boolean
}

@Component
export default class Billings extends Vue {
  @Prop({ type: Array }) contractParties!: ContractPartie[];
  @Prop({ type: Object, required: false }) editing!: Template;

  billings: BillingSpecificationIssuedFromFile[] = [];

  billingPeriodTypes = BillingPeriodTypes;
  billingPeriodType = BillingPeriodType;

  expandBox = false;
  billingBeingEdited = null;
  oldValue = "";
  currentBilling = {
    name: "",
    contractParty: "",
    description: "",
    value: {
      value: 0,
      currency: "BRL"
    },
    billingPeriodType: "",
    initialBillingPeriod: "",
    billingDueDay: 1,
    numberOfInstallments: 1
  };

  checkField = async (rule: any, value: string, callback: Function) => {
    if (!value) return callback(new Error("Campo obrigatório"));
    const filter =
      rule.field === "role"
        ? this.billings.filter((billing: any) => billing.role === this.currentBilling.contractParty)
        : this.billings.filter(
          (billing: any) => billing.name === value
        );

    if (filter.length > 0) return callback(new Error("Cobrança já adicionada"));
    return callback();
  };

  rules = {
    name: [{ required: true, validator: this.checkField, trigger: `blur` }],
    billingPeriodType: [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],
    contractParty: [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],
    initialBillingPeriod: [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],
    numberOfInstallments: [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],
    value: [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],

  };

  pickerOptions = {
    disabledDate(time: any) {
      return false;
    },
  };

  /**
   * Handle change input value
   * @param val
   */
  changeInput = (val: string) => (this.currentBilling.name = val);

  /**
   * Handle change select of identification type
   * @param type
   */
  public handleBillingPeriodTypeChange(type: BillingPeriodType) {
    if (type != BillingPeriodType.INSTALLMENTS) {
      //delete this.currentBilling.billingPeriod;
    }
  }

  public handleInputValue(currentValue: number, minValue: number, maxValue: number) {
    if (currentValue < minValue) {
      return minValue
    } else if (currentValue > maxValue) {
      return maxValue
    } else {
      return currentValue
    }
  }

  public addBilling() {
    //Validate form
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm) {
      ruleForm.validate((valid: boolean) => {
        if (!valid) return;

        const newBilling = {
          name: this.currentBilling.name,
          contractParty: this.currentBilling.contractParty,
          description: this.currentBilling.description,
          value: this.currentBilling.value,
          billingPeriodType: this.currentBilling.billingPeriodType,
          initialBillingPeriod: new Date(this.currentBilling.initialBillingPeriod).getTime(),
          billingDueDay: new Date(this.currentBilling.initialBillingPeriod).getUTCDate(),
          numberOfInstallments: this.currentBilling.numberOfInstallments
        }
        this.billings.push(newBilling);

        this.emit();
        this.resetValues();
      });
    }
  }

  public openEdit(billing: any) {
    this.expandBox = true;
    this.billingBeingEdited = billing;
    this.currentBilling = billing;
    this.oldValue = JSON.parse(JSON.stringify(billing.name));
  }

  private emit() {
    this.$emit("billings", this.billings);
  }

  public cancelAction() {
    eventBus.$emit("removeWildcard", this.currentBilling.name);
    this.resetValues();
  }

  public updateBilling(billing: any) {
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm[0]) {
      ruleForm[0].validate((valid: any) => {
        billing = this.currentBilling;
        // eventBus.$emit("updateWildcard", this.oldValue, this.currentBilling.name);
        this.emit();
        this.resetValues();
      });
    }
  }

  /**
   * Remove a billing specification
   * @param billing
   */
  public removeBilling(billing: any, flag: boolean) {
    if (flag) eventBus.$emit("removeWildcard", billing.name);
    this.$delete(this.billings, this.billings.indexOf(billing));
    this.emit();
  }

  public resetValues() {
    this.expandBox = false;
    this.billingBeingEdited = null;
    this.currentBilling = {
      name: "",
      contractParty: "",
      description: "",
      value: {
        value: 0,
        currency: "BRL"
      },
      billingPeriodType: "",
      initialBillingPeriod: "",
      billingDueDay: 1,
      numberOfInstallments: 1
    };
  }

  parsedB: any = []
  created() {

    eventBus.$on("getIssueData", (requestHandler: Function) => {
      this.parsedB = []
      this.billings.forEach((b: any) => {

        const newBilling = {
          name: b.name,
          contractParty: b.contractParty,
          description: b.description,
          value: b.value,
          billingPeriodType: b.billingPeriodType,
          initialBillingPeriod: new Date(b.initialBillingPeriod),
          billingDueDay: new Date(b.initialBillingPeriod).getUTCDate(),
          numberOfInstallments: b.numberOfInstallments
        }

        this.parsedB.push(newBilling)
      })
      requestHandler("billingData", this.parsedB)
    })
  }

  @Watch("expandBox")
  handler() {
    eventBus.$emit("canSaveTemplate", !this.expandBox);
  }
}

//Custom directive to change "," to "." on keydown event
Vue.directive('keydown', {
  bind(el: HTMLElement, binding: any) {
    el.addEventListener('keydown', event => {
      if (event.key === ',') {
        event.preventDefault();
        const input = event.target as HTMLInputElement;
        const value = input.value;
        const selectionStart = input.selectionStart ?? 0;
        const selectionEnd = input.selectionEnd ?? 0;
        const newValue = value.slice(0, selectionStart) + '.' + value.slice(selectionEnd);
        input.value = newValue;
        input.selectionStart = input.selectionEnd = selectionStart + 1;
        input.dispatchEvent(new Event('input'));
      }
    });
  }
});
