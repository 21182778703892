import { AuthenticatedUser } from "@/types/user";
import authService from "@/services/authentication"

const storage = localStorage;
const STORE_USER = 'dev_user';
const STORE_CONTRACTS_TOKEN = 'dev_contracts_token';
const STORE_ACCOUNTS_TOKEN = 'dev_accounts_token';

const authUtils = {
    isAuthenticated(): boolean {
        return this.user() != null;
    },

    logout(callback: VoidFunction) {
        storage.clear();
        if (!this.isAuthenticated() && callback) callback();
    },

    async authenticate(authenticationToken: string): Promise<AuthenticatedUser> {
        const createSession = await authService.login(authenticationToken);

        //If the application runs on development store the authentication token on storage
        this.setAccountsToken(authenticationToken);
        this.setContractsToken(createSession.token);

        //Return the updated user from server
        return await this.updatedUser();
    },

    user(): AuthenticatedUser {
        const storageData = storage.getItem(STORE_USER);
        return (storageData) ? JSON.parse(storageData) : null;
    },

    updatedUser: async (): Promise<AuthenticatedUser> => {
        //Return user data from server
        const authenticatedUser = await authService.me();
      
        //Store authenticated user info
        storage.setItem(STORE_USER, JSON.stringify(authenticatedUser));

        return authenticatedUser;
    },

    getAccountsToken() {
        const token = storage.getItem(STORE_ACCOUNTS_TOKEN);
        return token ? token : null;
    },

    getContractsToken() {
        const token = storage.getItem(STORE_CONTRACTS_TOKEN);
        return token ? token : null;
    },

    setAccountsToken(token: string) {
        storage.setItem(STORE_ACCOUNTS_TOKEN, token);
    },

    setContractsToken(token: string) {
        storage.setItem(STORE_CONTRACTS_TOKEN, token);
    }
}

export default authUtils;