
import EmailTemplateEditor from '@/components/EmailTemplateEditor.vue';
import organization from '@/services/organization';
import { EmailTypes, MailTemplate } from '@/types/organization';
import ErrorWrapper from '@/utils/ErrorWrapper';
import eventBus from '@/utils/eventBus';
import { Component, Vue } from "vue-property-decorator";


@Component({ components: { EmailTemplateEditor } })
export default class GlobalMailTemplates extends Vue {

    selectedMailTemplate: MailTemplate = {
        mailTemplate: "",
        mailType: EmailTypes.ISSUE_CONTRACT
    }

    emailEditor = false;

    editEmailTemplates = false

    emailType = "" as EmailTypes;

    emailTypes = EmailTypes;

    emailTemplates: MailTemplate[] | null = null

    hasPersonalizedIssueMail = false
    hasPersonalizedStateMail = false
    hasPersonalizedSignMail = false

    loading = true

    created() {
        eventBus.$on("editMailTemplate", (mailTemplate: MailTemplate) => {
            if (this.emailTemplates) {
                const mailTemplates = this.emailTemplates.filter((t: MailTemplate) => t.mailType !== mailTemplate.mailType) as MailTemplate[]
                mailTemplates.push(mailTemplate)
                this.emailTemplates = mailTemplates
            } else {
                this.emailTemplates = [mailTemplate]
            }
        })
        this.fetchMailTemplate()
    }

    public handleOpenEmailEditor(type: EmailTypes) {
        this.emailEditor = true
        this.emailType = type
        if (this.emailTemplates) {
            const mt = this.emailTemplates.filter(t => t.mailType === type)
            if (mt) this.selectedMailTemplate = mt[0]
        }
    }

    async fetchMailTemplate() {
        try {
            this.loading = true
            const response = await organization.fetchOrganizationEmail()

            if(response){
                response.forEach(mt => {
                    switch (mt.mailType) {
                        case EmailTypes.ISSUE_CONTRACT:
                            this.hasPersonalizedIssueMail = true
                            break;
                        case EmailTypes.CHANGE_STATES:
                            this.hasPersonalizedStateMail = true
                            break;
                        case EmailTypes.FULLY_SIGNED:
                            this.hasPersonalizedSignMail = true
                            break;
                    }
                })
            this.emailTemplates = response   
        }

        } catch (e) {
            const err = new ErrorWrapper(e)

            switch (err.httpStatus) {
                case 402:
                case 403:
                case 404:
                    break;
                default:
                    console.error(err)
                    this.$message.error(err.message);
                    break;
            }
        } finally {
            this.loading = false
        }
    }

}

