<template>
    <div style="max-height: 500px; overflow: auto;">
        <h3><b>Campos Identificados</b></h3>
        <p class="p-style">É necessário informar o tipo do campo e o participante. Após ir para a próxima tela, é necessário <b>Editar os campos requeridos</b> e os <b>Campos Financeiros</b>.</p>

        <el-row class="el-row">
            <el-col :span="12"><b>Nome do Campo</b></el-col>
            <el-col :span="12"><b>Tipo</b></el-col>
        </el-row>

        <el-row class="el-row" v-for="(item, index) in itemData" :key="item.name">
                <div :class="getRowDivClass(item.label)">
                    <el-col :span="12">{{ item.name }}</el-col>
                    <el-col :span="12">
                        <div class="el-radio-main">
                                <el-radio-group v-model="item.label" class="el-radio" @change="handleLabelChange(item)">
                                    <el-radio label="finance">Financeiro</el-radio>
                                    <el-radio label="field">Campo</el-radio>
                                </el-radio-group>
                        </div>
                    </el-col>
                    
                </div>
        </el-row>
    </div>
</template>
  
<script>
import ContractPartie from "@/types/contract";
import eventBus from "@/utils/eventBus";

export default {
    props: {
        wildcard: {
            type: Array,
            required: true
        },
        normalizedContractParties: {
            type: ContractPartie,
            required: true
        },
    },
    data() {
        return {
            itemData: [],
        };
    },

    created() {
        eventBus.$on("clear", () => {
            this.wildcard = []
            this.itemData = []
        })
    },

    watch: {
        wildcard: function () {
            this.wildcard.forEach((wc, index) => {
                let label = undefined

                if (wc.wildcardType === "REQUIRED_INFORMATION") {
                    label = "field"
                } else if (wc.wildcardType === "BILLING") {
                    label = "finance"
                }
                const normWc = {
                    name: wc.name,
                    label: label,
                    index: index,
                }
                this.itemData.push(normWc)
                this.$emit("itemData", this.itemData)
            })
        },
    },
    methods: {
        handleLabelChange(item, index) {
            // when change the label emit the itemData 
            this.$emit("itemData", this.itemData)
        },

        handlePartieChange(item, index) {
            // when change the partie emit the itemData 
            this.wildcard = this.wildcard;
            this.$emit("itemData", this.itemData)
        },

        getRowDivClass(label) {
            //Change the background color of the div
            // depending on the radio button value
            if (label === 'finance') {
                return 'el-row-div el-row-div-green';
            } else if (label === 'field') {
                return 'el-row-div el-row-div-yellow';
            } else {
                return 'el-row-div';
            }
        },
    }
}
</script>

<style>
.p-style {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.el-row {
    display: flex;
    text-align: center;
    font-size: 0.875rem;
}

.el-radio-group.el-radio {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
}

.el-radio-main {
    display: flex;
    justify-content: center;
}

.el-row-div {
    width: 100%;
    display: flex;
    align-items: center;
}

.el-row-div-green {
    background-color: #C6FFD6;
}

.el-row-div-yellow {
    background-color: #EBE698;
}

.el-select {
    padding: 0px 5px;
}
</style>