
import { Component, Watch, Vue } from "vue-property-decorator";
import { AccountTypes } from "@/types/user";
import authUtils from "@/utils/authentication";
import authService from "@/services/authentication";
import ErrorWrapper from "@/utils/ErrorWrapper";
import router from "@/router";

//Enum of actions
enum Actions {
  login = "login",
  create = "create-account",
}

@Component
export default class Login extends Vue {
  //------------------------------------------------------------------------------------------------------------------------- variables
  loading = false;
  actions = Actions;
  canCreateAccount = true;
  accountsToken: string | null | (string | null)[] = null;
  contractsToken: string | null | (string | null)[] = null;
  requiredEmail: string | null | (string | null)[] = null;

  //------------------------------------------------------------------------------------------------------------------------- created
  created() {
    //Go to home if user is already authenticated
    if (authUtils.isAuthenticated()) router.replace("/");
  }

  //------------------------------------------------------------------------------------------------------------------------- watch

  @Watch("$route", { immediate: true })
  handler() {
    //Get query params
    this.accountsToken = this.$route.query["token"] || null;
    this.contractsToken = this.$route.query["contracts-token"] || null;
    this.requiredEmail = this.$route.query["required-email"] || null;

    //Try to autenticate
    if (this.accountsToken || this.contractsToken) this.authenticate();
  }

  //------------------------------------------------------------------------------------------------------------------------- methods
  /**
   * Redirect user to Auth Webapp to authenticate
   * @param action
   */
  public redirect(action: Actions) {
    //Required email query param, if necessary
    const query = this.requiredEmail ? `&required-email=${this.requiredEmail}` : ``;
    window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP}/${action}?service=contracts-registry${query}`;
  }

  /**
   * Authenticate user with token from Auth Webapp
   */
  public async authenticate(): Promise<void> {
    this.loading = true;
    try {
      if (this.contractsToken) {
        //If contracts token is passed by query param store it
        authUtils.setContractsToken(this.contractsToken.toString());
        await authUtils.updatedUser();
      } else if (this.accountsToken) {
        //If accounts token is passed by query param authenticate the user
        await authUtils.authenticate(this.accountsToken.toString());
      }

      //Navigate to home
      router.replace("/new-template/word-template");
      this.$message({ message: "Bem vindo.", type: "success" });
    } catch (error) {
      const err = new ErrorWrapper(error);

      //If 403 error, try to create account
      if (err.httpStatus === 403) return this.createAccount();

      this.$message.error(
        err.httpStatus === 404
          ? `Conta não encontrada`
          : `Ocorreu um erro: ${err.message}`
      );
    } finally {
      this.loading = false;
    }
  }

  /**
   * Create a new customer account
   */
  public async createAccount(): Promise<void> {
    //Return if there is no accounts token
    if (!this.accountsToken) return;

    this.loading = true;
    try {
      //Get account type query param
      const accountType = this.$route.query["account-type"] || null;

      //Return if it is Customer Subuser because this type of account can not create an account, it has to be imported into the platform from its organization
      if (accountType === AccountTypes.CustomerSubuser) {
        //Update can create account flag
        this.canCreateAccount = false;
        return;
      }

      //Create users account
      await authService.create(this.accountsToken.toString());

      //If accounts token is passed by query param authenticate the user
      await authUtils.authenticate(this.accountsToken.toString());

      //Navigate to home
      router.replace("/");
      this.$message({ message: "Bem vindo.", type: "success" });
    } catch (e) {
      this.$message.error(`Ocorreu um erro: ${new ErrorWrapper(e).message}`);
    } finally {
      this.loading = false;
    }
  }
}
