
import contracts from "@/services/contracts";
import Parties from "../tabs/Parties.vue";
import Fields from "./Fields.vue";
import eventBus from "@/utils/eventBus";
import ErrorWrapper from "@/utils/ErrorWrapper";
import { RequiredInformationType, BillingPeriodType, ComputedWildcard, Uri } from "@/types/contract";
import { RequiredInformationTypes, BillingPeriodTypes, DialogSteps } from "@/types/utils";
import { AnyNsRecord } from "dns";

export default {
    props: {
        isWordTemplateDrawerOpen: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            handleWordTemplateDialogOpen: false,
            windowWidth: window.innerWidth,
            loading: false,
            wordTemplateParam: false,
            wordTemplateFile: false,
            activeTab: "parties",
            wildcards: [] as ComputedWildcard[],
            uri: {} as Uri,
            fieldsFilledObject: null,
            hasContractParties: false,
            normalizedContractParties: [] as any,
            allFieldsAreFilled: false,
            templateFromWord: {} as any,
            itemData: [] as any,
            requiredInformations: [] as any,
            billingSpecifications: [] as any,
            isDrawerOpen: this.isWordTemplateDrawerOpen,
            wordFile: null as File | null,
            wildcardsErrors: [] as string[],
            dialogStep: DialogSteps.IMPORT_FILE,
            hasRI: false,
            hasBill: false,
            allRIsFilled: false,
            allBillsFilled: false,

            requiredInformationTypes: RequiredInformationTypes,
            billingPeriodTypes: BillingPeriodTypes,
            billingPeriodType: BillingPeriodType,
            dialogSteps: DialogSteps,

            rules: {
                requiredInformation: {
                type: [
                    { required: true, message: 'Campo obrigatório', trigger: 'blur' }
                ],
                options: [] as string[]
                },
                'contractParty.role': [
                { required: true, message: 'Campo obrigatório', trigger: 'blur' }
                ]
            },
        };
    },

    created() {
        eventBus.$on("openEditingWordTemplateDrawer", () => {
            this.handleWordTemplateDialogOpen = false
            this.loading = false
            this.wordTemplateFile = false
            this.activeTab = "parties"
            this.wildcards = []
            this.uri = {} as Uri
            this.fieldsFilledObject = null
            this.hasContractParties = false
            this.allFieldsAreFilled = false
            this.templateFromWord = {}
            this.itemData = []
            this.requiredInformations = []
            this.billingSpecifications = []
            this.isDrawerOpen = true
            this.wordFile = null

            eventBus.$emit("getContractParties", (response: any) => {
                this.normalizedContractParties = response
                this.onAddContractParties(response)
            })
        });
    },
    watch: {
        normalizedContractParties: function () {
            if (this.wildcards.length === 0) {
                if (this.hasContractParties === true) {
                    this.allFieldsAreFilled = true;
                }
            }
        }
    },
    mounted() {
        eventBus.$on("getWordTemplateContent", (handler: any) => {
            handler(this.wordFile)
        })
    },
    methods: {
        wordTemplateUploadFile(file: any) {
            this.setToDefault()
            try {
                // Verify if the file is a docx type
                if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                    const type = "WORD";
                    this.dialogStep = DialogSteps.INSERT_PARTICIPANTS
                    this.loading = true
                    contracts.getWildcardsFromWordFile(type, file).then(computed => {
                        this.wildcardsErrors = computed.data.wildcards.wildcardErrors
                        if (computed.data.wildcards.wildcardErrors.length <= 0) {
                            this.wildcards = computed.data.wildcards.wildcards;
                            this.wildcards.sort((a: any, b: any) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
                        }
                        this.uri = computed.data.uri;
                        this.wordFile = file;
                        this.requiredInformations = [];
                        this.billingSpecifications = [];
                        this.templateFromWord.requiredContractPartiesInformation = [];
                        this.templateFromWord.billingSpecifications = [];
                        this.templateFromWord.contractParties = [];

                        eventBus.$emit("uri", this.uri);
                        eventBus.$emit("wordFileName", file.name)
                        eventBus.$emit("setContractParties", this.normalizedContractParties)
                        this.wordTemplateFile = true;
                    }).catch(e => {
                        const err = new ErrorWrapper(e)
                        this.$message.error(`Ocorreu um erro: ${err.message}`)
                        this.wordTemplateFile = false;
                    }).finally(() => { this.loading = false })
                }
                else {
                    this.$message.error(`O arquivo não é um documento Word`);
                }
            }
            catch (e) {
                // Handle errors
                const err = new ErrorWrapper(e);
                this.$message.error(`Ocorreu um erro: ${err.message}`);
            }
        },
        handleFieldsFilled(fieldsFilled: any) {
            this.fieldsFilledObject = fieldsFilled;
        },
        onAddContractParties(contractParties: any) {
            if (contractParties.length > 0) {
                this.hasContractParties = true;
                this.normalizedContractParties = contractParties;
            }
        },

        goToNextStep() {
            switch (this.dialogStep) {
                case 'INSERT_PARTICIPANTS':
                    if (this.wildcards.length > 0) {
                        this.dialogStep = DialogSteps.INSERT_FIELDS
                    }
                    break;
                case 'INSERT_FIELDS':
                    const filteredRI = this.itemData.filter((i: any) => i.label === "field")
                    .map((i: any) => {
                            const normRI = {
                                isWildcard: true,
                                contractPartyRole: "",
                                requiredInformation: {
                                    name: i.name,
                                    description: "",
                                    required: true,
                                    type: RequiredInformationType.SINGLE_LINE_TEXT /* "SINGLE_LINE_TEXT" */,
                                    options: [] as string[]
                                },
                                contractParty: {
                                    role: ""
                                },
                                filled: false
                            }
                            return normRI;
                    })
                    if (filteredRI.length > 0) {
                        this.hasRI = true;
                        this.requiredInformations = filteredRI
                    }
                    const filteredBill = this.itemData.filter((i: any) => i.label === "finance")
                    .map((i: any) => {
                        const normBill = {
                            billingPeriodType: BillingPeriodType.ONE_TIME_PAYMENT,
                            name: i.name,
                            required: true,
                            maximumValue: 9999999999,
                            minimumValue: 0,
                            debtorContractPartyRole: "",
                            debtorContractParty: {
                            role: ""
                            },
                            isWildcard: true,
                            filled: false,
                            billingPeriod: {
                                maxInstallments: 12
                            }
                        }
                        return normBill;
                    })
                    if (filteredBill.length > 0) {
                        this.hasBill = true;
                        this.billingSpecifications = filteredBill
                    }

                    if (this.hasRI) {
                        this.dialogStep = DialogSteps.INSERT_RI
                    } else if (this.hasBill) {
                        this.dialogStep = DialogSteps.INSERT_BILL
                    } else {
                        this.dialogStep = DialogSteps.IMPORT_FILE
                        this.submitPartiesAndFields()
                    }

                    break;
                case 'INSERT_RI':
                    if (this.hasBill) {
                        this.dialogStep = DialogSteps.INSERT_BILL
                    }
                    break;
                case 'INSERT_BILL':
                    break;
            }
        },

        goToPreviousStep() {
            switch (this.dialogStep) {
                case DialogSteps.INSERT_PARTICIPANTS:
                    break;
                case DialogSteps.INSERT_FIELDS:
                    this.dialogStep = DialogSteps.INSERT_PARTICIPANTS
                    break;
                case DialogSteps.INSERT_RI:
                    this.dialogStep = DialogSteps.INSERT_FIELDS
                    break;
                case DialogSteps.INSERT_BILL:
                    this.dialogStep = DialogSteps.INSERT_RI
                    break;
            }
        },

        isFieldValid(ri: any, fieldType: any) {
            if (ri.contractParty) {
                if (ri.contractParty.role !== "") {
                    ri.filled = true
                    ri.contractPartyRole = ri.contractParty.role
                }
            } else if (ri.debtorContractParty) {
                if (ri.debtorContractParty.role !== "") {
                    ri.filled = true
                    ri.debtorContractPartyRole = ri.debtorContractParty.role
                }
            }

            if (fieldType === "RI") {
                this.isRIsValid()
            } else if (fieldType === "BILL") {
                this.isBillsValid()
            }
        },

        invalidClass(ri: any) {
            if (!ri.filled) return "invalidField"
            return ""
        },

        isRIsValid() {
            let errors = 0
            this.requiredInformations.forEach((ri: any) => {
                if (ri.contractParty.role === "") errors += 1
            })
            this.allRIsFilled = errors === 0;
        },

        isBillsValid() {
            let errors = 0
            this.billingSpecifications.forEach((bill: any) => {
                if (bill.debtorContractParty.role === "") errors += 1
            })
            this.allBillsFilled = errors === 0;
        },

        handleInputValue(currentValue: number, minValue: number, maxValue: number) {
            if (currentValue < minValue) {
            return minValue
            } else if (currentValue > maxValue) {
            return maxValue;
            }
            else {
            return currentValue;
            }
        },

        submitPartiesAndFields() {
            this.templateFromWord.requiredContractPartiesInformation = this.requiredInformations;
            this.templateFromWord.billingSpecifications = this.billingSpecifications;
            this.templateFromWord.contractParties = this.normalizedContractParties;


            this.isDrawerOpen = false;
            eventBus.$emit("templateFromWord", this.templateFromWord)
        },
        setItemData(itemData: any) {
            this.itemData = itemData;
            let isValid = 0;

            this.itemData.forEach((item: any) => {
                if (item.label === undefined || item.partie === "") {
                    isValid += 1;
                }
            })
            if (isValid === 0) {
                this.allFieldsAreFilled = true;
            }
        },
        setToDefault() {
            this.handleWordTemplateDialogOpen = false;
            this.loading = false;
            this.wordTemplateFile = false;
            this.activeTab = "parties";
            this.wildcards = [];
            this.uri = {} as Uri;
            this.fieldsFilledObject = null;
            this.hasContractParties = false;
            this.allFieldsAreFilled = false;
            this.templateFromWord = {};
            this.itemData = [];
            this.requiredInformations = [];
            this.billingSpecifications = [];
            this.isDrawerOpen = true;
            this.wordFile = null;
            this.wildcardsErrors = [];
            this.dialogStep = DialogSteps.IMPORT_FILE;
            this.hasRI = false;
            this.hasBill = false;
            this.allRIsFilled = false;
            this.itemData = [];

            eventBus.$emit("getContractParties", (response: any) => {
                this.normalizedContractParties = response
                this.onAddContractParties(response)
            })

            eventBus.$emit("setContractParties", this.normalizedContractParties)
            eventBus.$emit("clear")
        },
        addOption(index: number, ri: any) {
            this.requiredInformations[index].requiredInformation.options.push('');
            this.isFieldValid(ri, 'RI')
        },
        removeOption(i: number, index: number, ri: any) {
            this.requiredInformations[index].requiredInformation.options.splice(i, 1);
            this.isFieldValid(ri, 'RI')
        },
        handleOptionInput(i: number, index: number, ri: any) {
            const newValue = this.requiredInformations.requiredInformation.options[i].replace(/,/g, '');
            this.requiredInformations[index].requiredInformation.options[i] = newValue;
            this.isFieldValid(ri, 'RI')
        },
    },

    components: { Parties, Fields }
}
