
import { Component, Watch, Vue } from "vue-property-decorator";
import router from "../router";
import authUtils from "@/utils/authentication";

@Component
export default class Home extends Vue {
  @Watch("$this.$router", { immediate: true })
  handler() {
    if (!authUtils.isAuthenticated()) router.push("/login");
    else router.push("/new-template/word-template");
  }
}
