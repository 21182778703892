
import Appbar from "@/components/Appbar.vue";
import TemplateDataReview from "@/components/TemplateDataReview.vue";
import Anexos from "@/components/tabs/Attachments.vue";
import Financeiro from "@/components/tabs/Billings.vue";
import Issuers from "@/components/tabs/Issuers.vue";
import Parties from "@/components/tabs/Parties.vue";
import Campos from "@/components/tabs/RequiredInformations.vue";
import Vigencia from "@/components/tabs/ValidityPeriod.vue";
import SignatureOrder from "@/components/tabs/SignatureOrder.vue";
import ContractsService from "@/services/contracts";
import {
  BillingSpecification,
  ContractIssuer,
  ContractPartie,
  DigitalContractInputFileType,
  RequiredAttachment,
  RequiredInformation,
  Template,
  ValidityPeriod,
  StartDatePolicyType,
  EndDatePolicyType,
  WildcardMetadata,
  WordTemplate,
} from "@/types/contract";
import { Component, Vue, Watch } from "vue-property-decorator";

import VueDocumentEditor from "@/components/editor/ContractEditor.vue";
import WordTemplateDialog from "@/components/template-from-word/WordTemplateDialog.vue";
import contracts from "@/services/contracts";
import ErrorWrapper from "@/utils/ErrorWrapper";
import { ContractsQuota, OrganizationContractsQuotaService } from "@/services/organization-quota";
import authUtils from "@/utils/authentication";
import eventBus from "@/utils/eventBus";
import axios from "axios";

interface URI {
  uri: String,
  uriExpiration: Date | null,
}

@Component({
  components: {
    Appbar,
    Parties,
    Campos,
    Vigencia,
    Financeiro,
    Anexos,
    Issuers,
    VueDocumentEditor,
    TemplateDataReview,
    WordTemplateDialog,
    SignatureOrder,
  },
})
export default class EditTemplate extends Vue {
  contractsQuota: ContractsQuota | undefined = undefined

  templateId: string | null | (string | null)[] = null;
  templateFile: string | null = null;
  loading = false;
  key = 0;
  name = "";
  allowIssuer = false

  async fetchContractsQuota() {
    if (!this.contractsQuota) {
      this.contractsQuota = await OrganizationContractsQuotaService.fetchContractsQuota();
    }
  }

  contractOnTop = false;
  isWordTemplateParamPresent = false;
  templateInputFileType: DigitalContractInputFileType | undefined = undefined;
  templateDownloadLink = "";

  templateFromWord = {} as WordTemplate;
  issuers: any = [];
  signatureOrder: string[] = [];
  signatureOrderEnabled = false;

  uri = {} as URI;
  fileName = "";

  tags = [];

  @Watch("uri")
  handler() {
    this.templateDownloadLink = this.uri.uri as string;
  }

  mounted() {
    //Get query params
    this.templateId = this.$route.params["templateId"] || null;
    if (this.templateId) this.fetchTemplateFile();
  }

  public async fetchTemplateFile() {
    if (!this.templateId) return;

    this.loading = true;
    const url = (await ContractsService.createDigitalContractTemplateDownloadTemplateFileDownloadUri(this.templateId as string)).uri;
    this.templateDownloadLink = url;

    try {
      const temp = (await contracts.find(this.templateId.toString())) as any;
      this.isWordTemplateParamPresent = temp.templateInputFile.type === DigitalContractInputFileType.WORD_1_0;

      const tagNames = (temp.contractTags) ? temp.contractTags.map(({ tagName }: { tagName: string }) => tagName) : [];

      if (temp.templateInputFile) {
        //Get the template file from local server
        if (temp.templateInputFile.type === DigitalContractInputFileType.HTML_1_0) {
          const resp = await axios.get(url);
          this.templateFile = resp.data;
        }
        else if (temp.templateInputFile.type === DigitalContractInputFileType.WORD_1_0) {
          const resp = await axios.get(`${this.templateDownloadLink}`, {
            responseType: "blob"
          })
          this.templateFile = resp.data;
        }
      }

      this.template = {
        name: temp.name,
        id: temp.id,
        legalRegionOfIssuance: "BRA",
        templateInputFile: temp.templateInputFile,
        validityPeriod: temp.validityPeriodSpecification,
        contractIssuers: temp.contractIssuersSpecifications,
        contractParties: temp.contractPartySpecifications,
        billingSpecifications: temp.billingSpecifications,
        requiredAttachments: temp.attachmentSpecifications,
        requiredContractPartiesInformation: temp.requiredContractPartiesInformation,
        allowIssuerToSendRequiredData: temp.allowIssuerToSendRequiredData,
        wildcardMetadata: temp.wildcardMetadata,
        contractTags: tagNames,
        mailTemplates: temp.mailTemplates,
        clausesToConfirm: temp.clausesToConfirm,
        contractPartiesSignatureOrder: temp.contractPartiesSignatureOrder,
      };
      this.signatureOrder = temp.contractPartiesSignatureOrder;
      this.signatureOrderEnabled = temp.contractPartiesSignatureOrder ? temp.contractPartiesSignatureOrder.length > 0 : false;
      this.templateInputFileType = temp.templateInputFile.type;
      this.issuers = temp.contractIssuersSpecifications;
      this.tags = tagNames;
      this.key += 1;
      this.isDisabled();
      eventBus.$emit("canSaveTemplate", true);
      eventBus.$emit("allRequiredInformationContractPartiesAreValid", this.allRequiredInformationContractPartiesAreValid())
      eventBus.$emit("allBillingsContractPartiesAreValid", this.allBillingsContractPartiesAreValid())
      eventBus.$emit("allAttachmentsContractPartiesAreValid", this.allAttachmentsContractPartiesAreValid())
      eventBus.$emit("fetchSignatureOrder", temp.contractPartiesSignatureOrder);
    } catch (e) {
      const err = new ErrorWrapper(e);
      console.error(err);
      this.$message.warning(
        `Não foi possível baixar o arquivo de mala direta deste modelo de template: ${err.message}`
      );
    } finally {
      this.loading = true;
    }
  }

  activeTab = "0";
  showReviewDialog = false;

  templateData: Template | null = null;

  template: any = {
    name: "",
    legalRegionOfIssuance: "BRA",
    templateInputFile: null,
    validityPeriod: {
      startDatePolicyType: StartDatePolicyType.STARTS_WHEN_SIGNED_BY_ALL_PARTIES,
      endDatePolicyType: EndDatePolicyType.UNDETERMINED
    },
    contractIssuers: [],
    contractParties: [],
    billingSpecifications: [],
    requiredAttachments: [],
    requiredContractPartiesInformation: [],
    allowIssuerToSendRequiredData: false,
    wildcardMetadata: [],
    contractTags: [],
    mailTemplates: [],
    signatureOrder: []
  };

  userName = "";
  userEmail = "";
  showUserMenu = false;
  disableSaveButton = true;

  @Watch("contractsQuota")
  handleContractQuota(newValue: ContractsQuota | undefined, oldValue: ContractsQuota | undefined) {
    if (newValue !== undefined) {
      this.panels.map((p) => {
        if (p.label === "Anexos") {
          this.contractsQuota?.customerContractsQuota.contractAttachmentsEnabled ?
            [p.hasRequiredQuota = true, p.canEdit = true] :
            [p.hasRequiredQuota = false, p.canEdit = this.template.requiredAttachments.length > 0]
        }
        if (p.label === "Campos") {
          this.contractsQuota?.customerContractsQuota.contractsFieldsAutomationEnabled ?
            [p.hasRequiredQuota = true, p.canEdit = true] :
            [p.hasRequiredQuota = false, p.canEdit = this.template.requiredContractPartiesInformation.length > 0]
        }
      })
    }
  }

  created() {
    this.fetchContractsQuota();
    eventBus.$on("templateFromWord", (tfw: any) => {
      this.templateFromWord = tfw;
      this.isDisabled()
    });
    eventBus.$on("canSaveTemplate", (val: boolean) => (this.disableSaveButton = !val));
    eventBus.$on("openEdittingIssuersDrawer", () => {
      eventBus.$emit("openIssuersDrawer", this.template);
    });
    eventBus.$on("uri", (uriVal: URI) => this.uri = uriVal);
    eventBus.$on("wordFileName", (fileName: string) => (this.fileName = fileName))

    if (!authUtils.isAuthenticated()) return;
    this.userName = authUtils.user().account.fullname;
    this.userEmail = authUtils.user().account.email;

    this.setActiveTab();
    eventBus.$on("newWildcard", (value: string) => {
      this.activeTab = value.toString();
    });
    
    eventBus.$on("fetchIssuers", (requestHandler: Function) => {requestHandler(this.issuers)})
    eventBus.$on("fetchTags", (requestHandler: Function) => {requestHandler(this.tags)})


    eventBus.$on(
      "saveTemplate",
      (contractName: string, contractIssuers: ContractIssuer[], allowIssuer: boolean, tags: []) => {
        this.template.contractName = contractName;
        this.template.contractIssuers = contractIssuers;
        this.key += 1;
        this.template.allowIssuerToSendRequiredData = allowIssuer;
        this.allowIssuer = allowIssuer;
        this.template.billingSpecifications.forEach((billing: BillingSpecification) => {
          if (!billing.maximumValue || !billing.minimumValue) {
            billing.maximumValue = 9999999999
            billing.minimumValue = 0
          }
          this.template.wildcardMetadata.forEach((wcMeta: WildcardMetadata) => {
            if (wcMeta.name === billing.name) billing.isWildcard = true
          })
        })
        this.template.contractTags = tags;
        this.createNewTemplate();
      }
    );

    eventBus.$on("fetchContractParties", (requestHandler: Function) => {
      if (this.template) {
        requestHandler(this.template.contractParties);
      }
    });

  }

  public updateSignatureOrder(orderEnabled: boolean, order: string[]) {
    this.signatureOrder = order;
    this.signatureOrderEnabled = orderEnabled;
  }

  updated() {
    eventBus.$on("templateFromWord", (tfw: any) => {
      this.template.billingSpecifications = []
      this.template.contractParties = []
      this.template.requiredContractPartiesInformation = []

      this.templateFromWord = tfw;
      this.template.contractParties = tfw.contractParties;
      this.isDisabled()
    });
  }

  public downloadWordFile() {
    const link = document.createElement('a');
    link.href = this.templateDownloadLink;
    link.setAttribute('download', this.fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(this.templateDownloadLink);
  }

  panels = [
    {
      label: "Participantes",
      icon: "pi pi-users icon",
      component: Parties,
      disabled: false,
      hasRequiredQuota: true,
      canEdit: true,
      value: 0,
    },
    {
      label: "Campos",
      icon: "pi pi-bars icon",
      component: Campos,
      disabled: true,
      hasRequiredQuota: true,
      canEdit: true,
      value: 1,
    },
    {
      label: "Vigência",
      icon: "pi pi-calendar icon",
      component: Vigencia,
      disabled: true,
      hasRequiredQuota: true,
      canEdit: true,
      value: 2,
    },
    {
      label: "Financeiro",
      icon: "pi pi-credit-card icon",
      component: Financeiro,
      disabled: true,
      hasRequiredQuota: true,
      canEdit: true,
      value: 3,
    },
    {
      label: "Anexos",
      icon: "pi pi-paperclip icon",
      component: Anexos,
      disabled: true,
      hasRequiredQuota: true,
      canEdit: true,
      value: 4,
    },
    {
      label: "Ordem de Assinatura",
      icon: "pi pi-sort-numeric-down",
      component: SignatureOrder,
      disabled: true,
      hasRequiredQuota: true,
      canEdit: true,
      value: 5
    },
  ];

  private setActiveTab() {
    const hash = this.$route.hash || null;
    if (!hash) return;
    if (hash.substring(1) != "0" && this.template.contractParties.length === 0) {
      this.$router.push({ hash: "#" + 0 });
      this.activeTab = "0";
    } else this.activeTab = hash.substring(1);
  }

  public handleChangeTabs(tabEvent: any) {
    const hash = this.$route.hash || null;
    if (hash && hash.substring(1) === this.activeTab) return;
    this.$router.push({ hash: "#" + tabEvent.index });

    this.panels.map((p) => {
      if (p.label === "Anexos") {
        this.contractsQuota?.customerContractsQuota.contractAttachmentsEnabled ?
          [p.hasRequiredQuota = true, p.canEdit = true] :
          [p.hasRequiredQuota = false, p.canEdit = this.template.requiredAttachments.length > 0]
      }
      if (p.label === "Campos") {
        this.contractsQuota?.customerContractsQuota.contractsFieldsAutomationEnabled ?
          [p.hasRequiredQuota = true, p.canEdit = true] :
          [p.hasRequiredQuota = false, p.canEdit = this.template.requiredContractPartiesInformation.length > 0]
      }
    });
  }

  /**
   * Update disabled panels
   */
  public isDisabled() {
    if (!this.panels) return;
    this.panels.map((p) => {
      if (p.label !== "Participantes")
        p.disabled = this.template.contractParties.length === 0;
    });
  }

  /**
  * Verify if the contract parties matches with the required informations contract parties
  */
  public allRequiredInformationContractPartiesAreValid() {
    return this.template.requiredContractPartiesInformation.every((wildcard: RequiredInformation) => {
      return this.template.contractParties.find((contractPartie: ContractPartie) => {
        return contractPartie.role === wildcard.contractParty?.role
      })
    })
  }
  /**
  * Verify if the contract parties matches with the billing contract parties
  */
  public allBillingsContractPartiesAreValid() {
    return this.template.billingSpecifications.every((billing: BillingSpecification) => {
      return this.template.contractParties.find((contractPartie: ContractPartie) => {
        return contractPartie.role === billing.debtorContractParty?.role
      })
    })
  }
  /**
  * Verify if the contract parties matches with the attachments contract parties
  */
  public allAttachmentsContractPartiesAreValid() {
    return this.template.requiredAttachments.every((attachment: RequiredAttachment) => {
      return this.template.contractParties.find((contractPartie: ContractPartie) => {
        return contractPartie.role === attachment.contractParty?.role;
      })
    })
  }

  public addContractPartie(emittedContractParties: ContractPartie[]) {
    this.template.contractParties = emittedContractParties;
    eventBus.$emit("canSaveTemplate", this.template.contractParties.length > 0);
    this.isDisabled();
    eventBus.$emit("allRequiredInformationContractPartiesAreValid", this.allRequiredInformationContractPartiesAreValid())
    eventBus.$emit("allBillingsContractPartiesAreValid", this.allBillingsContractPartiesAreValid())
    eventBus.$emit("allAttachmentsContractPartiesAreValid", this.allAttachmentsContractPartiesAreValid())
  }
  public addRequiredInformations(wildcards: RequiredInformation[]) {
    this.template.requiredContractPartiesInformation = wildcards;
    eventBus.$emit("allRequiredInformationContractPartiesAreValid", this.allRequiredInformationContractPartiesAreValid())
  }
  public addValidityPeriod(validityPeriod: ValidityPeriod) {
    this.template.validityPeriod = validityPeriod;
  }
  public addBillings(billings: BillingSpecification[]) {
    this.template.billingSpecifications = billings;
    eventBus.$emit("allBillingsContractPartiesAreValid", this.allBillingsContractPartiesAreValid())
  }
  public addRequiredAttachments(attachments: RequiredAttachment[]) {
    this.template.requiredAttachments = attachments;
    eventBus.$emit("allAttachmentsContractPartiesAreValid", this.allAttachmentsContractPartiesAreValid())
  }
  public createNewTemplate() {
    this.name = this.template.contractName;
    this.template = this.template;
    this.template.allowIssuerToSendRequiredData = this.allowIssuer;
    if (this.signatureOrderEnabled) {
      this.template.contractPartiesSignatureOrder = this.signatureOrder;
    } else {
      this.template.contractPartiesSignatureOrder = undefined;
    }
    this.showReviewDialog = true;
  }
  public showContract() {
    if (this.contractOnTop == false) {
      document.getElementById('el-main')!.style.zIndex = '2';
      this.contractOnTop = true;
    }
    else {
      document.getElementById('el-main')!.style.zIndex = '0';
      this.contractOnTop = false;
    }
  }
  public isAWordTemplate() {
    if (this.templateInputFileType === DigitalContractInputFileType.WORD_1_0) {
      return true;
    }
  }
}
