
import { Component, Vue, Prop } from "vue-property-decorator";
import ErrorWrapper from "@/utils/ErrorWrapper";
import authentication from "@/services/authentication";
import authUtils from "@/utils/authentication";

@Component
export default class RenewAccess extends Vue {
  @Prop({ type: Boolean }) visible!: boolean;

  password = "";
  loading = false;

  public close() {
    this.$emit("closeDialog");
  }

  public async renewAccess() {
    this.loading = true;

    try {
      const email = authUtils.user().account.email;

      //Authenticate on accounts 
      const accountsToken = await authentication.renewSessionWithPassword( this.password);
      if (!accountsToken) return;
  
      //Then authenticate the user on contracts with the accounts token
      await authUtils.authenticate(accountsToken);

      this.$emit("sessionRenewed");
    } catch (e) {
      const err = new ErrorWrapper(e);
      switch (err.httpStatus) {
        case 403:
          this.$message.error(`Credenciais Inválidas.`);
          break;
        default:
          this.$message.error(`Ocorreu um erro: ${err.message}`);
          break;
      }
    } finally {
      this.loading = false;
    }
  }
}
