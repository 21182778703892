
import { Component, Watch, Vue } from "vue-property-decorator";
import Appbar from "@/components/Appbar.vue";
import TemplateFileViwer from "@/components/editor/TemplateFileViwer.vue";
import contracts from "@/services/contracts";
import Parties from "@/components/editable-tabs/Parties.vue";
import ValidityPeriod from "@/components/editable-tabs/ValidityPeriod.vue";
import Billings from "@/components/editable-tabs/Billings.vue";
import IssueDataReview from "@/components/editable-tabs/IssueDataReview.vue";
import eventBus from "@/utils/eventBus";
import { BillingSpecification, DigitalContractInputFileType, IdentificationType } from "@/types/contract";
import ContractsService from "@/services/contracts";
import { MailTemplate, OrganizationQuota, Plans } from "@/types/organization";
import organization from "@/services/organization";
import ErrorWrapper from "@/utils/ErrorWrapper";

interface IdentificationInterface {
  identification: string,
  role: string
}

@Component({ components: { Appbar, TemplateFileViwer, Parties, ValidityPeriod, Billings, IssueDataReview } })
export default class IssueTemplate extends Vue {
  templateId: string | null | (string | null)[] = null;
  templateFileId = null;
  loading = false;
  activeTab = "0";

  tempName = "";
  tempParties: any[] = [];
  tempBillings = [];
  tempTags = [];
  tempValidity = {};

  showPreview = false;
  data: any = {};
  identifications: IdentificationInterface[] = []

  contractOnTop = false;

  templateInputFileType: DigitalContractInputFileType | undefined = undefined;
  templateDownloadLink = "";

  organizationQuota: OrganizationQuota = {
    customerContractsQuota: {
      contractAttachmentsEnabled: false,
      contractsFieldsAutomationEnabled: false,
      contractsIssuedPerMonth: 0,
      financialAnalyticsModuleEnabled: false,
      maxStorageBytes: 0,
      maxSubusers: 0,
      maxTemplateCount: 0,
      plan: Plans.NO_QUOTA
    },
    contractsIssuedPerMonth: 0,
    currentStorageConsumptionInBytes: 0,
    currentSubusersCount: 0,
    currentTemplateCount: 0
  };
  contractsQuotaPercentage = "0";
  contractsQuota: number | null = 0;
  contractsUsage = 0;
  isAContractBeingRenewed = false;

  templateMailTemplates: MailTemplate[] | null = null

  globalMailTemplates: MailTemplate[] | null = null

  created() {
    eventBus.$on("openIssueDrawer", () => {
      eventBus.$emit('getIssueData', (value: string, resp: any) => {
        this.showPreview = true
        if (value === 'contractParties') {
          this.data = { ...this.data, contractParties: resp }
        }
        else if (value === 'billingData') {
          this.data = { ...this.data, billingData: resp }
        }
        else if (value === 'validityPeriod') {
          this.data = { ...this.data, validityPeriod: resp }
        }
      })
    })
    eventBus.$on("canOpenDrawer", (openDialog: boolean) => {
      this.showPreview = openDialog
    })
    eventBus.$on("openRenewDrawer", () => {
      eventBus.$emit('getIssueData', (value: string, resp: any) => {
        if (value === 'contractParties') {
          this.data = { ...this.data, contractParties: resp }
        }
        if (value === 'billingData') {
          this.data = { ...this.data, billingData: resp }
        }
        else if (value === 'validityPeriod') {
          this.data = { ...this.data, validityPeriod: resp }
        }
      })
    })
    this.generateTemplateDownloadLink()
    this.fetchOrganizationQuota()
    eventBus.$on("issueContract", (requestHandler: Function) => {

      // Get the contracts issued per month quota and if it's null set to the default of 10
      const contractsIssuedPerMonth = this.organizationQuota.customerContractsQuota.contractsIssuedPerMonth || 10

      // Check if the quota has been exceeded
      const quotaExcess = this.organizationQuota.contractsIssuedPerMonth > contractsIssuedPerMonth;
      requestHandler(quotaExcess)
    })
  }

  @Watch("$route", { immediate: true })
  handler() {
    //Get query params
    this.templateId = this.$route.params["templateId"] || null;
    if (this.templateId) this.fetchTemplate();

    if (this.$route.name === "renew-issued") {
      this.isAContractBeingRenewed = true;
      this.generateEditableDigitalContractFromIssuedContractBeingRenewed()
    }
  }

  public async generateEditableDigitalContractFromIssuedContractBeingRenewed() {
    const previousContractInformations = (await (contracts.generateEditableDigitalContractFromIssuedContractBeingRenewed(this.$route.params.previousContractId))).data;
    this.templateId = previousContractInformations.template.id;

    const normalizedParties: any[] = []

    previousContractInformations.contractParties.forEach((partie: any) => {
      if (partie.contractPartyIdentificationType === IdentificationType.IDENTIFIED_BY_EMAIL) {
        this.identifications.push({ identification: partie.email, role: partie.role })
        normalizedParties.push({
          contractPartyIdentificationType: partie.contractPartyIdentificationType,
          duplicated: partie.duplicated,
          email: partie.email,
          identification: {
            email: partie.email,
          },
          role: partie.role
        })
      } else {
        this.identifications.push({ identification: partie.uuid, role: partie.role })
        normalizedParties.push({
          contractPartyIdentificationType: partie.contractPartyIdentificationType,
          duplicated: partie.duplicated,
          uuid: partie.uuid,
          identification: {
            uuid: partie.uuid,
          },
          role: partie.role
        })
      }
    })

    this.fetchTemplate()
    this.tempParties = [...normalizedParties]
  }

  public async fetchTemplate() {
    if (!this.templateId) return;
    this.loading = true;

    try {
      const temp = (await contracts.find(this.templateId.toString())) as any;

      this.data = { ...this.data, id: temp.id, name: "", clausesToConfirm: temp.clausesToConfirm }
      this.tempName = temp.name;
      this.tempTags = (temp.contractTags) ? temp.contractTags.map((t: any) => t.tagName) : [];
      this.tempParties = temp.contractPartySpecifications;
      this.tempValidity = temp.validityPeriodSpecification;
      this.tempBillings = temp.billingSpecifications.length > 0 ? temp.billingSpecifications : null;
      this.templateFileId = temp.templateInputFile ? temp.templateInputFile.fileId : null;
      this.templateInputFileType = temp.templateInputFile.type;

      let mailTemplates = null
      if (!temp.mailTemplates) {
        mailTemplates = await this.fetchMailTemplate()
      } else {
        mailTemplates = temp.mailTemplates
      }

      this.templateMailTemplates = mailTemplates

      this.tempBillings.forEach((billing: BillingSpecification) => {
        if (!billing.maximumValue || !billing.minimumValue) {
          billing.maximumValue = 9999999999
          billing.minimumValue = 0
        }
      })

      if (!temp.templateInputFile) eventBus.$emit('disableEmitButton')
    } catch (err) { }
  }

  public showContract() {
    if (this.contractOnTop == false) {
      document.getElementById('el-main')!.style.zIndex = '2';
      this.contractOnTop = true;
    }
    else {
      document.getElementById('el-main')!.style.zIndex = '0';
      this.contractOnTop = false;
    }
  }
  public isAWordTemplate() {
    if (this.templateInputFileType === DigitalContractInputFileType.WORD_1_0) {
      return true;
    }
  }
  //Generate the Template Download Link 
  async generateTemplateDownloadLink() {
    const url = (await ContractsService.createDigitalContractTemplateDownloadTemplateFileDownloadUri(this.templateId as string)).uri;
    this.templateDownloadLink = url;
  }

  async fetchOrganizationQuota() {
    // Try to fetch the organization quota
    try {
      const response = await organization.fetchOrganizationQuota()
      this.organizationQuota = response
      const contractsQuota = response.customerContractsQuota.contractsIssuedPerMonth;
      if (contractsQuota) {
        let percentage = ((response.contractsIssuedPerMonth / contractsQuota) * 100).toFixed(4)
        if (Number(percentage) > 100) percentage = "100"
        this.contractsQuotaPercentage = percentage;
      }
      this.contractsQuota = contractsQuota
      this.contractsUsage = response.contractsIssuedPerMonth
    } catch (e) {
      // Handle errors
      const err = new ErrorWrapper(e);
      this.$message.error(`Ocorreu um erro: ${err.message}`);
    }

  }

  async fetchMailTemplate(): Promise<MailTemplate[] | null> {
    try {

      const response = await organization.fetchOrganizationEmail()

      return response;

    } catch (e) {
      const err = new ErrorWrapper(e)

      switch (err.httpStatus) {
        case 404:
        case 403:
        case 402:
          break;
        default:
          this.$message.error(err.message);
          break;
      }
      return null;
    }
  }
}
