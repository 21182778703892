
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import VueDocumentEditor from "@/components/editor/ContractEditor.vue";
import axios from "axios";
import ContractsService from "@/services/contracts";

enum WildcardTypes {
  REQUIRED_INFORMATION = "requiredInformation",
  BILLING = "billing",
}

@Component({ components: { VueDocumentEditor } })
export default class TemplateFileViwer extends Vue {
  @Prop({ type: String }) templateFileId!: string | null;

  templateId: string | null | (string | null)[] = null;
  templateFile: string = "";
  loading = false;

  @Watch("templateFileId", { immediate: true })
  handler() {
    if (!this.templateFileId) return;

    //Get id from props
    this.templateId = this.templateFileId;
    this.fetchTemplateFile();
  }

  @Watch("$route", { immediate: true })
  handler2() {
    if (this.$route.name === "issue-template") return;

    //Get query params
    this.templateId = this.$route.params["templateId"] || null;
    if (this.templateId) this.fetchTemplateFile();
  }

  public async fetchTemplateFile() {
    if (this.templateId == null) {
      throw new Error("Failed to render the template file. templateFileId is null");
    }
    this.loading = true;

    try {
      const url = (await ContractsService.createDigitalContractTemplateDownloadTemplateFileDownloadUri(this.templateId as string)).uri;

      //Get the template file from local server
      const response = await axios.get(url);

      //Generate a DOM with HTML from the template file
      const dom = new DOMParser().parseFromString(response.data, "text/html");

      //Get all pages
      const pages = Array.from(dom.getElementsByTagName("div") as HTMLCollectionOf<HTMLElement>);

      //Loop through each page and replace it's wildcards
      pages.forEach((element: HTMLElement) => this.insertWildcard(element));

      //Set template innerHTML to update the preview DOM
      this.templateFile = dom.body.innerHTML;
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = true;
    }
  }

  public insertWildcard(page: HTMLElement) {
    //Regex used to identify the wildcards
    const regex = /\{\{+(.*?)\}\}+/g;

    //Remove unused page attributes
    page.removeAttribute("style");
    page.removeAttribute("contenteditable");

    //Set a new class value for style
    page.className = "page";

    //Find all matching values
    const wildcards = page.innerHTML.match(regex);

    //Loop through each wildcard
    wildcards?.forEach((wc) => {
      //Regex to get the value of the wildcard
      const innerText = wc.match(/\[\"+(.*?)\"\]+/g);
      if (!innerText) return;

      //Create a prefix according to wildcard type
      const prefix = wc.includes(WildcardTypes.REQUIRED_INFORMATION)
        ? "Campos"
        : wc.includes(WildcardTypes.BILLING)
          ? "Cobrança"
          : "";
      const type = wc.includes(WildcardTypes.REQUIRED_INFORMATION)
        ? "field"
        : wc.includes(WildcardTypes.BILLING)
          ? "money"
          : "";

      //Generate the new text with the span tag and class style and replace unnecessary characters
      const newText = `<span class='${type} wildcard'> ${prefix} > ${innerText[0]
        .replace('["', "")
        .replace('"]', "")
        .replace("_", " ")} </span>`;

      //Replace the page innerHTML with new values
      page.innerHTML = page.innerHTML.replace(wc, newText);
    });
  }
}
