
import authentication from "@/services/authentication";
import contracts from "@/services/contracts";
import organization from "@/services/organization";
import { EmailTypes, MailTemplate, OrganizationInterfaceTheme } from "@/types/organization";
import ErrorWrapper from "@/utils/ErrorWrapper";
import eventBus from "@/utils/eventBus";
import { AxiosError } from "axios";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SupportedCurrencies } from "../../utils/Currencies";
import EmailTemplateEditor from "../EmailTemplateEditor.vue";
import Tags from "../Tags.vue";

interface ErrorResponse {
    documentFile?: string;
}

@Component({ components: { EmailTemplateEditor, Tags } })
export default class IssueDataReview extends Vue {
    @Prop({ type: Boolean }) visible!: boolean;
    @Prop({ type: Object }) data!: any;
    @Prop({ type: Blob }) file!: any;
    @Prop({ type: String }) templateName!: String;

    loading = false
    success = false;
    saving = false;
    selectedTab: "name" | "tags" | "review" = "name";
    organization = "";
    vpStartDate = ""
    vpEndDate = ""

    selectedMailTemplate: MailTemplate = {
        mailTemplate: "",
        mailType: EmailTypes.ISSUE_CONTRACT
    }

    emailEditor = false;

    editEmailTemplates = false

    emailType = "" as EmailTypes;

    emailTypes = EmailTypes;

    interfaceTheme: OrganizationInterfaceTheme = {
        primaryColor: "#370068",
        secondaryColor: "#7C24BE",
        logoUrl: "https://accountsblob.blob.core.windows.net/publicassets/cartorizi-logo.webp"
    };

    issueMailTemplate: MailTemplate | null = null;

    users: any = [];
    parties: any = [];
    tags: any = [];

    emailTemplates: MailTemplate[] = [];

    hasPersonalizedIssueMail = false;
    hasPersonalizedStateMail = false;
    hasPersonalizedSignMail = false;

    confirmRead = false;
    typeOfConfirmation: "clauses" = "clauses";
    clausesToConfirm = "";
    clauses: string[] | null = null;

    created() {
        eventBus.$on("editMailTemplate", (mailTemplate: MailTemplate) => {
            if (this.emailTemplates) {
                const mailTemplates = this.emailTemplates.filter((t: MailTemplate) => t.mailType !== mailTemplate.mailType) as MailTemplate[]
                mailTemplates.push(mailTemplate)
                this.emailTemplates = mailTemplates
            } else {
                this.emailTemplates = [mailTemplate]
            }
        })
        this.fetchMailTemplate()
        this.fetchInterfaceTheme()
    }

    public handleOpenEmailEditor(type: EmailTypes) {
        this.emailEditor = true
        this.emailType = type
        if (this.emailTemplates) {
            const mt = this.emailTemplates.filter(t => t.mailType === type)
            if (mt) this.selectedMailTemplate = mt[0]
        }
    }

    private async fetchInterfaceTheme() {
        try {
            const theme = await organization.fetchOrganizationInterfaceTheme()
            if (theme) {
                this.interfaceTheme = theme
            }
        } catch (e) {
            const err = new ErrorWrapper(e)
            switch (err.httpStatus) {
                case 404:
                    break;
                default:
                    this.$message.error(err.message)
                    break;
            }
        }
    }

    async fetchMailTemplate() {
        try {

            const response = await organization.fetchOrganizationEmail()

            this.emailTemplates = response

        } catch (e) {
            const err = new ErrorWrapper(e)

            switch (err.httpStatus) {
                case 404:
                case 403:
                case 402:
                    break;
                default:
                    this.$message.error(err.message);
                    break;
            }
        }
    }

    @Watch("data")
    handler() {
        if (!this.data.contractParties) return;
        this.parties = this.data.contractParties;
        this.parties.forEach(async (cp: any) => {
            this.$forceUpdate()
            if (cp.identification.uuid) {
                const user = await organization.fetchMember(cp.identification.uuid);
                this.users.push({ name: user.fullname, uuid: user.uuid })
            }
            this.getOrganization()
        });
        this.vpStartDate = moment(this.data.validityPeriod.startDate).format('DD/MM/YYYY')
        this.vpEndDate = moment(this.data.validityPeriod.endDate).format('DD/MM/YYYY')
    }

    public findUser(uuid: string) {
        const user = this.users.find((u: any) => u.uuid === uuid)
        return user ? user.name : uuid;
    }

    public billingDate(date: Date) {
        return moment(date).format('DD MMMM YYYY')
    }

    public close() {
        this.saving = false;
        this.selectedTab = 'name';
        this.$emit('close')
    }

    public async issue() {
        this.loading = true;
        this.saving = true;
        const normalizedTags = {
            tags: this.tags
        }

        try {
            await contracts.issueDigitalContractFromFile({
                ...this.data,
                billingData: (!this.data.billingData || this.data.billingData.length == 0) ? [] : this.data.billingData.map((b: { value: { value: number; }; }) => ({
                    ...b,
                    value: {
                        value: SupportedCurrencies.BRL.fromRegionValueToSubunit(b.value.value),
                        currency: SupportedCurrencies.BRL.currencyName,
                    }
                })),
                contractTags: normalizedTags,
                mailTemplates: this.emailTemplates,
                clausesToConfirm: this.confirmRead ? this.clauses : null,
            }, this.file)
            this.success = true;
        }
        catch (e) {
            const err = new ErrorWrapper(e);
            this.$message.error(
                    err.message === "Unable to issue contract: limit has been reached"  
                    ?   
                        "Ocorreu um erro: Sua cota de emissão atingiu o limite." 
                    :   
                        err.message === "Unable to issue contract due to excess of quota"
                        ?
                            "Ocorreu um erro: Não é possível emitir o contrato devido a cota excedente." 
                        :
                        `Ocorreu um erro: ${err.message}`
                );
        }
        finally {
            this.loading = false;
        }
    }

    public async getOrganization() {
        this.loading = true
        if (this.organization == "") {
            try {
                const org = await authentication.getOrganization()
                this.organization = org.data.fullname
            } catch {
                try {
                    this.organization = (await authentication.me()).account.preferredName
                } catch (error) {
                    this.organization = "{Nome da sua organização}"
                }

            }
        }
        this.loading = false
        if (this.emailTemplates) {
            this.emailTemplates.forEach(mt => {
                switch (mt.mailType) {
                    case EmailTypes.ISSUE_CONTRACT:
                        this.hasPersonalizedIssueMail = true
                        break;
                    case EmailTypes.CHANGE_STATES:
                        this.hasPersonalizedStateMail = true
                        break;
                    case EmailTypes.FULLY_SIGNED:
                        this.hasPersonalizedSignMail = true
                        break;
                }
            })
        }
    }

    public goToTheNextStep() {
        switch (this.selectedTab) {
            case "name":
                this.selectedTab = "tags"
                break;
            case "tags":
                this.selectedTab = "review"
                break;
        }
    }

    public goToThePreviousStep() {
        switch (this.selectedTab) {
            case "review":
                this.selectedTab = "tags"
                break;
            case "tags":
                this.selectedTab = "name"
                break;
        }
    }

    public preventFormSubmit(event: Event) {
        event.preventDefault();
    }

    public removeTag(tag: any) {
        const updatedTags = this.tags.filter((t: any) => t !== tag);
        this.tags = updatedTags
    }

    @Watch('tags', { immediate: true })
    handleTagsChange() {
        this.$forceUpdate();
    }

    public handleTagAdded(tag: any) {
        tag = tag.trim().toLowerCase();
        if (!this.tags.some((t: any) => t === tag)) {
            this.tags.push(tag);
        }
    }

    public updateClausesToConfirm() {
        let clausesAux: string[] = []
        if (this.clausesToConfirm !== "") {
            clausesAux = this.clausesToConfirm.split(";").map(s => s.trim());
            clausesAux = clausesAux.filter(s => s !== "");
        }
        this.clauses = clausesAux;
    }

}
