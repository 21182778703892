
import { ContractPartie, ContractPartyIdentification, IdentificationType } from "@/types/contract";
import { ContractPartiesSuggestions, IdentificationTypes } from "@/types/utils";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import SearchBar from "../SearchBar.vue";
import eventBus from "@/utils/eventBus";
import ErrorWrapper from "@/utils/ErrorWrapper";

@Component({ components: { SearchBar } })
export default class Parties extends Vue {
    @Prop({ type: Boolean }) fileUploaded!: boolean;

    // Boolean variables
    edit = false;
    editCommentDialog = false;
    expandBox = false;

    // Data variables
    contractParties: ContractPartie[] = [];
    cpBeingEdited: ContractPartie | null = null;
    currentCP: ContractPartyIdentification = {
        role: "",
        identificationType: "",
        identification: {} || undefined,
        email: "",
        comment: "",
    };
    addedOrganizationMembers = new Set([""]);
    currentIdType: string | null = null;
    comment = "";
    prevComment = "";
    parsedCP: any = [];

    // Types variables
    identificationType = IdentificationType;
    identificationTypes = IdentificationTypes;

    // Validation
    checkField = async (rule: any, value: string, callback: Function) => {
        if (!value) return callback(new Error("Campo obrigatório"));
        const filter =
            rule.field === "role"
                ? this.contractParties.filter((cp) => cp.role === this.currentCP.role)
                : this.contractParties.filter(
                    (cp) => cp.identification === this.currentCP.identification
                );

        if (filter.length > 0) return callback(new Error("Participante já adicionado"));
        return callback();
    };

    checkEmailField = async (rule: any, value: string, callback: Function) => {
        if (!value) return callback(new Error("Campo obrigatório"));
        if (!/\S+@\S+\.\S+/.test(value)) return callback(new Error("Insira um e-mail valido"))
        return callback();
    };

    checkIdentificationField = async (rule: any, value: any, callback: Function) => {

        if (!value.uuid) return callback(new Error("Campo obrigatório"));
        const filter = this.addedOrganizationMembers.has(value.uuid)
        if (filter) return callback(new Error("Membro já utilizado em outro participante"))

        return callback();
    };

    rules = {
        role: [{ required: true, validator: this.checkField, trigger: "change" }],
        identificationType: [
            { required: true, message: "Campo obrigatório", trigger: "blur" },
        ],
        identification: [{ required: true, validator: this.checkIdentificationField, trigger: "change" }],
        email: [{ required: true, validator: this.checkEmailField, trigger: "blur" }],
    };

    // Events
    created() {

        eventBus.$on("getIssueData", (requestHandler: Function) => {
            this.parsedCP = []
            let hasErrors = false

            let cpIdentification = ""

            const contractPartiesIdentification: Set<string[]> = new Set([])
            try {
                // Iterate over the contract parties
                this.contractParties.forEach((cp: any) => {
                    cpIdentification = cp.role
                    const newCP = { role: cp.role, identification: {}, contractPartyIdentificationType: cp.identificationType, comment: cp.comment }
                    if (cp.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL) {
                        newCP.identification = { email: cp.identification.email }

                        // Check if there is already this email in the identification set and if there is throw a Error
                        if (!contractPartiesIdentification.has(cp.identification.email)) {
                            contractPartiesIdentification.add(cp.identification.email)
                        } else {
                            throw new Error(`E-mail ${cp.identification.email} está duplicado no participante ${cpIdentification}`)
                        }
                        this.parsedCP.push(newCP);
                    }
                    else {
                        newCP.identification = { uuid: cp.identification.uuid }

                        // Check if there is already this uuid in the identification set and if there is throw a Error
                        if (!contractPartiesIdentification.has(cp.identification.uuid)) {
                            contractPartiesIdentification.add(cp.identification.uuid)
                        } else {
                            throw new Error(`Mesmo membro da organização foi encontrado em mais de um participante`)
                        }
                        this.parsedCP.push(newCP);
                    }
                })
            } catch (e) {
                console.error(e)
                // If there is a error send a message and set the boolean to true
                if (!hasErrors) this.$message.error(`Ocorreu um erro: ${new ErrorWrapper(e).message}`);
                hasErrors = true
            }
            if (!hasErrors) requestHandler("contractParties", this.parsedCP)
            // Emit if the IssueDataReview drawer can open
            eventBus.$emit("canOpenDrawer", !hasErrors)
        })
    }

    // Functions
    public fetchSuggestions(queryString: string, callback: any) {
        const filteredSuggestions = ContractPartiesSuggestions.filter(
            suggestion =>
                suggestion.label.toLowerCase().includes(queryString.toLowerCase()) ||
                suggestion.value.toLowerCase().includes(queryString.toLowerCase())
        );
        callback(filteredSuggestions);
    }

    public openAdd() {
        this.expandBox = true;
        eventBus.$emit("canSaveExternalContract", false)
    }

    /**
     * Open edit mode of added partie
     * @param partie
     */
    public openEdit(partie: ContractPartie) {
        //Set values to the form being updated
        this.edit = true;
        this.cpBeingEdited = partie;

        this.currentCP.role = partie.role;
        this.currentCP.identificationType = partie.identificationType;
        this.currentCP.comment = partie.comment
        if (partie.identification) {
            Object.assign(this.currentCP, { identification: partie.identification })
        }
        this.currentCP.email = partie.identification.email ? partie.identification.email : ""
        eventBus.$emit("canSaveExternalContract", false)
    }

    /**
     * Add a contract partie
     */
    public addPartie() {
        //Validate form
        const ruleForm: any = this.$refs.ruleForm;
        if (ruleForm) {
            ruleForm?.validate((valid: any) => {
                if (valid) {
                    let idType = !this.currentIdType
                        ? IdentificationType.IDENTIFIED_BY_EMAIL
                        : this.currentIdType === "teamId"
                            ? IdentificationType.ORGANIZATION_TEAM
                            : IdentificationType.ORGANIZATION_USER;
                    //Create a new object to be added to the list of cp
                    let newCP;

                    if (this.currentCP.identificationType === IdentificationType.IDENTIFIED_BY_EMAIL) {
                        newCP = {
                            role: this.currentCP.role,
                            identificationType: idType,
                            identification: {
                                email: this.currentCP.email
                            },
                            comment: this.currentCP.comment
                        };
                    } else {
                        newCP = {
                            role: this.currentCP.role,
                            identificationType: IdentificationType.PLATFORM_USER,
                            identification: this.currentCP.identification,
                        };
                        comment: this.currentCP.comment

                        if (this.currentCP.identification?.uuid) {
                            this.addedOrganizationMembers.add(this.currentCP.identification.uuid)
                        }
                    }

                    //Force type to stop observer
                    let _newCP = JSON.parse(JSON.stringify(newCP));

                    //Add and emit values
                    this.contractParties.push(_newCP);
                    this.emit();
                    this.resetValues();
                }
            });
        }
    }

    /**
     * Update a contract partie
     * @param cp
     */
    public updatePartie(cp: any) {
        //Cp is the object being updated, its values have to match currentCP values
        //Validate form
        const ruleForm: any = this.$refs.ruleForm;
        if (ruleForm) {
            ruleForm[0].validate((valid: any) => {
                //Ignore if both values are the same because the obj is being updated
                if (cp.role == this.currentCP.role) valid = true;
                if (valid) {
                    //Update role
                    cp.role = this.currentCP.role;

                    //Update identification type
                    cp.contractPartyIdentificationType = this.currentCP.identificationType as IdentificationType;

                    //Update identification (if necessary)
                    if (this.currentCP.identification) {
                        cp.identification = this.currentCP.identification;
                    }
                    //Reset values
                    this.resetValues();
                }
            });
        }
    }

    /**
     * Remove a contract partie
     * @param cp
     */
    public removePartie(cp: ContractPartie) {
        this.$delete(this.contractParties, this.contractParties.indexOf(cp));
        if (cp.identificationType === IdentificationType.ORGANIZATION_USER && cp.identification.uuid) {
            this.addedOrganizationMembers.delete(cp.identification.uuid)
        }
        this.emit();
    }

    /**
     * Emit contract parties value to parent
     */
    @Emit("enableTabs")
    emit() {
        eventBus.$emit("canSaveExternalContract", true)
        return this.contractParties;
    }

    /**
     * Reset state values
     */
    public resetValues() {
        this.edit = false;
        this.expandBox = false;
        this.currentIdType = null;
        this.currentCP = {
            role: "",
            identificationType: "",
            identification: {},
            email: "",
            comment: ""
        };
        eventBus.$emit("canSaveExternalContract", true)
    }

    // Handler functions

    /**
     * Handle change input value
     * @param val
     */
    changeInput = (val: string) => (this.currentCP.role = val);

    /**
    * Handle change select of identification type
    * @param idType
    */
    public handleIdentificationTypeChange(idType: IdentificationType) {
        this.currentCP.identificationType = JSON.parse(JSON.stringify(idType));
        if (idType === IdentificationType.IDENTIFIED_BY_EMAIL) {
            delete this.currentCP.identification;
        }
    }

    /**
     * Handle change select of organization member
     * @param member (user account or organization team)
     */
    public selectedOrganizationMember(member: any) {
        this.currentIdType = member.type === "ORGANIZATION_TEAM" ? "teamId" : "uuid";
        let fieldValue =
            member.type === "ORGANIZATION_TEAM" ? member.object.guid : member.object.uuid;

        this.currentCP.identification = { [this.currentIdType]: fieldValue };
    }

    changeEmailInput = (val: string) => {
        this.currentCP.email = val;
        this.currentCP.identification = { email: val };
    };

    public handleOpenCommentDialog(cp: any) {
        this.editCommentDialog = true;
        this.comment = cp.comment ? cp.comment : "";
        this.prevComment = this.comment;
    }

    public saveContractPartyComment(cp: any) {
        this.editCommentDialog = false;
        cp.comment = this.comment;
        this.comment = "";
        this.prevComment = "";
    }
}
