
import {
  BillingSpecification,
  BillingPeriodType,
  ContractPartie,
  Template,
  WordTemplate,
} from "@/types/contract";
import { BillingPeriodTypes } from "@/types/utils";
import eventBus from "@/utils/eventBus";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class Financeiro extends Vue {
  @Prop({ type: Array }) contractParties!: ContractPartie[];
  @Prop({ type: Object, required: false }) editing!: Template;
  @Prop({ type: Object, required: false }) templateFromWord!: any;
  @Prop({ type: Boolean }) isWordTemplateParamPresent!: Boolean;

  created() {
    if (this.editing) this.billings = this.editing.billingSpecifications;
    this.billings.forEach(billing => {
      billing = {
        ...billing,
        billingPeriod: {
          maxInstallments: billing.billingPeriod?.maxInstallments ? billing.billingPeriod.maxInstallments : 1
        }
      }
      billing.required = true
      if (!billing.maximumValue || !billing.minimumValue) {
        billing.maximumValue = 9999999999
        billing.minimumValue = 0
      };
      this.editing.wildcardMetadata.forEach(wcMeta => {
        if (wcMeta.name === billing.name) billing.isWildcard = true
      })
    })
    this.billings.sort((a,b)=> a.name.localeCompare(b.name))
  }
  @Watch("templateFromWord")
  onTemplateFromWordChanged(newVal: any) {
    this.billings = []
    newVal.billingSpecifications.forEach((b: any) => {
      const normalizedBs: BillingSpecification = {
        billingPeriodType: b.billingPeriodType,
        name: b.name,
        required: true,
        maximumValue: 9999999999,
        minimumValue: 0,
        debtorContractPartyRole: b.debtorContractPartyRole,
        debtorContractParty: {
          role: b.debtorContractParty.role
        },
        isWildcard: true,
        billingPeriod: b.billingPeriod

      }
      this.billings.push(normalizedBs);
      this.emit();

    })
  }

  //Form validator
  checkField = async (rule: any, value: any, callback: Function) => {
    if (!value) return callback(new Error("Campo obrigatório"));
    this.validateForm(value, callback);
  };

  public validateForm(value: string, callback: Function) {
    const filter = this.billings.filter((billing) => billing.name.toLowerCase() === value.toLowerCase());
    if (filter.length >= 1 && !this.billingBeingEdited) return callback(new Error("Campo já adicionado"));
    if (value.trim().length === 0) return callback(new Error("Campo está invalido"));
    return callback();
  }

  billings: BillingSpecification[] = [];

  billingPeriodTypes = BillingPeriodTypes;
  billingPeriodType = BillingPeriodType;

  expandBox = false;
  billingBeingEdited = null;
  oldValue = "";
  currentBilling = {
    isWildcard: false,
    name: "",
    required: true,
    billingPeriodType: "",
    debtorContractPartyRole: "",
    minimumValue: 0,
    maximumValue: 9999999999,
    description: "",
    billingPeriod: {
      maxInstallments: 12,
    },
    debtorContractParty: {
      role: ""
    }
  };

  rules = {
    name: [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
      { required: true, validator: this.checkField, trigger: "blur" },
    ],
    billingPeriodType: [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],
    'debtorContractParty.role': [
      { required: true, message: `Campo obrigatório`, trigger: `blur` },
    ],
  };

  mounted() {
    eventBus.$on("newWildcard", (tabValue: number, wcName: string) => {
      if (tabValue != 3) return;
      this.currentBilling.name = wcName;
      this.currentBilling.isWildcard = true;
      this.expandBox = true;
    });
    eventBus.$on("canCreateBiWildcard", (requestHandler: Function) => {
      if (!this.expandBox && !this.billingBeingEdited) requestHandler(true);
      else requestHandler(false);
    });
    eventBus.$on("removedWildcard-money", (wcName: string) => {
      const billing = this.billings.find((b) => b.name === wcName.trim());
      if (billing) this.removeBilling(billing, false);
    });
  }

  /**
   * Handle change input value
   * @param val
   */
  changeInput = (val: string) => (this.currentBilling.name = val);

  /**
   * Handle change select of identification type
   * @param type
   */
  public handleBillingPeriodTypeChange(type: BillingPeriodType) {
    if (type != BillingPeriodType.INSTALLMENTS) {
      //delete this.currentBilling.billingPeriod;
    }
  }

  public addBilling() {
    //Validate form
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm) {
      ruleForm.validate((valid: boolean) => {
        if (!valid) return;
        const normalizedBilling: BillingSpecification = {
          billingPeriodType: this.currentBilling.billingPeriodType as BillingPeriodType,
          debtorContractPartyRole: this.currentBilling.debtorContractParty.role,
          name: this.currentBilling.name,
          required: true,
          billingPeriod: this.currentBilling.billingPeriod,
          description: this.currentBilling.description,
          maximumValue: this.currentBilling.maximumValue,
          minimumValue: this.currentBilling.minimumValue,
          debtorContractParty: this.currentBilling.debtorContractParty
        }
        if (normalizedBilling.billingPeriodType != BillingPeriodType.INSTALLMENTS)
          delete normalizedBilling.billingPeriod;
        this.billings.push(normalizedBilling);
        this.billings.sort((a,b)=> a.name.localeCompare(b.name))

        this.emit();
        this.resetValues();
      });
    }
  }

  public openEdit(billing: any) {
    this.expandBox = true;
    this.billingBeingEdited = billing;
    this.currentBilling = {
      ...billing,
      billingPeriod: {
        maxInstallments: billing.billingPeriod?.maxInstallments ? billing.billingPeriod.maxInstallments : 1
      }
    }
    if (!billing.maximumValue || !billing.minimumValue) {
      this.currentBilling.maximumValue = 9999999999
      this.currentBilling.minimumValue = 0
    }
    this.oldValue = JSON.parse(JSON.stringify(billing.name));
  }

  private emit() {
    this.$emit("billings", this.billings);
  }

  public cancelAction() {
    eventBus.$emit("removeWildcard", this.currentBilling.name);
    this.resetValues();
  }

  public updateBilling(billing: BillingSpecification) {
    const ruleForm: any = this.$refs.ruleForm;
    if (ruleForm[0]) {
      ruleForm[0].validate((valid: any) => {
        billing = this.currentBilling as BillingSpecification;
        // eventBus.$emit("updateWildcard", this.oldValue, this.currentBilling.name);
        const billingIndex = this.billings.findIndex(bill => bill.name === billing.name)
        this.billings[billingIndex] = billing;
        if(billing.debtorContractParty){
          this.billings[billingIndex].debtorContractPartyRole =  billing.debtorContractParty.role;
        }
        this.emit();
        this.resetValues();
      });
    }
  }

  /**
   * Remove a billing specification
   * @param billing
   */
  public removeBilling(billing: BillingSpecification, flag: boolean) {
    if (flag) eventBus.$emit("removeWildcard", billing.name);
    this.$delete(this.billings, this.billings.indexOf(billing));
    this.emit();
  }

  public resetValues() {
    this.expandBox = false;
    this.billingBeingEdited = null;
    this.currentBilling = {
      name: "",
      required: true,
      isWildcard: false,
      billingPeriodType: "",
      debtorContractPartyRole: "",
      description: "",
      minimumValue: 0,
      maximumValue: 9999999999,
      billingPeriod: {
        maxInstallments: 1,
      },
      debtorContractParty: {
        role: ""
      }
    };
  }

  public handleInputValue(currentValue: number, minValue: number, maxValue: number) {
    if (currentValue < minValue) {
      return minValue
    } else if (currentValue > maxValue) {
      return maxValue;
    }
    else {
      return currentValue;
    }
  }
  public canEditOrDeleteThisBilling(billing: BillingSpecification) {
    if(this.editing){
      if (this.editing.wildcardMetadata) {
        const aux = this.editing.wildcardMetadata.find(wc=> wc.name === billing.name);
        const isAWildcardMetadata = (Boolean(aux)); 
        if(this.isWordTemplateParamPresent && isAWildcardMetadata){
          return false;
        }
      }
    }
    if (this.templateFromWord && billing.isWildcard === true) {
      return false;
    }
    else {
      return true;
    }
  }

  @Watch("expandBox")
  handler() {
    eventBus.$emit("canSaveTemplate", !this.expandBox);
  }
}
