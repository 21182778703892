
import Appbar from "@/components/Appbar.vue";
import TemplateDataReview from "@/components/TemplateDataReview.vue";
import Anexos from "@/components/tabs/Attachments.vue";
import Financeiro from "@/components/tabs/Billings.vue";
import Issuers from "@/components/tabs/Issuers.vue";
import Parties from "@/components/tabs/Parties.vue";
import Campos from "@/components/tabs/RequiredInformations.vue";
import Vigencia from "@/components/tabs/ValidityPeriod.vue";
import SignatureOrder from "@/components/tabs/SignatureOrder.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

import {
  BillingSpecification,
  ContractIssuer,
  ContractPartie,
  RequiredAttachment,
  RequiredInformation,
  Template,
  ValidityPeriod,
  WordTemplate,
} from "@/types/contract";

import VueDocumentEditor from "@/components/editor/ContractEditor.vue";
import WordTemplateDialog from "@/components/template-from-word/WordTemplateDialog.vue";
import eventBus from "@/utils/eventBus";
import authUtils from "@/utils/authentication";
import { ContractsQuota, OrganizationContractsQuotaService } from "@/services/organization-quota";
import axios from "axios";

interface URI {
  uri: String,
  uriExpiration: Date | null,
}

@Component({
  components: {
    Appbar,
    Parties,
    Campos,
    Vigencia,
    Financeiro,
    Anexos,
    Issuers,
    VueDocumentEditor,
    TemplateDataReview,
    WordTemplateDialog,
    SignatureOrder,
  },
})

export default class NewTemplate extends Vue {
  contractsQuota: ContractsQuota | undefined = undefined
  activeTab = "0";
  showReviewDialog = false;

  templateData: Template | null = null;

  contractName = "";
  validityPeriod: ValidityPeriod | null = null;
  contractParties: ContractPartie[] = [];
  contractIssuers: ContractIssuer[] = [];
  billingSpecifications: BillingSpecification[] = [];
  requiredAttachments: RequiredAttachment[] = [];
  requiredContractPartiesInformation: RequiredInformation[] = [];
  signatureOrder: string[] = [];
  signatureOrderEnabled = false;
  allowIssuer = false;

  userName = "";
  userEmail = "";
  showUserMenu = false;
  disableSaveButton = true;

  async fetchContractsQuota() {
    if (!this.contractsQuota) {
      this.contractsQuota = await OrganizationContractsQuotaService.fetchContractsQuota();
    }
  }

  contractOnTop = false;

  // Check if the route has a word-template or otherwise use word as default
  isWordTemplateParamPresent = this.$route.path.split('/').pop() === 'word-template' || this.$route.path.endsWith('new-template') || this.$route.path.endsWith('new-template/') || this.$route.path.endsWith('templates/create'); 
  uri = {} as URI;
  tempParties: ContractPartie[] = [];
  hasContractParties = false;
  normalizedContractParties = [];
  templateFromWord = {} as WordTemplate;
  linkToDownload = "";
  fileName = "";

  tags = [] as string[];

  @Watch("uri")
  handler() {
    this.fetchWordFileFromBlob();
  }
  onSubmitPartiesAndFields(normalizedContractParties: any) {

    this.tempParties = normalizedContractParties as ContractPartie[];
  }
  created() {
    eventBus.$on("canSaveTemplate", (val: boolean) => (this.disableSaveButton = !val));
    eventBus.$on("allPartiesAreValid", (val: boolean) => (this.disableSaveButton = !val));
    eventBus.$on("templateFromWord", (tfw: any) => {
      this.templateFromWord = tfw;
      this.contractParties = tfw.contractParties;
      this.isDisabled()
    });
    eventBus.$on("uri", (uriVal: URI) => this.uri = uriVal);

    this.setActiveTab();
    eventBus.$on("newWildcard", (value: string) => {
      this.activeTab = value.toString();
    });
    eventBus.$on(
      "saveTemplate",
      (contractName: string, contractIssuers: ContractIssuer[], allowIssuer: boolean, tags: string[]) => {
        this.contractName = contractName;
        this.contractIssuers = contractIssuers;
        this.allowIssuer = allowIssuer
        this.tags = tags;
        this.createNewTemplate();
      }
    );

    eventBus.$on("fetchContractParties", (requestHandler: Function) => {
      requestHandler(this.contractParties);
    })

    this.fetchContractsQuota();
    eventBus.$on("wordFileName", (fileName: string) => (this.fileName = fileName))
  }

  panels = [
    {
      label: "Participantes",
      icon: "pi pi-users icon",
      component: Parties,
      disabled: false,
      hasRequiredQuota: true,
      value: 0,
    },
    {
      label: "Campos",
      icon: "pi pi-bars icon",
      component: Campos,
      disabled: true,
      hasRequiredQuota: false,
      value: 1,
    },
    {
      label: "Vigência",
      icon: "pi pi-calendar icon",
      component: Vigencia,
      disabled: true,
      hasRequiredQuota: true,
      value: 2,
    },
    {
      label: "Financeiro",
      icon: "pi pi-credit-card icon",
      component: Financeiro,
      disabled: true,
      hasRequiredQuota: true,
      value: 3,
    },
    {
      label: "Anexos",
      icon: "pi pi-paperclip icon",
      component: Anexos,
      disabled: true,
      hasRequiredQuota: false,
      value: 4,
    },
    {
      label: "Ordem de Assinatura",
      icon: "pi pi-sort-numeric-down",
      component: SignatureOrder,
      disabled: true,
      hasRequiredQuota: true,
      value: 5
    }
  ];

  private setActiveTab() {
    const hash = this.$route.hash || null;
    if (!hash) return;
    if (hash.substring(1) != "0" && this.contractParties.length === 0) {
      this.$router.push({ hash: "#" + 0 });
      this.activeTab = "0";
    } else this.activeTab = hash.substring(1);
  }

  public handleChangeTabs(tabEvent: any) {
    const hash = this.$route.hash || null;
    if (hash && hash.substring(1) === this.activeTab) return;
    this.$router.push({ hash: "#" + tabEvent.index });
    this.panels.map((p) => {
      if (p.label === "Anexos") {
        this.contractsQuota?.customerContractsQuota.contractAttachmentsEnabled ? p.hasRequiredQuota = true : p.hasRequiredQuota = false
      }
      if (p.label === "Campos") {
        this.contractsQuota?.customerContractsQuota.contractsFieldsAutomationEnabled ? p.hasRequiredQuota = true : p.hasRequiredQuota = false
      }
    });
  }

  /**
   * Update disabled panels 
   */
  public isDisabled() {
    if (!this.panels) return;
    this.panels.map((p) => {
      if (p.label !== "Participantes") {
        p.disabled = this.contractParties.length === 0;
      }
    });
  }

  /**
   * Verify if the contract parties matches with the required informations contract parties
   */
  public allRequiredInformationContractPartiesAreValid() {
    return this.requiredContractPartiesInformation.every((wildcard) => {
      return this.contractParties.find((contractPartie) => {
        return contractPartie.role === wildcard.contractParty?.role
      })
    })
  }
  /**
     * Verify if the contract parties matches with the billing contract parties
     */
  public allBillingsContractPartiesAreValid() {
    return this.billingSpecifications.every((billing) => {
      return this.contractParties.find((contractPartie) => {
        return contractPartie.role === billing.debtorContractParty?.role
      })
    })
  }
  /**
     * Verify if the contract parties matches with the attachments contract parties
     */
  public allAttachmentsContractPartiesAreValid() {
    return this.requiredAttachments.every((attachment) => {
      return this.contractParties.find((contractPartie) => {
        return contractPartie.role === attachment.contractParty?.role;
      })
    })
  }

  public addContractPartie(emittedContractParties: ContractPartie[]) {
    this.contractParties = emittedContractParties;
    eventBus.$emit("canSaveTemplate", this.contractParties.length > 0);
    this.isDisabled();
    eventBus.$emit("allRequiredInformationContractPartiesAreValid", this.allRequiredInformationContractPartiesAreValid())
    eventBus.$emit("allBillingsContractPartiesAreValid", this.allBillingsContractPartiesAreValid())
    eventBus.$emit("allAttachmentsContractPartiesAreValid", this.allAttachmentsContractPartiesAreValid())
  }
  public addRequiredInformations(wildcards: RequiredInformation[]) {
    this.requiredContractPartiesInformation = wildcards;
    eventBus.$emit("allRequiredInformationContractPartiesAreValid", this.allRequiredInformationContractPartiesAreValid())
  }
  public addValidityPeriod(validityPeriod: ValidityPeriod) {
    this.validityPeriod = validityPeriod;
  }
  public addBillings(billings: BillingSpecification[]) {
    this.billingSpecifications = billings;
    eventBus.$emit("allBillingsContractPartiesAreValid", this.allBillingsContractPartiesAreValid())
  }
  public addRequiredAttachments(attachments: RequiredAttachment[]) {
    this.requiredAttachments = attachments;
    eventBus.$emit("allAttachmentsContractPartiesAreValid", this.allAttachmentsContractPartiesAreValid())
  }
  public updateSignatureOrder(orderEnabled: boolean, order: string[]) {
    this.signatureOrder = order;
    this.signatureOrderEnabled = orderEnabled;
  }
  public createNewTemplate() {
    const template: any = {
      name: this.contractName,
      legalRegionOfIssuance: "BRA",
      templateInputFile: null,
      validityPeriod: this.validityPeriod as ValidityPeriod,
      contractIssuers: this.contractIssuers,
      contractParties: this.contractParties,
      billingSpecifications: this.billingSpecifications,
      requiredAttachments: this.requiredAttachments,
      requiredContractPartiesInformation: this.requiredContractPartiesInformation,
      allowIssuerToSendRequiredData: this.allowIssuer,
      contractTags: this.tags,
    };
    if (this.signatureOrderEnabled) {
      template.contractPartiesSignatureOrder = this.signatureOrder;
    } else {
      template.contractPartiesSignatureOrder = undefined;
    }
    this.templateData = template;
    this.showReviewDialog = true;
  }

  public showContract() {
    if (this.contractOnTop == false) {
      document.getElementById('el-main')!.style.zIndex = '2';
      this.contractOnTop = true;
    }
    else {
      document.getElementById('el-main')!.style.zIndex = '0';
      this.contractOnTop = false;
    }
  }

  async fetchWordFileFromBlob() {
    try {
      axios.get(`${this.uri.uri}`, {
        responseType: "blob"
      }).then(downloadedFile => {
        this.linkToDownload = URL.createObjectURL(downloadedFile.data);
      })
    } catch (error) {
      console.error("Error while trying to fecth the Word File", error);
    }
  }
  public downloadWordFile() {
    const link = document.createElement('a');
    link.href = this.linkToDownload;
    link.setAttribute('download', this.fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(this.linkToDownload);
  }
}
