import authUtils from "@/utils/authentication";
import axios, { AxiosError } from "axios";

// Marks if the application is running on development
const DEV = process.env.NODE_ENV === 'development';

// Store the default development host name
const accountsAPI = process.env.VUE_APP_ACCOUNTS_API || 'http://localhost:21001/klausc2w-accounts-ws';

//Create the ContractsApi Axios instance
const AccountsAPI = axios.create({
    baseURL: `${accountsAPI}`,
    timeout: 100000,
    withCredentials: false
})

// Add a request interceptor
AccountsAPI.interceptors.request.use(config => {
   
    config.headers = {
        'Authorization': `Bearer ${authUtils.getAccountsToken()}`,
        "Accept" : "application/json"
    }
    return config;
});

AccountsAPI.interceptors.response.use(
    (response) => {
        return response
    },
    (error: AxiosError) => {
        
        // If there is no response just reject the error
        if (!error.response) return Promise.reject(error);
        
        // If there is and the status is 5xx change the x-reason to default message
        if (Math.floor(error.response.status / 100) === 5) {
            error.response.headers["x-reason"] = "Ocorreu um erro no servidor"
            console.error(error)
        }

        return Promise.reject(error);
    }
)

export default AccountsAPI;