import { MailTemplate } from "./organization";

// deno-lint-ignore-file
interface Template {
    contractName: string;
    contractParties: ContractPartie[];
    id: string,
    organizationOwnerId: string,
    name: string,
    legalRegionOfIssuance: string,
    validityPeriod: ValidityPeriod,
    requiredContractPartiesInformation: RequiredInformation[],
    contractIssuers: ContractIssuer[],
    billingSpecifications: BillingSpecification[],
    requiredAttachments: RequiredAttachment[],
    templateInputFile: null | {
        fileId: string
    },
    allowIssuerToSendRequiredData: boolean,
    wildcardMetadata: WildcardMetadata[],
    contractTags: Tags[],
    mailTemplates?: MailTemplate[],
    contractPartiesSignatureOrder?: string[]
}
interface WordTemplate {
    contractParties: ContractPartie[];
    requiredContractPartiesInformation: [{
        index: number,
        label: string,
        name: string,
        partie: string,
    }],
    billingSpecifications: [{
        index: number,
        label: string,
        name: string,
        partie: string,
    }],
}

interface TemplateDTO {
    id: string,
    contractParties: ContractPartie[];
    name: string,
    legalRegionOfIssuance: string,
    validityPeriod: ValidityPeriod,
    requiredContractPartiesInformation: RequiredInformation[],
    contractIssuers: ContractIssuer[],
    billingSpecifications: BillingSpecification[],
    requiredAttachments: RequiredAttachment[],
    templateInputFile: null | {
        fileId: string
    },
    allowIssuerToSendRequiredData: boolean,
    wildcardMetadata: WildcardMetadata[],
    contractTags: any,
    mailTemplates?: MailTemplate[],
    contractPartiesSignatureOrder?: string[],
}

interface WildcardMetadata {
    name: string,
    type: WildcardType
}

interface ContractPartie {
    role: string,
    identificationType: IdentificationType,
    identification: {
        teamId?: string,
        uuid?: string,
        email?: string
    },
    comment?: string
}

interface RequiredInformation {
    isWildcard?: boolean;
    contractPartyRole: string,
    requiredInformation: {
        name: string,
        description?: string,
        required: boolean,
        type: RequiredInformationType,
        options?: string[]
    },
    contractParty?: {
        role: string
    }
}

enum RequiredInformationType {
    DATE = "DATE",
    TIME = "TIME",
    ADDRESS = "ADDRESS",
    INTEGER = "INTEGER",
    DECIMAL = "DECIMAL",
    BRA_CPF = "BRA_CPF",
    BRA_CNPJ = "BRA_CNPJ",
    TIMESTAMP = "TIMESTAMP",
    BRA_CPF_OR_CNPJ = "BRA_CPF_OR_CNPJ",
    SINGLE_LINE_TEXT = "SINGLE_LINE_TEXT",
    MULTIPLE_SELECT = "MULTIPLE_SELECT",
    SINGLE_SELECT = "SINGLE_SELECT",
    EMAIL = "EMAIL"
}

enum IdentificationType {
    ORGANIZATION_USER = "ORGANIZATION_USER",
    ORGANIZATION_TEAM = "ORGANIZATION_TEAM",
    IDENTIFIED_BY_EMAIL = "IDENTIFIED_BY_EMAIL",
    PLATFORM_USER = "PLATFORM_USER"
}

enum SearchResultType {
    ORGANIZATION_ACCOUNT = "ORGANIZATION_ACCOUNT",
    ORGANIZATION_SUBUSER_ACCOUNT = "ORGANIZATION_SUBUSER_ACCOUNT",
    ORGANIZATION_TEAM = "ORGANIZATION_TEAM"
}

enum DigitalContractInputFileType {
    HTML_1_0 = "HTML_1_0",
    WORD_1_0 = "WORD_1_0"
}

enum WildcardType {
    REQUIRED_INFORMATION = "REQUIRED_INFORMATION",
    BILLING = "BILLING",
}


interface SearchResult {
    id: string;
    description: string;
    subdescription: string | null;
    type: SearchResultType;
    object: any;
}

interface ValidityPeriod {
    startDatePolicyType: StartDatePolicyType;
    endDatePolicyType: EndDatePolicyType;
    endDatePolicy?: EndDatePolicy
}

interface EndDatePolicy {
    value: number,
    dateInterval: DateIntervals
}

enum DateIntervals {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR"
}

enum EndDatePolicyType {
    UNDETERMINED = "UNDETERMINED",
    SET_BY_ISSUER = "SET_BY_ISSUER",
    SET_AUTOMATICALLY_AFTER_START_DATE = "SET_AUTOMATICALLY_AFTER_START_DATE"
}
enum StartDatePolicyType {
    SET_BY_ISSUER = "SET_BY_ISSUER",
    STARTS_WHEN_SIGNED_BY_ALL_PARTIES = "STARTS_WHEN_SIGNED_BY_ALL_PARTIES"
}

interface BillingSpecification {
    required: boolean,
    name: string,
    billingPeriodType: BillingPeriodType,
    debtorContractPartyRole: string,
    description?: string,
    minimumValue: number,
    maximumValue: number,
    billingPeriod?: {
        maxInstallments?: number
    },
    debtorContractParty?: {
        role: string
    },
    isWildcard?: boolean
}

enum BillingPeriodType {
    RECURRING = "RECURRING",
    ONE_TIME_PAYMENT = "ONE_TIME_PAYMENT",
    INSTALLMENTS = "INSTALLMENTS"
}

interface RequiredAttachment {
    required: boolean,
    contractPartyRole: string,
    name: string,
    contractParty?: {
        role: string
    },
    requiresApproval?:boolean,
}

interface ContractIssuer {
    identificationType: IdentificationType,
    identification: {
        teamId?: string,
        uuid?: string
    }
}

interface ContractPartyIdentification {
    role: string,
    identificationType: string,
    identification: {
        uuid?: string,
        email?: string,
        teamId?: string
    } | undefined,
    email: string,
    comment?: string,
}

interface ValidityPeriodDates {
    startDate: Date | null,
    endDate: Date | null
}

interface ComputedWildcard {
    name: string,
    wildcardType: WildcardType | null
}

interface DocxTemplate {
    wildcards: {
        wildcards: ComputedWildcard[],
        wildcardsErrors: string[],
    },
    uri: Uri

}

interface Uri {
    uri: string,
    uriExpiration: number | null
}

type Tags = {
    tagName: string,
    tagOrigin: TagOrigin,
}

enum TagOrigin {
    CONTRACT = "CONTRACT",
    TEMPLATE = "TEMPLATE"
}

export {
    Template,
    ContractPartie,
    RequiredInformation,
    RequiredInformationType,
    IdentificationType,
    SearchResultType,
    SearchResult,
    ValidityPeriod,
    DateIntervals,
    EndDatePolicyType,
    EndDatePolicy,
    StartDatePolicyType,
    BillingSpecification,
    BillingPeriodType,
    RequiredAttachment,
    ContractIssuer,
    TemplateDTO,
    ContractPartyIdentification,
    WordTemplate,
    DigitalContractInputFileType,
    WildcardMetadata,
    WildcardType,
    ValidityPeriodDates,
    DocxTemplate,
    ComputedWildcard,
    Uri,
    Tags,
    TagOrigin,
}