
import { Vue } from "vue-property-decorator";

export default class NotFound extends Vue {
    public goToHome() {
        if (process.env.NODE_ENV === 'production') {
            parent.window.location.href = "https://app.cartorizi.com"
        }
        else{
            parent.window.location.href = "http://localhost:22001"
        }
    }
}

