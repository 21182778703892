
import { AuthenticatedUser } from "@/types/user";
import authUtils from "./authentication";

export const SecurityTierLevels = {
    SameDevice: 2,
    SamePerson: 3,
    MaxLevel: 4
};

//Create the React.Context default instance
const StlInterceptor = {
    require: (requiredStlLevel: number): Promise<AuthenticatedUser> => {
        return new Promise((resolve, reject) => {
            authUtils.updatedUser()
                .then(authUser => {
                    if (authUser.session.stl >= requiredStlLevel) resolve(authUser);
                    else reject(authUser);
                })
                .catch(() => reject());
        });
    },
};

//Export the STL interceptor
export default StlInterceptor;