
import Appbar from "@/components/Appbar.vue";
import Billings from "@/components/external-contract/Billings.vue";
import Parties from "@/components/external-contract/Parties.vue";
import Validity from "@/components/external-contract/ValidityPeriod.vue";
import organization from "@/services/organization";
import { ContractPartie } from "@/types/contract";
import { OrganizationInterfaceTheme, OrganizationQuota, Plans } from "@/types/organization";
import ErrorWrapper from "@/utils/ErrorWrapper";
import eventBus from "@/utils/eventBus";
import { Component, Vue, Watch } from "vue-property-decorator";
import authentication from "@/services/authentication";
import moment from "moment";
import Tags from "@/components/Tags.vue";
import contracts from "@/services/contracts";
import { SupportedCurrencies } from "@/utils/Currencies";
import { AxiosError } from "axios";

interface ErrorResponse {
    documentFile?: string;
}

@Component({ components: { Appbar, Parties, Validity, Billings, Tags } })
export default class CreateExternalContract extends Vue {

    // Boolean variables
    contractOnTop = false;
    tabIsDisabled = true;
    fileUploaded = false;
    showPreview = false;

    // Tab variables
    activeTab = "0";

    // Data variables
    organizationQuota: OrganizationQuota = {
        customerContractsQuota: {
            contractAttachmentsEnabled: false,
            contractsFieldsAutomationEnabled: false,
            contractsIssuedPerMonth: 0,
            financialAnalyticsModuleEnabled: false,
            maxStorageBytes: 0,
            maxSubusers: 0,
            maxTemplateCount: 0,
            plan: Plans.NO_QUOTA
        },
        contractsIssuedPerMonth: 0,
        currentStorageConsumptionInBytes: 0,
        currentSubusersCount: 0,
        currentTemplateCount: 0
    };
    contractParties: ContractPartie[] = [];
    pdfFile: File | null = null;
    pdfBlob: Blob | null = null;
    pdfBlobURL: string | null = null;
    data: any = {
        name: ""
    };

    // Events
    created() {
        eventBus.$on("openIssueDrawer", () => {
            eventBus.$emit('getIssueData', (value: string, resp: any) => {
                if (value === 'contractParties') {
                    this.data = { ...this.data, contractParties: resp }
                }
                else if (value === 'billingData') {
                    this.data = { ...this.data, billingData: resp }
                }
                else if (value === 'validityPeriod') {
                    this.data = { ...this.data, validityPeriod: resp }
                }
                this.data = {
                    ...this.data,
                    name: "",
                    region: "BRA",
                }
            })
        })
        eventBus.$on("canOpenDrawer", (openDialog: boolean) => {
            this.showPreview = openDialog
        })
        this.fetchOrganizationQuota()
    }

    // Functions
    async fetchOrganizationQuota() {
        // Try to fetch the organization quota
        try {
            const response = await organization.fetchOrganizationQuota()
            this.organizationQuota = response
        } catch (e) {
            // Handle errors
            const err = new ErrorWrapper(e);
            this.$message.error(`Ocorreu um erro: ${err.message}`);
        }

    }

    public enableTabs(emittedContractParties: ContractPartie[]) {
        this.contractParties = emittedContractParties
        this.tabIsDisabled = emittedContractParties.length === 0
        eventBus.$emit("issueFromFileButton", this.contractParties.length > 0);
    }

    // Handler functions
    public showContract() {
        if (this.contractOnTop == false) {
            document.getElementById('el-main')!.style.zIndex = '2';
            this.contractOnTop = true;
        }
        else {
            document.getElementById('el-main')!.style.zIndex = '0';
            this.contractOnTop = false;
        }
    }

    async handleFileUpload(file: File) {
        if (!(file.type === 'application/pdf') && !(file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) return;
        this.pdfFile = file;
        this.fileUploaded = true;

        if (file.type === 'application/pdf') this.data.documentType = "PDF";
        else this.data.documentType = "WORD";

        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target) {
                const data = event.target.result;
                if (data) {
                    this.pdfBlob = new Blob([data], { type: file.type }); // Set the type according to the file type
                    if (file.type === 'application/pdf') {
                        this.pdfBlobURL = URL.createObjectURL(this.pdfBlob);
                    } else {
                        this.pdfBlobURL = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(URL.createObjectURL(this.pdfBlob))}`;
                    }
                }
            }
        };

        reader.readAsArrayBuffer(file);

        // Return false to prevent the file from being uploaded
        return false;
    }

    public resetPDF() {
        this.fileUploaded = false;
    }

    // DIALOG SCRIPT
    loading = false;
    success = false;
    saving = false;
    review = false;
    organization = "";
    vpStartDate = ""
    vpEndDate = ""
    name = ""

    interfaceTheme: OrganizationInterfaceTheme = {
        primaryColor: "#370068",
        secondaryColor: "#7C24BE",
        logoUrl: "https://accountsblob.blob.core.windows.net/publicassets/cartorizi-logo.webp"
    }

    users: any = []
    parties: any = []
    tags: any = [];

    hasPersonalizedIssueMail = false
    hasPersonalizedStateMail = false
    hasPersonalizedSignMail = false


    private async fetchInterfaceTheme() {
        try {
            const theme = await organization.fetchOrganizationInterfaceTheme()
            if (theme) {
                this.interfaceTheme = theme
            }
        } catch (e) {
            const err = new ErrorWrapper(e)
            switch (err.httpStatus) {
                case 404:
                    break;
                default:
                    this.$message.error(err.message)
                    break;
            }
        }
    }

    public findUser(uuid: string) {
        const user = this.users.find((u: any) => u.uuid === uuid)
        return user ? user.name : uuid;
    }

    public billingDate(date: Date) {
        return moment(date).format('DD MMMM YYYY')
    }

    public close() {
        this.saving = false;
        this.review = false;
        this.loading = false;
        this.success = false;
        this.showPreview = false;
    }


    public async getOrganization() {
        this.loading = true
        if (this.organization == "") {
            try {
                const org = await authentication.getOrganization()
                this.organization = org.data.fullname
            } catch {
                try {
                    this.organization = (await authentication.me()).account.fullname
                } catch (error) {
                    this.organization = "{Nome da sua organização}"
                }

            }
        }
        this.loading = false
    }

    public enableReview() {
        this.review = !this.review
    }

    public preventFormSubmit(event: Event) {
        event.preventDefault();
    }

    public removeTag(tag: any) {
        const updatedTags = this.tags.filter((t: any) => t !== tag);
        this.tags = updatedTags
    }

    @Watch('tags', { immediate: true })
    handleTagsChange() {
        this.$forceUpdate();
    }

    public handleTagAdded(tag: any) {
        tag = tag.trim().toLowerCase();
        if (!this.tags.some((t: any) => t === tag)) {
            this.tags.push(tag);
        }
    }

    public async issue() {
        this.loading = true;
        this.saving = true;
        const normalizedTags = {
            tags: this.tags
        }
        try {
            if (!this.pdfFile) return;
            await contracts.createExternalDigitalContractFromFile({
                ...this.data,
                billingData: (!this.data.billingData || this.data.billingData.length == 0) ? [] : this.data.billingData.map((b: { value: { value: number; }; }) => ({
                    ...b,
                    value: {
                        value: SupportedCurrencies.BRL.fromRegionValueToSubunit(b.value.value),
                        currency: SupportedCurrencies.BRL.currencyName,
                    }
                })),
                contractTags: normalizedTags,
                state: "SIGNED",
                signatureDate: this.data.validityPeriod.startDate
            }, this.pdfFile)
            this.success = true;
        }
        catch (e) {
            if ((e as AxiosError).response) {
                const response = (e as AxiosError).response;
                const errorData = response?.data as ErrorResponse;
                if (errorData && errorData.documentFile) {
                    const documentFileError = errorData.documentFile;
                    this.$message.error({
                        message: `Ocorreu um erro: ${documentFileError}`,
                        duration: 10000
                    });
                } else {
                    this.$message.error({
                        message: `Ocorreu um erro: ${response?.data}`,
                        duration: 10000
                    });
                }
            } else {
                const err = new ErrorWrapper(e);
                this.$message.error(`Ocorreu um erro: ${err.message}`);
            }
        }
        finally {
            this.loading = false;
        }
    }

}
