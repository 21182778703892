
interface AuthenticatedUser {
    account: UserAccount,
    session: Session,
}

interface Session {
    accountType: AccountTypes,
    rasUuid: string,
    roles: SessionRoles,
    stl: number
}

interface UserAccount {
    creationDate: Date,
    email: string,
    fullname: string,
    preferredName: string,
    uuid: string,
    accountRoles: AccountRoles,
    customerOrAdministrator: boolean
}

interface OrganizationTeam {
    creationDate: Date,
    guid: string,
    name: string
}

enum SessionRoles {
    ROLE_STL_0 = 'ROLE_STL_0',
    ROLE_STL_1 = 'ROLE_STL_1',
    ROLE_STL_2 = 'ROLE_STL_2',
    ROLE_STL_3 = 'ROLE_STL_3',
    ROLE_STL_4 = 'ROLE_STL_4',
    ROLE_ACCOUNT_TYPE_CUSTOMER = 'ROLE_ACCOUNT_TYPE_CUSTOMER',
    ROLE_ACCOUNT_TYPE_CUSTOMER_SUBUSER = 'ROLE_ACCOUNT_TYPE_CUSTOMER_SUBUSER'
}

enum AccountRoles {
    ADMINISTRATOR = 'ADMINISTRATOR',
    USER_AND_TEAMS_MANAGER = 'USER_AND_TEAMS_MANAGER',
    DIGITAL_CONTRACT_TEMPLATE_MANAGER = 'DIGITAL_CONTRACT_TEMPLATE_MANAGER'
}

enum AccountTypes {
    Administrator = "ADMINISTRATOR",
    Customer = "CUSTOMER",
    CustomerSubuser = "CUSTOMER_SUBUSER"
}

export { AuthenticatedUser, Session, UserAccount, OrganizationTeam, SessionRoles, AccountRoles, AccountTypes }