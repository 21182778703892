
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Quill from 'quill';
import { EmailTypes, MailTemplate, OrganizationInterfaceTheme } from "@/types/organization"
import organization from "@/services/organization"
import ErrorWrapper from '@/utils/ErrorWrapper';
import eventBus from "@/utils/eventBus";

import 'quill/dist/quill.snow.css'; // Make sure to import Quill's CSS

@Component
export default class EmailTemplateEditor extends Vue {
  @Prop({ type: Boolean }) visible!: boolean;
  @Prop({ type: String }) emailType!: EmailTypes
  @Prop({ type: Object }) mailTemplate!: MailTemplate
  @Prop({ type: Boolean, required: false }) isGlobalTemplate!: boolean

  public close() {
    this.resetValues();
    this.$emit("closeDialog");
  }

  private quillEditorInstance: Quill | null = null;

  toolbarOptions = [
    [{ 'font': [] }],
    [{ 'bold': true }, { 'italic': true }, { 'underline': true }, { 'strike': true }],
    [{ 'script': 'super' }, { 'script': 'sub' }],
    [{ 'header': 1 }, { 'header': 2 }, 'blockquote'],
    [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'image': '' }],
    ['clean']
  ];

  emailTypes = EmailTypes

  email = {
    title: "",
    type: ""
  }

  showPreview = false

  interfaceTheme: OrganizationInterfaceTheme = {
    primaryColor: "#370068",
    secondaryColor: "#7C24BE",
    logoUrl: "https://accountsblob.blob.core.windows.net/publicassets/cartorizi-logo.webp"
  }

  openCollapse = ""

  template = ""

  loading = false

  created() {
    switch (this.emailType) {
      case EmailTypes.CHANGE_STATES:
        this.email = {
          title: "Mudança de Estado",
          type: this.emailType
        }
        break;
      case EmailTypes.FULLY_SIGNED:
        this.email = {
          title: "Contrato Assinado",
          type: this.emailType
        }
        break;
      case EmailTypes.ISSUE_CONTRACT:
        this.email = {
          title: "Emissão de contrato",
          type: this.emailType
        }
        break;
    }
    this.fetchInterfaceTheme()
  }

  @Watch("visible")
  onDisplayDialog() {
    if (this.visible) {
      this.$nextTick(() => {
        if (!this.quillEditorInstance) this.initializeQuillEditor()
        this.setInitialContent()
        switch (this.emailType) {
          case EmailTypes.CHANGE_STATES:
            this.email = {
              title: "Mudança de Estado",
              type: this.emailType
            }
            break;
          case EmailTypes.FULLY_SIGNED:
            this.email = {
              title: "Contrato Assinado",
              type: this.emailType
            }
            break;
          case EmailTypes.ISSUE_CONTRACT:
            this.email = {
              title: "Emissão de contrato",
              type: this.emailType
            }
            break;
        }
      })
    }
  }

  private initializeQuillEditor() {

    var backgroundStyle = Quill.import('attributors/style/background');
    var colorStyle = Quill.import('attributors/style/color');
    var sizeStyle = Quill.import('attributors/style/size');
    var alignStyle = Quill.import('attributors/style/align');
    Quill.register(backgroundStyle, true);
    Quill.register(colorStyle, true);
    Quill.register(sizeStyle, true);
    Quill.register(alignStyle, true)

    // Initialize Quill editor
    this.quillEditorInstance = new Quill(this.$refs.quillEditor as HTMLElement, {
      modules: {
        toolbar: this.toolbarOptions, // Pass the toolbar options here
      },
      theme: 'snow',
    });
  }

  public showEmailPreview() {
    this.showPreview = true
    if (this.quillEditorInstance) {
      this.template = this.quillEditorInstance.root.innerHTML
    }
  }

  private setInitialContent() {
    if (this.quillEditorInstance) {
      if (this.mailTemplate) {
        this.quillEditorInstance.clipboard.dangerouslyPasteHTML(this.mailTemplate.mailTemplate)
      } else {
        this.quillEditorInstance.clipboard.dangerouslyPasteHTML("")
      }
    }
  }

  private async fetchInterfaceTheme() {
    try {
      const theme = await organization.fetchOrganizationInterfaceTheme()
      if (theme) {
        this.interfaceTheme = theme
      }
    } catch (e) {
      const err = new ErrorWrapper(e)

      switch (err.httpStatus) {
        case 404:
          break;
        default:
          this.$message.error(err.message)
          break;
      }

    }
  }

  private getEditorContent(): string {
    if (this.quillEditorInstance) {
      return this.quillEditorInstance.root.innerHTML
    }
    return "";
  }

  public async saveMailTemplate() {

    const temp = this.template !== "" ? this.template : this.getEditorContent()

    const mt: MailTemplate = {
      mailTemplate: temp,
      mailType: this.emailType
    }
    if (!this.isGlobalTemplate) {
      eventBus.$emit("editMailTemplate", mt)
    } else {
      try {
        this.loading = true
        organization.updateGlobalMailTemplate(mt)
        eventBus.$emit("editMailTemplate", mt)
        this.$message.success("Modelo de e-mail atualizado com sucesso")
      } catch (e) {
        const err = new ErrorWrapper(e)

        this.$message.error(err.message)
      } finally {
        this.loading = false
      }
    }
    this.close()
  }

  private resetValues() {
    this.email = {
      title: "",
      type: ""
    }

    this.showPreview = false

    this.interfaceTheme = {
      primaryColor: "#370068",
      secondaryColor: "#7C24BE",
      logoUrl: "https://accountsblob.blob.core.windows.net/publicassets/cartorizi-logo.webp"
    }

    this.openCollapse = ""

    this.template = ""
  }
}
