import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import NewTemplate from '../views/NewTemplate.vue'
import EditTemplate from '../views/EditTemplate.vue'
import IssueTemplate from '../views/IssueTemplate.vue'
import TemplateFileViwer from '../components/editor/TemplateFileViwer.vue'
import IssueFromFile from '../views/IssueFromFile.vue'
import GlobalEmailTemplate from '../views/GlobalEmailTemplate.vue'
import NotFound from '../views/NotFound.vue'
import CreateExternalContract from "../views/CreateExternalContract.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login
  },
  {
    path: '/templates/create',
    name: 'new-template',
    component: NewTemplate
  },
  {
    path: '/templates/edit/:templateId',
    name: 'edit-template',
    component: EditTemplate
  },
  {
    path: '/issue/template/:templateId',
    name: 'issue-template',
    component: IssueTemplate
  },
  {
    path: '/templates/:templateId/template-file-viwer',
    name: 'template-file-viwer',
    component: TemplateFileViwer
  },
  {
    path: '/issue/file',
    name: 'issue-from-file',
    component: IssueFromFile
  },
  {
    path: '/new-template/word-template',
    name: 'new-word-template',
    component: NewTemplate
  },
  {
    path: '/renew-contract/:contractId',
    name: 'renew-issued',
    component: IssueTemplate
  },
  {
    path: '/global-mail-template',
    name: 'global-mail-template',
    component: GlobalEmailTemplate
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/external-contracts/create',
    name: 'external-contract',
    component: CreateExternalContract
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
