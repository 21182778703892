
import { ContractPartie, Template } from "@/types/contract";
import draggable from 'vuedraggable';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import eventBus from "@/utils/eventBus";

@Component({ components: { draggable } })
export default class SignatureOrder extends Vue {
    @Prop({ type: Array }) contractParties!: ContractPartie[];
    @Prop({ type: Object, required: false }) editing!: Template;

    // Boolean variables
    enabled = true;
    dragging = false;

    // Data variables
    orderSignatures: "YES" | "NO" = "NO";
    contractPartiesSignatureOrder: string[] = [];
    dragOptions = {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
    };

    alreadyFetched = false;

    // Events
    created() {
        this.contractPartiesSignatureOrder = this.editing.contractPartiesSignatureOrder ? this.editing.contractPartiesSignatureOrder : []
        this.orderSignatures = this.editing.contractPartiesSignatureOrder && this.editing.contractPartiesSignatureOrder.length > 0 ? "YES" : "NO"
        eventBus.$on("editContract", () => {
            this.enabled = false;
            this.alreadyFetched = false;
            this.contractPartiesSignatureOrder = [];
        })
        
    }


    @Watch("contractParties")
    handler() {
        this.contractParties.forEach(c => {

            if (!this.contractPartiesSignatureOrder.includes(c.role)) {
                this.contractPartiesSignatureOrder.push(c.role)
            }
        })
    }

    public updateOrder() {
        const enable = this.orderSignatures === "YES" ? true : false
        if (enable && !this.alreadyFetched) {
            eventBus.$emit("fetchContractParties", (cps: ContractPartie[]) => {
                this.contractPartiesSignatureOrder = cps.map(cp => cp.role);
                this.alreadyFetched = true
            })
        }
        if (!enable) {
            this.alreadyFetched = false;
            this.contractPartiesSignatureOrder = [];
        }
        this.$emit("signatureOrder", enable, this.contractPartiesSignatureOrder)
    }


}
