
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import "moment/locale/pt-br";
import { StartDatePolicyType, EndDatePolicyType, ValidityPeriod, ValidityPeriodDates } from "@/types/contract";
import eventBus from "@/utils/eventBus";

@Component
export default class Validity extends Vue {
  @Prop({ type: Object }) validityPeriod!: ValidityPeriod;

  //Vars
  startDatePolicyType = StartDatePolicyType;
  endDatePolicyType = EndDatePolicyType;
  dates: ValidityPeriodDates = {
    startDate: null,
    endDate: null
  }

  rules = {
    startDate: { type: 'date', required: true, message: 'Selecione uma data', trigger: 'change' },
    endDate: { type: 'date', required: true, message: 'Selecione uma data', trigger: 'change' }
  };

  //Handle end date text simulator
  get textSimulator() {
    let sd = 'hoje';
    if (this.validityPeriod.startDatePolicyType === this.startDatePolicyType.SET_BY_ISSUER) {
      sd = this.dates.startDate ? moment(this.dates.startDate).format("DD/MM/YYYY") : sd;
    }
    return `Considerando a data de início como ${sd}, este contrato se encerrará em: `
  }

  //Handle end date simulator
  get dateSimulator() {
    const today = this.dates.startDate ? moment(moment(this.dates.startDate), "DD/MM/YYYY") : moment(moment(), "DD-MM-YYYY")
    const interval = this.validityPeriod.endDatePolicy?.dateInterval as undefined;
    const date = today.add(this.validityPeriod.endDatePolicy?.value, interval).calendar();
    return date;
  }

  //Date picker options - disable previous date
  get pickerOptions() {
    return {
      disabledDate: (time: any) => {
        // Disable dates before the startDate
        return time.getTime() < (this.dates.startDate ? this.dates.startDate.getTime() : Date.now());
      },
    };
  }

  created() {
    eventBus.$on("canIssueContract", (requestHandler: Function) => {
      const formDate: any = this.$refs.formDate
      if (!formDate) return;
      formDate.validate((valid: any) => requestHandler(valid))
    })

    eventBus.$on("getIssueData", (requestHandler: Function) => {
      const newVP: any = { startDate: null, endDate: null }

      if (this.validityPeriod.startDatePolicyType === this.startDatePolicyType.SET_BY_ISSUER) {
        if (this.dates.startDate) newVP.startDate = { timestamp: new Date(this.dates.startDate).getTime() }
      }

      if (this.validityPeriod.endDatePolicyType === this.endDatePolicyType.SET_BY_ISSUER) {
        if (this.dates.endDate) newVP.endDate = { timestamp: new Date(this.dates.endDate).getTime() }
      }
      requestHandler("validityPeriod", newVP)
    })
  }
}
