type SupportedRegions = "BRA";

class Currency {
    region : SupportedRegions;
    currencyName : string;
    subunit : number;

    constructor(region : SupportedRegions, currency : string, subunit : number) {
        this.region = region;
        this.subunit = subunit;
        this.currencyName = currency;
    }

    /**
     * Parse an subunit value to regional value
     * @param subunitValue 
     * @returns 
     */
    fromSubunitToRegionValue(subunitValue : number) : number {
        return subunitValue * this.subunit;
    }

    /**
     * Parse an regional value to its subunit equivalent
     * @param regionValue 
     * @returns 
     */
    fromRegionValueToSubunit(regionValue : number) : number {
        return regionValue / this.subunit;
    }
}

const SupportedCurrencies = {
    BRL : new Currency("BRA", "BRL", 1 / 100)
}

export {
    SupportedCurrencies
}