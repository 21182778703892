import authUtils from "@/utils/authentication";
import axios, { AxiosError } from "axios";

// Marks if the application is running on development
const DEV = process.env.NODE_ENV === 'development';

// Store the default development host name
const contractsAPI = process.env.VUE_APP_CONTRACTS_API || 'http://localhost:21002/klausc2w-contracts-ws';

//Create the ContractsApi Axios instance
const ContractsApi = axios.create({
    baseURL: `${contractsAPI}`,
    timeout: 100000,
    withCredentials: true
})

// Add a request interceptor
ContractsApi.interceptors.request.use(config => {
   
    config.headers = {
        'Authorization': `Bearer ${authUtils.getContractsToken()}`,
        "Accept" : "application/json",
        'Content-Type': 'application/json; charset=utf-8'
    }

    return config;
});

ContractsApi.interceptors.response.use(
    (response) => {
        return response
    },
    (error: AxiosError) => {
        
        // If there is no response just reject the error
        if (!error.response) return Promise.reject(error);
        
        // If there is and the status is 5xx change the x-reason to default message
        if (Math.floor(error.response.status / 100) === 5) {
            error.response.headers["x-reason"] = "Ocorreu um erro no servidor"
            console.error(error)
        }

        return Promise.reject(error);
    }
)

export default ContractsApi;