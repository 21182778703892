import api from "@/services/contracts-api"
import accountsApi from "./accounts-api";
import { AuthenticatedUser, UserAccount } from "@/types/user";

interface Session {
    token: string,
    expirationDate: Date
}

const authentication = {
    create: async (authenticationToken: string): Promise<UserAccount> => {
        //Send request
        return await api.post(`/rest/v1/user-accounts/public/create-with-zaninte-account`, { authenticationToken })
    },

    accountsLogin: async (email: string, password: string) => {
        //Try to authenticate the user
        const authResponse = await accountsApi.post(`/rest/v1/auth/public/tokens/create-with-email-and-password?trustDevice=true`, { email, password })

        //Return the data
        return authResponse.data.token;
    },

    login: async (authenticationToken: string): Promise<Session> => {
        //Send request
        const authResponse = await api.post(`/rest/v1/auth/public/tokens/issue-with-zaninte-auth-token`, { authenticationToken })

        //Return the data
        return {
            token: authResponse.data.token,
            expirationDate: authResponse.data.expirationDate
        }
    },

    me: async (): Promise<AuthenticatedUser> => {
        //Send request
        const response = await api.get(`/rest/v1/auth/me`);
        return {
            account: response.data.account,
            session: response.data.session
        }
    },

    getOrganization: async (): Promise<any> => {
        const response = await accountsApi.get(`/rest/v1/auth/me/owner`)
        return await response.data
    },

    async renewSessionWithPassword(password: string): Promise<string> {
        const authResponse = await accountsApi.post(`/rest/v1/auth/tokens/renew-with-password`, { password })

        //Return the data
        return authResponse.data.token;
    }
}

export default authentication;