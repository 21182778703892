import { render, staticRenderFns } from "./TemplateDataReview.vue?vue&type=template&id=af433630&scoped=true&"
import script from "./TemplateDataReview.vue?vue&type=script&lang=ts&"
export * from "./TemplateDataReview.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateDataReview.vue?vue&type=style&index=0&id=af433630&prod&scoped=true&lang=css&"
import style1 from "./TemplateDataReview.vue?vue&type=style&index=1&id=af433630&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af433630",
  null
  
)

export default component.exports