import { DigitalContractInputFileType, Template } from "@/types/contract";
import api from "@/services/contracts-api"


interface URIRedirectDTO {
    uri : string;
}

const contracts = {
    find: async (id: string): Promise<Template> => {
        //Send request
        return await (await api.get(`/rest/v1/digital-contracts/templates/${id}`)).data;
    },

    register: async (templateData: Template): Promise<Template> => {
        //Send request
        return await (await api.post(`/rest/v1/digital-contracts/templates`, templateData)).data;
    },

    update: async (templateId: string, templateData: Template): Promise<Template> => {
        //Send request
        return await (await api.put(`/rest/v1/digital-contracts/templates/${templateId}`, templateData)).data;
    },

    issue: async (id: string, templateData: any) => {
        //Send request
        return await (await api.post(`/rest/v1/digital-contracts/templates/${id}/issue-new-editable-contract`, templateData)).data;
    },

    uploadTemplateFile: async (templateGuid: string, templateBlob: Blob, inputFileType: DigitalContractInputFileType): Promise<Template> => {
        const bodyFormData = new FormData();
        bodyFormData.append("template", templateBlob);
        //Send request
        return await api.post(`/rest/v1/digital-contracts/templates/${templateGuid}/template-file?fileType=${inputFileType}`, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        })
    },

    duplicatedCp: async (id: string, field: string, value: string): Promise<any> => {
        //Send request
        const response = await api.get(`/rest/v1/digital-contracts/templates/${id}/verify-duplicated-contract?field=${field}&value=${value}`)
        
         //If the response is empty, return null
         if (response.status === 204) return null;

         //Return data
         return await (response).data
    },

    /**
     * 
     * @param contractId 
     * @returns 
     */
    async createDigitalContractTemplateDownloadTemplateFileDownloadUri(contractTemplateId : string) : Promise<URIRedirectDTO> {
        return await (await api.get(`/rest/v1/digital-contracts/templates/${contractTemplateId}/template-file/download-uri`)).data;
    },

    issueDigitalContractFromFile: async (data: any, file: File) => {
        const payload = new FormData();
        const contractInformationString = JSON.stringify(data);
        const contractInformationBlob = new Blob([contractInformationString], { type: 'application/json' });
    
        payload.append('file', file);
        payload.append('contractInformation', contractInformationBlob, 'contractInformation.json');
    
        const response = await api.post(`/rest/v1/digital-contracts/issued-contracts/issue-from-file`, payload, {
            headers: { "Content-Type": "multipart/form-data" }
        })

        return await response.data
    },

    getWildcardsFromWordFile: async (fileType: string, file: File) => {
        const bodyFormData = new FormData();
        bodyFormData.append("file", file);

        return await api.post(`/rest/v1/digital-contracts/compute-wildcards/${fileType}`, bodyFormData, {
            headers: {"Content-Type":"multipart/form-data"},
        });
    },

    generateEditableDigitalContractFromIssuedContractBeingRenewed: async (previousContractId: string) => {
        return await api.get(`/rest/v1/digital-contracts/issued-contracts/${previousContractId}/fetch-contract-to-renew`)
    },

    renewIssuedDigitalContract: async (previousContractId: string, data: any) => {
        return await api.post(`/rest/v1/digital-contracts/issued-contracts/${previousContractId}/renew`, data)
    },

    createExternalDigitalContractFromFile: async (data: any, file: File) => {
        const payload = new FormData();
        const contractInformationString = JSON.stringify(data);
        const contractInformationBlob = new Blob([contractInformationString], { type: 'application/json' });
    
        payload.append('file', file);
        payload.append('contractInformation', contractInformationBlob, 'contractInformation.json');
        
        const response = await api.post(`/rest/v1/digital-contracts/external-contracts`, payload, {
            headers: { "Content-Type": "multipart/form-data" }
        })

        return await response.data
    },
}

export default contracts;